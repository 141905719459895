import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { ChevronLeft, Close } from "@carbon/icons-react";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  postClose,
  useConfigContext,
  useNativeMessagingContext,
} from "@WahooFitness/wahoo-offline-mfe";
import { PLATFORM_ENUM } from "@WahooFitness/cloud-client-types";

interface Props {
  onClose: () => void;
}

function OnboardingNavbar({ onClose }: Props) {
  const { platform } = useConfigContext();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const isFirstVideo = useMemo(() => Number(params.videoIdx) === 0, [params.videoIdx]);
  const { search } = useLocation();
  const { addBackPressListener, removeBackPressListener } = useNativeMessagingContext();

  const isVideoPartOnly = useMemo(() => search.includes("video_link"), [search]);
  const handleVideoPartOnlyClose = useCallback(() => {
    postClose("runSetupVideos");
  }, []);

  const handleBack = useCallback(() => {
    if (location.pathname.includes("training-preferences")) {
      postClose("trainingPreferences");
    } else {
      navigate(-1);
    }
  }, [navigate, location]);

  useEffect(() => {
    addBackPressListener(handleBack);
    return () => {
      removeBackPressListener();
    };
  }, [addBackPressListener, handleBack, removeBackPressListener]);

  return (
    <AppBar elevation={0} position="sticky">
      <Toolbar variant={platform === PLATFORM_ENUM.ios ? "ios" : "regular"}>
        <Box
          height={44}
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {isVideoPartOnly && isFirstVideo ? (
            <Box width="24" />
          ) : (
            <IconButton edge="start" onClick={handleBack}>
              <ChevronLeft size={24} />
            </IconButton>
          )}
          <IconButton onClick={isVideoPartOnly ? handleVideoPartOnlyClose : onClose} edge="end">
            <Close size={24} />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default OnboardingNavbar;
