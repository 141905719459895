import React, { useCallback, useMemo } from "react";
import {
  WahooWorkoutTypeFamily,
  WorkoutProfileResponseType,
} from "@WahooFitness/cloud-client-types";
import { MenuList, MenuListItemType, MenuListItemVariant } from "@WahooFitness/redesignr";
import useZones from "@/components/Zones/useZones";
import { ChevronRightIcon } from "@WahooFitness/redesignr/Icons";
import ZoneSelect from "./ZoneSelect";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import { t } from "@lingui/macro";
import { ReactRouterLinkWrapper } from "@/components/MenuList/ReactRouterLinkWrapper";
import { useDrawerContext } from "@WahooFitness/wahoo-offline-mfe";

interface Props {
  profile?: WorkoutProfileResponseType;
}

const ZonesSection: React.FC<Props> = ({ profile }) => {
  const { setDrawer, handleClose } = useDrawerContext();
  const { zoneSets: powerZoneSets, isLoading: powerIsLoading } = useZones("power");
  const { zoneSets: hrZoneSets, isLoading: hrIsLoading } = useZones("heartRate");
  const { zoneSets: paceZoneSets, isLoading: paceIsLoading } = useZones("speed");

  const { updateProfile } = useWorkoutProfilesContext();

  const { powerZoneName, hrZoneName, paceZoneName } = useMemo(() => {
    const powerZoneName = profile?.power_zone_id ? powerZoneSets[profile.power_zone_id]?.name : "";
    const hrZoneName = profile?.heart_rate_zone_id
      ? hrZoneSets[profile.heart_rate_zone_id]?.name
      : "";
    const primaryPaceZoneName = Object.values(paceZoneSets).find(
      (zoneSet) => !!zoneSet.primary
    )?.name;
    const paceZoneName = profile?.pace_zone_id
      ? paceZoneSets[profile.pace_zone_id]?.name
      : primaryPaceZoneName;
    return { powerZoneName, hrZoneName, paceZoneName };
  }, [hrZoneSets, paceZoneSets, powerZoneSets, profile]);

  const zoneDrawerValues = useMemo(
    () => ({
      hr: {
        title: t`Heart rate zone`,
        zoneChoice: profile?.heart_rate_zone_id,
        zoneSets: hrZoneSets,
      },
      power: {
        title: t`Power zone`,
        zoneChoice: profile?.power_zone_id,
        zoneSets: powerZoneSets,
      },
      speed: {
        title: `Running pace zone`,
        zoneChoice: profile?.pace_zone_id,
        zoneSets: paceZoneSets,
      },
    }),
    [
      hrZoneSets,
      paceZoneSets,
      powerZoneSets,
      profile?.heart_rate_zone_id,
      profile?.pace_zone_id,
      profile?.power_zone_id,
    ]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, zoneDrawerMode: "hr" | "power" | "speed") => {
      const zoneId = +(event.target as HTMLInputElement).value;
      handleClose();
      if (!profile) {
        return;
      }
      if (zoneDrawerMode === "hr") {
        updateProfile(profile.id, "heart_rate_zone_id", zoneId);
      } else if (zoneDrawerMode === "power") {
        updateProfile(profile.id, "power_zone_id", zoneId);
      } else if (zoneDrawerMode === "speed") {
        updateProfile(profile.id, "pace_zone_id", zoneId);
      }
    },
    [profile, updateProfile, handleClose]
  );

  const openZoneSelect = useCallback(
    (mode: "hr" | "power" | "speed") => {
      setDrawer({
        open: true,
        title: zoneDrawerValues[mode].title,
        footer: <></>,
        zIndex: 1301,
        children: (
          <ZoneSelect
            handleChange={(ev) => handleChange(ev, mode)}
            zoneChoice={zoneDrawerValues[mode].zoneChoice}
            zoneSets={zoneDrawerValues[mode].zoneSets}
          />
        ),
      });
    },
    [handleChange, setDrawer, zoneDrawerValues]
  );

  const sectionItems = useMemo(() => {
    const baseItems: MenuListItemType[] = [
      {
        id: "heartRateZone",
        content: t`Heart rate zone`,
        secondaryContent: hrZoneName,
        variant: MenuListItemVariant.Action,
        actionComponent: <ChevronRightIcon sx={{ color: "action.active" }} />,
        action: () => {
          openZoneSelect("hr");
        },
      },
      {
        id: "powerZone",
        content: t`Power zone`,
        secondaryContent: powerZoneName,
        variant: MenuListItemVariant.Action,
        actionComponent: <ChevronRightIcon sx={{ color: "action.active" }} />,
        action: () => {
          openZoneSelect("power");
        },
      },
    ];
    const noPaceZonesListItem = {
      id: "noPaceZones",
      content: t`Running pace zone`,
      secondaryContent: t`Click here to create pace zones`,
      variant: MenuListItemVariant.InternalLink,
      linkLocation: "/pace-zones",
      linkComponent: ReactRouterLinkWrapper,
    };
    const paceZoneItem = {
      id: "paceZone",
      content: t`Running pace zone`,
      secondaryContent: paceZoneName,
      variant: MenuListItemVariant.Action,
      actionComponent: <ChevronRightIcon sx={{ color: "action.active" }} />,
      action: () => {
        openZoneSelect("speed");
      },
    };
    const renderedPaceZoneListItem = paceZoneName ? paceZoneItem : noPaceZonesListItem;
    if (profile?.workout_type_family_id === WahooWorkoutTypeFamily.Running) {
      baseItems.push(renderedPaceZoneListItem);
    }
    return baseItems;
  }, [hrZoneName, powerZoneName, paceZoneName, profile?.workout_type_family_id, openZoneSelect]);

  if (powerIsLoading || hrIsLoading || paceIsLoading) {
    return null;
  }

  return <MenuList title={t`Zones`} listItems={sectionItems} />;
};

export default ZonesSection;
