import React, { PropsWithChildren, RefObject } from "react";
import { ChevronRightIcon, MenuList, SlidingDrawer } from "@WahooFitness/redesignr";
import { Box, Button, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import useAssignedProfiles from "./useAssignedProfiles";
import { Trans, t } from "@lingui/macro";
import { WahooWorkoutTypeFamily } from "@WahooFitness/cloud-client-types";
import { useConfigContext } from "@WahooFitness/wahoo-offline-mfe";

const ListItemWrapper: React.FC<PropsWithChildren<{ editing: boolean; onClick: () => void }>> = ({
  children,
  editing,
  onClick,
}) => {
  if (editing) {
    return (
      <ListItemButton onClick={onClick} sx={{ padding: 0 }}>
        {children}
      </ListItemButton>
    );
  }
  return <>{children}</>;
};

const AssignedProfiles = ({
  editing,
  zoneSetId,
  type,
  primaryZoneSetId,
  parentDrawerRef,
}: {
  editing: boolean;
  zoneSetId: number;
  type: "power" | "heartRate" | "speed";
  primaryZoneSetId: number | undefined;
  parentDrawerRef?: RefObject<HTMLDivElement>;
}) => {
  const { globalBottomPadding, platform } = useConfigContext();
  const {
    profileCount,
    isPrimary,
    sportSections,
    open,
    handleOpen,
    handleCancel,
    handleSelectAll,
    handleSelectNone,
    handleSave,
  } = useAssignedProfiles(zoneSetId, type, primaryZoneSetId, editing);
  return (
    <>
      <ListItem data-testid="assignedProfilesButton">
        <ListItemWrapper editing={editing} onClick={handleOpen}>
          <ListItemText primary={t`Assigned workout profiles`} />
          <Typography>{profileCount}</Typography>
          {editing && <ChevronRightIcon sx={{ marginLeft: 2 }} />}
        </ListItemWrapper>
      </ListItem>
      <SlidingDrawer
        platform={platform}
        globalBottomPadding={globalBottomPadding}
        open={open}
        handleClose={handleCancel}
        handleOpen={handleOpen}
        title={t`Assigned workout profiles`}
        parentDrawerRef={parentDrawerRef}
        footer={
          <Box mx={3} my={2} display="flex" justifyContent="space-between" alignItems="center">
            {t`${profileCount} Selected`}
            <Box>
              <Button onClick={handleCancel}>
                <Trans>Cancel</Trans>
              </Button>
              <Button onClick={handleSave}>
                <Trans>Done</Trans>
              </Button>
            </Box>
          </Box>
        }
        zIndex={3000}
      >
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="column">
            {!isPrimary && (
              <Box display="flex" my={2} justifyContent="space-around">
                <Button
                  sx={{ margin: "0 8px" }}
                  fullWidth
                  variant="outlined"
                  onClick={handleSelectNone}
                >
                  <Trans>Unselect all</Trans>
                </Button>
                <Button
                  sx={{ margin: "0 8px" }}
                  fullWidth
                  variant="outlined"
                  onClick={handleSelectAll}
                >
                  <Trans>Select all</Trans>
                </Button>
              </Box>
            )}
            {Object.entries(sportSections).map(([sport, profiles]) => (
              <MenuList
                key={sport}
                title={WahooWorkoutTypeFamily[Number(sport)]}
                listItems={profiles}
              />
            ))}
          </Box>
        </Box>
      </SlidingDrawer>
    </>
  );
};

export default AssignedProfiles;
