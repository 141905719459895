import React, { useCallback, useMemo, useState } from "react";
import { Box, Button } from "@mui/material";
import {
  WahooWorkoutTypeFamily,
  WorkoutProfileResponseType,
} from "@WahooFitness/cloud-client-types";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import { useSnackbarContext, useConfigContext } from "@WahooFitness/wahoo-offline-mfe";
import FullScreenDialog from "@/components/FullScreenDialog";
import EditWorkoutProfile from "../EditWorkoutProfile/EditWorkoutProfile";
import useWorkoutProfiles from "../useWorkoutProfiles";
import NewOrExistingToggle from "./NewOrExistingToggle";
import TemplateChipList from "./TemplateChipList";
import useAddWorkoutProfile from "./useAddWorkoutProfile";
import { t } from "@lingui/macro";
import { MenuList } from "@WahooFitness/redesignr";

interface Props {
  closeParent: () => void;
  familyFilter?: WahooWorkoutTypeFamily;
}

const AddWorkoutProfile: React.FC<Props> = ({ closeParent, familyFilter }) => {
  const { globalBottomPadding } = useConfigContext();
  const { enqueueSnackbar } = useSnackbarContext();
  const [open, setOpen] = useState<boolean>(false);
  const [newProfile, setNewProfile] = useState<WorkoutProfileResponseType>();
  const { createProfile, copyProfile, deleteProfile, workoutProfiles } =
    useWorkoutProfilesContext();

  const handleCreateProfile = useCallback(
    async (workoutProfileType: number, name: string) => {
      const result = await createProfile(workoutProfileType, name);
      if (result) {
        setNewProfile(result);
        setOpen(true);
      }
    },
    [createProfile]
  );

  const handleCopyProfile = useCallback(
    async (profileId: number) => {
      const result = await copyProfile(profileId);
      if (result) {
        setNewProfile(result);
        setOpen(true);
      }
    },
    [copyProfile]
  );

  const profile = useMemo(
    () => (newProfile ? workoutProfiles.find((wp) => wp.id === newProfile.id) : undefined),
    [newProfile, workoutProfiles]
  );

  const handleDiscardWorkoutProfile = useCallback(() => {
    if (profile?.id) {
      deleteProfile(profile?.id);
    }
    setWarningDialogOpen(false);
    setOpen(false);
  }, [deleteProfile, profile?.id]);

  const handleContinueEditing = useCallback(() => {
    setWarningDialogOpen(false);
  }, []);

  const handleClose = useCallback(() => {
    setWarningDialogOpen(true);
  }, []);

  const handleCloseParent = useCallback(() => {
    enqueueSnackbar({
      message: t`Workout Profile added!`,
      severity: "success",
    });
    closeParent();
  }, [closeParent, enqueueSnackbar]);

  const { workoutProfilesList } = useWorkoutProfiles({
    isNew: true,
    action: handleCopyProfile,
    familyFilter,
  });
  const { templateData, newOrExistingToggle, setNewOrExistingToggle } =
    useAddWorkoutProfile(familyFilter);

  const [warningDialogOpen, setWarningDialogOpen] = useState(false);

  const SaveButton = () => {
    return <Button onClick={handleCloseParent}>{t`Done`}</Button>;
  };

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
        <NewOrExistingToggle
          newOrExistingToggle={newOrExistingToggle}
          setNewOrExistingToggle={setNewOrExistingToggle}
        />
        {newOrExistingToggle === "newProfile" && (
          <Box overflow="auto" pb={globalBottomPadding}>
            <TemplateChipList templateData={templateData} handleOpen={handleCreateProfile} />
          </Box>
        )}
        {newOrExistingToggle === "existingProfile" && (
          <Box overflow="auto" pt={1} pb={globalBottomPadding}>
            <MenuList listItems={workoutProfilesList} />
          </Box>
        )}
      </Box>
      <FullScreenDialog
        slideDirection="up"
        open={open}
        handleClose={handleClose}
        headerProps={{
          title: t`Add workout profile`,
          backAction: handleClose,
          backText: t`Cancel`,
          headerAction: <SaveButton />,
        }}
      >
        <EditWorkoutProfile
          hasOwnHeader
          title={t`Add workout profile`}
          profile={profile}
          backAction={handleClose}
          headerAction={<SaveButton />}
          warningDialogOpen={warningDialogOpen}
          onDiscardWorkoutProfile={handleDiscardWorkoutProfile}
          onContinueEditing={handleContinueEditing}
        />
      </FullScreenDialog>
    </React.Fragment>
  );
};

export default AddWorkoutProfile;
