import { Workouts } from "@WahooFitness/cloud-client-ts";
import {
  useCloudContext,
  useConfigContext,
  useDrawerContext,
  useUserContext,
} from "@WahooFitness/wahoo-offline-mfe";
import { useCallback, useMemo } from "react";
import { differenceInCalendarDays } from "date-fns";
import { t, Trans } from "@lingui/macro";
import ElemntWorkoutImage from "@/assets/ELEMENT_Workout.png";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { WSMBoltPlanProviderType } from "@WahooFitness/wsm-native/dist/esm/types/bolt_plan_provider_type";
import { SensorManagerPlugin } from "@WahooFitness/wsm-native";
import { WorkoutInputType } from "@WahooFitness/cloud-client-types";

export const useWorkoutScheduler = () => {
  const { wahooToken } = useConfigContext();
  const { getCloudClient } = useCloudContext();
  const { setDrawer, handleClose: handleCloseDrawer } = useDrawerContext();
  const workoutsClient = useMemo(() => getCloudClient(Workouts), [getCloudClient]);
  const { currentUser } = useUserContext();

  const getMidnightTodayIsoString = useCallback(() => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1; // month is 0-indexed
    const currentDay = now.getDate();

    return `${currentYear}-${currentMonth}-${currentDay}T00:00:00.000Z`;
  }, []);

  const todaysDayCode = useMemo(
    () => differenceInCalendarDays(new Date(), new Date(2020, 0, 1)),
    []
  );

  const showSendToElementDialog = useCallback(
    (workoutName: string) => {
      setDrawer({
        open: true,
        title: t`Send to ELEMNT`,
        hidePuller: true,
        children: (
          <Box display="flex" flexDirection="column" pt={2} p={3} rowGap={1.5} alignItems="center">
            <img src={ElemntWorkoutImage} height={220} width={148} />
            <Typography variant="prose-md" align="center">
              <Trans>
                <strong>“{workoutName}”</strong> has been sent to your ELEMNT and will be available
                next time you turn it on.
              </Trans>
            </Typography>
            <Typography variant="prose-xs-medium" align="center">
              <Trans>
                To ensure everything is synced, go to Menu &gt; Planned Workouts and tap
                &rsquo;Sync&rsquo; on your ELEMNT.
              </Trans>
            </Typography>
            <Button size="large" variant="contained" color="secondary" onClick={handleCloseDrawer}>
              <Trans>Done</Trans>
            </Button>
          </Box>
        ),
      });
    },
    [handleCloseDrawer, setDrawer]
  );

  const scheduleWorkoutInCloud = async (
    name: string,
    minutes: number,
    planGroupId: number | undefined,
    workoutTypeId: number
  ) => {
    if (planGroupId && currentUser) {
      const workout: WorkoutInputType = {
        name: name,
        minutes: minutes,
        plan_group_id: planGroupId,
        workout_type_id: workoutTypeId,
        starts: getMidnightTodayIsoString(),
        workout_token: `Lobby: ${Math.floor(Math.random() * 1e10)}`, // random 10 digit number
        create_participant: true,
        day_code: todaysDayCode,
      };
      const result = await workoutsClient.post(wahooToken, currentUser.id, workout);
      showSendToElementDialog(name);
      return result.id;
    }
  };

  const sendWorkoutToElemnt = useCallback(
    (
      providerTypeId: WSMBoltPlanProviderType | undefined,
      providerId: string | undefined,
      workoutId: number | undefined,
      fitnessAppId: number | undefined
    ) => {
      if (
        providerTypeId === undefined ||
        providerId === undefined ||
        workoutId == undefined ||
        fitnessAppId == undefined
      ) {
        return;
      }
      SensorManagerPlugin?.bcSelectPlan({
        providerTypeId,
        providerId,
        workoutId,
        fitnessAppId,
      });
    },
    []
  );

  return {
    scheduleWorkoutInCloud,
    sendWorkoutToElemnt,
  };
};
