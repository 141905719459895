import { useUnitFormatter } from "@/hooks/useUnitFormatter";
import { t } from "@lingui/macro";
import { LobbyEntryTypeEnum, PlanGroupType } from "@WahooFitness/cloud-client-types";
import { ExtendedPlanGroupLobbyCardType } from "./useWorkoutsFilters";

function usePlanGroupToPlanGroupCardProps(
  entry: PlanGroupType,
  index: number
): ExtendedPlanGroupLobbyCardType {
  const { formatDuration, unitFormatter } = useUnitFormatter();
  const indoorPlan = entry?.plans?.find((plan) => plan.workout_type_location_id === 0);
  const outdoorPlan = entry.plans?.find((plan) => plan.workout_type_location_id === 1);
  const tempMetrics = {
    duration: formatDuration(indoorPlan?.plan_metric?.duration, "pretty"),
    [t`IF®`]: indoorPlan?.plan_metric?.intensity_factor
      ? String(indoorPlan?.plan_metric?.intensity_factor)
      : undefined,
    [t`TSS®`]: unitFormatter?.formatTSS(indoorPlan?.plan_metric?.tss).formattedValue,
  };
  return {
    id: String(entry.id),
    title: entry.name || t`Workout`,
    description: entry.description,
    image: entry.image ? new URL(entry.image.url) : undefined,
    linkLocation: "",
    type: LobbyEntryTypeEnum.LobbyPlanGroupEntry,
    position: index,
    fullWidth: true,
    workoutFamily: entry.workout_type_family_id,
    metrics: Object.fromEntries(
      Object.entries(tempMetrics).filter(([_key, value]) => value !== "-" && value !== undefined)
    ) as unknown as Record<string, string>,
    planGroupId: entry.id,
    planId: indoorPlan?.id || 1,
    premium: entry.premium,
    ...(entry.plans?.[0]?.plan_metric?.ftp && {
      fourDpValues: {
        ftp: entry.plans?.[0]?.plan_metric?.ftp ?? 0,
        map: entry.plans?.[0]?.plan_metric?.map ?? 0,
        ac: entry.plans?.[0]?.plan_metric?.ac ?? 0,
        nm: entry.plans?.[0]?.plan_metric?.nm ?? 0,
      },
    }),
    planJson: [0, 1].includes(entry.workout_type_family_id)
      ? entry.plans?.[0]?.file?.url
      : undefined,
    indoorPlan,
    outdoorPlan: outdoorPlan || indoorPlan,
  };
}

export default usePlanGroupToPlanGroupCardProps;
