import { Geolocation } from "@capacitor/geolocation";
import { PLATFORM_ENUM } from "@WahooFitness/cloud-client-types";
import { useConfigContext } from "@WahooFitness/wahoo-offline-mfe";
import { useCallback, useEffect, useState } from "react";

export type GeolocationPosition = {
  latitude: number;
  longitude: number;
};

export enum GeolocationError {
  PERMISSION_UNAVAILABLE,
  PERMISSION_DENIED,
  POSITION_FAILED,
}

const useGeolocation = () => {
  const { platform } = useConfigContext();
  const [cachedPosition, setCachedPosition] = useState<GeolocationPosition | undefined>(undefined);
  const [error, setError] = useState<GeolocationError | undefined>(undefined);

  const getCurrentPosition = useCallback(async () => {
    setError(undefined);
    if (platform === PLATFORM_ENUM.web) {
      // Geolocation plugin is not implemented on web
      setCachedPosition(undefined);
      return;
    }
    let permission;
    try {
      permission = await Geolocation.checkPermissions();
    } catch {
      setCachedPosition(undefined);
      setError(GeolocationError.PERMISSION_UNAVAILABLE);
      return;
    }
    if (permission.location === "prompt") {
      try {
        await Geolocation.requestPermissions();
      } catch {
        setCachedPosition(undefined);
        setError(GeolocationError.PERMISSION_DENIED);
        return;
      }
    } else if (permission.location === "granted") {
      try {
        const coordinates = await Geolocation.getCurrentPosition();
        if (coordinates.coords) {
          const position = {
            latitude: coordinates.coords.latitude,
            longitude: coordinates.coords.longitude,
          };
          setCachedPosition(position);
          return position;
        }
      } catch {
        setCachedPosition(undefined);
        setError(GeolocationError.POSITION_FAILED);
        return;
      }
    }
  }, [platform]);

  const refreshCachedPosition = useCallback(async () => {
    getCurrentPosition();
  }, [getCurrentPosition]);

  useEffect(() => {
    refreshCachedPosition();
  }, [refreshCachedPosition]);

  return {
    cachedPosition,
    error,
    refreshCachedPosition,
    getCurrentPosition,
  };
};

export default useGeolocation;
