import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import { useMemo } from "react";
import { useCloudContext, useConfigContext, useOfflineSWR } from "@WahooFitness/wahoo-offline-mfe";
import { WorkoutPlanGroups } from "@WahooFitness/cloud-client-ts";
import usePlanGroupToPlanGroupCardProps from "./usePlanGroupToPlanGroupCardProps";

function useWorkouts() {
  const { curatedWorkoutsList } = useFlaggedFeatures();

  const { getCloudClient } = useCloudContext();
  const { wahooToken } = useConfigContext();
  const planGroupsClient = useMemo(() => getCloudClient(WorkoutPlanGroups), [getCloudClient]);

  const {
    data: curatedPlanGroups,
    error,
    isLoading,
  } = useOfflineSWR(
    ["CloudPlanGroups", wahooToken, curatedWorkoutsList.join("")],
    async ([_key, token]) => {
      const allPlanGroups = await planGroupsClient.get(token, ["plans", "plans:plan_metric"]);
      return allPlanGroups.filter((planGroup) => curatedWorkoutsList.includes(planGroup.id));
    }
  );

  const workoutsList = useMemo(() => {
    if (!curatedPlanGroups?.length || !curatedWorkoutsList.length) {
      return [];
    }
    return curatedPlanGroups.map(usePlanGroupToPlanGroupCardProps);
  }, [curatedPlanGroups, curatedWorkoutsList]);

  return {
    workoutsList,
    error,
    isLoading,
    refreshWorkoutsList: () => {},
  };
}

export default useWorkouts;
