import { Profile } from "@WahooFitness/cloud-client-types";
import { crux_bolt_app_profile_e } from "@WahooFitness/crux-js-display-cfg";

const getCruxAppProfile = (cloudAppProfile?: Profile) => {
  switch (cloudAppProfile) {
    case Profile.std:
      return crux_bolt_app_profile_e.STD;
    case Profile.beta:
      return crux_bolt_app_profile_e.BETA;
    case Profile.alpha:
      return crux_bolt_app_profile_e.ALPHA;
    case Profile.dev:
      return crux_bolt_app_profile_e.DEV;
    case Profile.factory:
      return crux_bolt_app_profile_e.FACTORY;
    default:
      return crux_bolt_app_profile_e.STD;
  }
};

const getCloudAppProfile = (cruxAppProfile?: number) => {
  switch (cruxAppProfile) {
    case crux_bolt_app_profile_e.STD:
      return Profile.std;
    case crux_bolt_app_profile_e.BETA:
      return Profile.beta;
    case crux_bolt_app_profile_e.ALPHA:
      return Profile.alpha;
    case crux_bolt_app_profile_e.DEV:
      return Profile.dev;
    case crux_bolt_app_profile_e.FACTORY:
      return Profile.factory;
    default:
      return Profile.std;
  }
};

export { getCruxAppProfile, getCloudAppProfile };
