import useSubscription, { SubscriptionState } from "@/hooks/useSubscription";
import { t } from "@lingui/macro";
import { useAnalyticsContext, useSnackbarContext } from "@WahooFitness/wahoo-offline-mfe";

const useTextForState = <T extends { [key: string]: string | null }>(
  state: SubscriptionState,
  config: { [key in SubscriptionState]: T }
) => {
  return config[state];
};

export const useMembershipConfig = ({
  onClose,
  analyticsParams,
}: {
  onClose: () => void;
  analyticsParams?: { [key: string]: string | number | undefined };
}) => {
  const { subscriptionState, mutateSubscription, startTrial } = useSubscription();
  const { enqueueSnackbar } = useSnackbarContext();
  const { logEvent } = useAnalyticsContext();

  const subscriptionStateConfig = {
    [SubscriptionState.Active]: {
      primaryText: "",
      secondaryText: "",
      buttonText: "",
      linkHref: "",
      analyticsButtonStateName: "",
      membershipBenefitsTitle: "",
    },
    [SubscriptionState.CanTrial]: {
      primaryText: t`Congratulations! You are about to unlock 14 days of free access to premium Wahoo X features.`,
      secondaryText: "",
      buttonText: t`Start Your Free Trial Now`,
      linkHref: "",
      analyticsButtonStateName: "activate_trial",
      membershipBenefitsTitle: t`Your free trial includes access to`,
    },
    [SubscriptionState.Expired]: {
      primaryText: t`Congratulations! You are about to unlock access to premium workouts. `,
      secondaryText: t`Begin training by activating your Wahoo X membership today.`,
      buttonText: t`Start Your Membership Now`,
      linkHref: `systm://billing`,
      analyticsButtonStateName: "activate_subscription",
      membershipBenefitsTitle: t`Your Wahoo X membership includes`,
    },
  };

  const {
    primaryText,
    buttonText,
    linkHref,
    analyticsButtonStateName,
    membershipBenefitsTitle,
    secondaryText,
  } = useTextForState(subscriptionState, subscriptionStateConfig);

  const membershipBenefitsText = [
    t`Personalized workout recommendations`,
    t`Smarter training with Four-Dimensional Power (4DP®)`,
    t`Immersive Videos`,
    t`Yoga, Strength, and Mobility`,
  ];
  const onButtonClick = async () => {
    logEvent("subscription_state_button_click", {
      button_state_name: analyticsButtonStateName,
      source_page_name: "paywall",
      ...analyticsParams,
    });
    if (subscriptionState === SubscriptionState.Expired) {
      onClose();
      return;
    }
    try {
      await startTrial();
      mutateSubscription();
      enqueueSnackbar({
        message: t`Trial started`,
        severity: "info",
      });
      onClose();
    } catch (e) {
      enqueueSnackbar({
        message: t`Failed to start trial`,
        severity: "error",
      });
    }
    return false;
  };

  return {
    onButtonClick,
    primaryText,
    membershipBenefitsText,
    buttonText,
    linkHref,
    membershipBenefitsTitle,
    secondaryText,
  };
};
