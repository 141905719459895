import {
  useSnackbarContext,
  useCloudContext,
  useConfigContext,
  useUserContext,
} from "@WahooFitness/wahoo-offline-mfe";
import Sex from "@/services/types/Sex";
import { DisplayPreferences, Users } from "@WahooFitness/cloud-client-ts";
import {
  DisplayPreferenceInputType,
  ImperialMetricDisplayPreference,
  PlanRecommendationsPreference,
  UserInputType,
  UserType,
} from "@WahooFitness/cloud-client-types";
import { formatISO, startOfYear, sub } from "date-fns";
import { FocusEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { t } from "@lingui/macro";
import { convertKilogramsToPounds, convertPoundsToKilograms } from "@/services/convertUnits";

const getLocalizedFieldNames = (): Record<string, string> => ({
  first: t`First Name`,
  last: t`Last Name`,
  display_name: t`RGT Display Name`,
  gender: t`Sex`,
  birth: t`Date of Birth`,
  country: t`Country`,
  locale: t`Language`,
  height: t`Height`,
  weight: t`Weight`,
});

const useAboutYou = (user: UserType) => {
  const localizedFieldNames = useMemo(() => getLocalizedFieldNames(), []);

  const { getCloudClient } = useCloudContext();
  const users = useMemo(() => getCloudClient(Users), [getCloudClient]);
  const displayPreferences = useMemo(() => getCloudClient(DisplayPreferences), [getCloudClient]);

  const { enqueueSnackbar } = useSnackbarContext();

  const { wahooToken } = useConfigContext();
  const { mutateUser } = useUserContext();

  const [defaultHeight, defaultWeight] = useMemo(() => {
    switch (user.gender) {
      case Sex.Male:
        return [
          user.display_preference?.speed_distance ? 180 : 70,
          user.display_preference?.weight ? 80 : 180,
        ];
      case Sex.Female:
        return [
          user.display_preference?.speed_distance ? 170 : 66,
          user.display_preference?.weight ? 68 : 150,
        ];
      case Sex.Other:
        return [
          user.display_preference?.speed_distance ? 175 : 68,
          user.display_preference?.weight ? 75 : 170,
        ];
      default:
        return [
          user.display_preference?.speed_distance ? 175 : 68,
          user.display_preference?.weight ? 75 : 170,
        ];
    }
  }, [user.display_preference?.speed_distance, user.display_preference?.weight, user.gender]);

  const weight = useMemo(() => {
    return (
      (user.display_preference?.weight === ImperialMetricDisplayPreference.metric
        ? +(user.weight || 0)
        : convertKilogramsToPounds(+(user.weight || 0))) || defaultWeight
    );
  }, [defaultWeight, user.display_preference?.weight, user.weight]);

  const height = useMemo(() => {
    return (
      (user.display_preference?.speed_distance === ImperialMetricDisplayPreference.metric
        ? Math.round(+(user.height || 0) * 10000) / 100
        : Math.round(+(user.height || 0) * 3937) / 100) || defaultHeight
    );
  }, [defaultHeight, user.display_preference?.speed_distance, user.height]);

  const units = useMemo(
    () => [
      user.display_preference?.elevation,
      user.display_preference?.speed_distance,
      user.display_preference?.temperature,
      user.display_preference?.weight,
    ],
    [
      user.display_preference?.elevation,
      user.display_preference?.speed_distance,
      user.display_preference?.temperature,
      user.display_preference?.weight,
    ]
  );

  const determineUnitPreference = useCallback(
    () =>
      (!user.display_preference && "metric") ||
      (units.every((val) => val) && "metric") ||
      (!units.some((val) => val) && "imperial") ||
      "custom",
    [units, user.display_preference]
  );

  const [unitPreference, setUnitPreference] = useState<"metric" | "imperial" | "custom">(
    determineUnitPreference()
  );

  const defaultBirthDate = useMemo(() => {
    return sub(startOfYear(new Date()), { years: 30 });
  }, []);

  const [birthDate, setBirthDate] = useState<Date>(
    user.birth ? new Date(`${user.birth}T00:00:00.000`) : defaultBirthDate
  );

  useEffect(() => {
    setBirthDate(user.birth ? new Date(`${user.birth}T00:00:00.000`) : defaultBirthDate);
  }, [defaultBirthDate, user.birth]);

  const updateUserField = useCallback(
    (value: any, fieldName: keyof UserInputType) => {
      if (user[fieldName] !== value) {
        const originalValue = user[fieldName];
        users.update(wahooToken, user.id, { [fieldName]: value }).catch(() => {
          enqueueSnackbar({
            message: t`An error occurred when saving ${localizedFieldNames[fieldName]}. Please check your internet connection and try again.`,
            severity: "error",
          });
          mutateUser({ ...user, [fieldName]: originalValue }, { revalidate: false });
        });
        mutateUser({ ...user, [fieldName]: value }, { revalidate: false });
      }
    },
    [enqueueSnackbar, localizedFieldNames, mutateUser, user, users, wahooToken]
  );

  const handleFirstNameChange: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> =
    useCallback(
      (event) => {
        updateUserField(event.target.value, "first");
      },
      [updateUserField]
    );

  const handleLastNameChange: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> =
    useCallback(
      (event) => {
        updateUserField(event.target.value, "last");
      },
      [updateUserField]
    );

  const handleSexChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> =
    useCallback(
      (event) => {
        updateUserField(event.target.value, "gender");
      },
      [updateUserField]
    );

  const handleBirthDateChange = useCallback(
    (value: Date | null) => {
      if (!value) {
        return;
      }
      updateUserField(formatISO(value, { representation: "date" }), "birth");
    },
    [updateUserField]
  );

  const handleCountryChange = useCallback(
    (value: string | null) => {
      if (!value) {
        return;
      }
      updateUserField(value, "country");
    },
    [updateUserField]
  );

  const updateDisplayPreferences = useCallback(
    async (update: DisplayPreferenceInputType) => {
      try {
        const newPreferences = await displayPreferences.update(wahooToken, update);
        mutateUser(
          {
            ...user,
            display_preference: newPreferences,
          },
          { revalidate: false }
        );
      } catch (error) {
        const sectionName = t`Unit Preferences`;
        enqueueSnackbar({
          message: t`An error occurred when saving ${sectionName}. Please check your internet connection and try again.`,
          severity: "error",
        });
      }
    },
    [displayPreferences, enqueueSnackbar, mutateUser, user, wahooToken]
  );

  const handleUnitPreferenceChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => void = useCallback(
    (_event, value) => {
      if (!value) {
        return;
      }
      if (value !== "custom" && value !== unitPreference) {
        const newValue =
          value === "metric"
            ? ImperialMetricDisplayPreference.metric
            : ImperialMetricDisplayPreference.imperial;
        const update = {
          elevation: newValue,
          speed_distance: newValue,
          temperature: newValue,
          weight: newValue,
        };
        updateDisplayPreferences(update);
      }
      setUnitPreference(value);
    },
    [unitPreference, updateDisplayPreferences]
  );

  const handleDistancePreferenceChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => void = useCallback(
    (_event, value) => {
      if (value !== null && value !== user.display_preference?.speed_distance) {
        const update = {
          speed_distance: value,
        };
        updateDisplayPreferences(update);
      }
    },
    [updateDisplayPreferences, user.display_preference?.speed_distance]
  );

  const handleElevationPreferenceChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => void = useCallback(
    (_event, value) => {
      if (value !== null && value !== user.display_preference?.elevation) {
        const update = {
          elevation: value,
        };
        updateDisplayPreferences(update);
      }
    },
    [updateDisplayPreferences, user.display_preference?.elevation]
  );

  const handleTemperaturePreferenceChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => void = useCallback(
    (_event, value) => {
      if (value !== null && value !== user.display_preference?.temperature) {
        const update = {
          temperature: value,
        };
        updateDisplayPreferences(update);
      }
    },
    [updateDisplayPreferences, user.display_preference?.temperature]
  );

  const handleWeightPreferenceChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => void = useCallback(
    (_event, value) => {
      if (value !== null && value !== user.display_preference?.weight) {
        const update = {
          weight: value,
        };
        updateDisplayPreferences(update);
      }
    },
    [updateDisplayPreferences, user.display_preference?.weight]
  );

  const handleHeightChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> =
    useCallback(
      (event) => {
        const newValue =
          user.display_preference?.speed_distance === ImperialMetricDisplayPreference.metric
            ? +event.target.value / 100
            : +event.target.value / 39.37;
        updateUserField(newValue, "height");
      },
      [updateUserField, user.display_preference?.speed_distance]
    );

  const handleWeightChange: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback(
    (event) => {
      const newValue =
        user.display_preference?.weight === ImperialMetricDisplayPreference.metric
          ? +event.target.value
          : convertPoundsToKilograms(+event.target.value);
      updateUserField(newValue, "weight");
    },
    [updateUserField, user.display_preference?.weight]
  );

  const handleRecommendationsPreferenceChange = useCallback(
    (isCurrentlyOff: boolean) => {
      if (isCurrentlyOff) {
        updateDisplayPreferences({
          plan_recommendations: PlanRecommendationsPreference.when_nothing_scheduled,
        });
      } else {
        updateDisplayPreferences({
          plan_recommendations: PlanRecommendationsPreference.never,
        });
      }
    },
    [updateDisplayPreferences]
  );

  return {
    height,
    weight,
    birthDate,
    unitPreference,
    updateUserField,
    handleFirstNameChange,
    handleLastNameChange,
    handleSexChange,
    handleBirthDateChange,
    handleUnitPreferenceChange,
    handleDistancePreferenceChange,
    handleElevationPreferenceChange,
    handleTemperaturePreferenceChange,
    handleWeightPreferenceChange,
    handleHeightChange,
    handleWeightChange,
    handleCountryChange,
    handleRecommendationsPreferenceChange,
  };
};

export default useAboutYou;
