import { useCallback, useMemo } from "react";
import { useWsmPlugin } from "./useWsmPlugin";
import WSMSensorConnectionState from "@WahooFitness/wsm-native/dist/esm/types/connection_state";
import { t } from "@lingui/macro";
import useSWR from "swr";
import { WebWsmDevice } from "@/services/WebWsmDevice";
import useBoltTypeStore from "./useBoltTypeStore";
import { PLATFORM_ENUM } from "@WahooFitness/cloud-client-types";
import { useConfigContext } from "@WahooFitness/wahoo-offline-mfe";

const usePairedDevice = (appToken?: string) => {
  const { platform } = useConfigContext();
  const { sensorManagerPlugin } = useWsmPlugin();

  const { getAllStoredBoltTypes, storeBoltType, deleteStoredBoltType } = useBoltTypeStore();

  const {
    data: devices,
    error: devicesError,
    isLoading: devicesLoading,
    mutate: mutateDevices,
  } = useSWR(
    () => appToken && ["getDevices"],
    async () => {
      const devices = (await sensorManagerPlugin?.getDevices())?.devices;
      if (devices) {
        Object.keys(getAllStoredBoltTypes()).forEach((appToken) => {
          if (devices.every((device) => device.appToken !== appToken)) {
            deleteStoredBoltType(appToken);
          }
        });
        devices.forEach((device) => {
          if (device.boltType && device.appToken) {
            storeBoltType(device.appToken, device.boltType);
          }
        });
      }
      return devices;
    }
  );

  const device = useMemo(
    () => devices?.find((device) => device.appToken === appToken),
    [appToken, devices]
  );

  const reloadDevice = useCallback(() => {
    mutateDevices();
  }, [mutateDevices]);

  const connectionStateDisplay = useMemo(() => {
    switch (device?.connectionState) {
      case WSMSensorConnectionState.CONNECTED:
        return t`Connected`;
      case WSMSensorConnectionState.CONNECTING:
        return t`Connecting`;
      case WSMSensorConnectionState.DISCONNECTED:
        return t`Not connected`;
      case WSMSensorConnectionState.DISCONNECTING:
        return t`Disconnecting`;
    }
  }, [device?.connectionState]);

  const unpairDevice = useCallback(async () => {
    if (device) {
      await sensorManagerPlugin?.unpairFromDevice(device);
      mutateDevices();
    }
  }, [device, mutateDevices, sensorManagerPlugin]);

  const isDeviceConnected = useMemo(
    () => device?.connectionState === WSMSensorConnectionState.CONNECTED,
    [device?.connectionState]
  );

  const setDeviceName = useCallback(
    async (name: string) => {
      if (device) {
        await sensorManagerPlugin?.setDeviceName({ sensorId: device.sensorId, name });
      }
    },
    [device, sensorManagerPlugin]
  );

  // If running in Web, return a mock device
  // We could do this in WSM, but it would mean WSM code changes to test different
  // device scenarios.
  if (platform === PLATFORM_ENUM.web) {
    return {
      device: WebWsmDevice,
      deviceLoading: false,
      reloadDevice: () => {},
      isDeviceConnected: true,
      connectionStateDisplay: t`Connected`,
      unpairDevice: () => {},
      setDeviceName: () => {},
    };
  }

  return {
    device,
    deviceError: devicesError,
    deviceLoading: devicesLoading,
    reloadDevice,
    isDeviceConnected,
    connectionStateDisplay,
    unpairDevice,
    setDeviceName,
  };
};

export default usePairedDevice;
