import { t, Trans } from "@lingui/macro";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import completionImage from "@/assets/KickrRunOnboarding.png";
import { LobbyEntryTypeEnum } from "@WahooFitness/cloud-client-types";
import { PlanGroupLobbyCard } from "@WahooFitness/redesignr";
import { formatDuration } from "@WahooFitness/unit-convertr-ts";
import { useUnitFormatter } from "@/hooks/useUnitFormatter";
import useOnboardingCompleted from "./useOnboardingCompleted";
import { postClose } from "@WahooFitness/wahoo-offline-mfe";
import { useLocation } from "react-router";
import { useMemo, useCallback } from "react";

const OnboardingCompleted = () => {
  const { planGroupData, handleExit, handleCardClick } = useOnboardingCompleted();
  const { formatTSS } = useUnitFormatter();
  const { search } = useLocation();
  const isVideoPartOnly = useMemo(() => search.includes("video_link"), [search]);
  const handleVideoPartOnlyClose = useCallback(() => {
    postClose("runSetupVideos");
  }, []);

  return (
    <>
      <Box display="flex" flexDirection="column" gap={3} alignItems="center" overflow="auto">
        <img style={{ maxWidth: "100%" }} src={completionImage} />
        <Box display="flex" flexDirection="column" rowGap={1} mx={3}>
          <Typography variant="prose-lg-bold" align="center">
            <Trans>Your KICKR RUN is set up and ready to be put through its paces.</Trans>
          </Typography>
          {planGroupData && (
            <Typography variant="prose-sm" align="center" color="text.secondary">
              <Trans>We recommend this workout to get started.</Trans>
            </Typography>
          )}
        </Box>
        {planGroupData && (
          <Box display="flex" flexDirection="column" width="100%" px={3} alignItems="center">
            <PlanGroupLobbyCard
              fullWidth
              title={planGroupData.name}
              description={planGroupData.description}
              onClick={handleCardClick}
              id={planGroupData.id.toString()}
              linkLocation={""}
              type={LobbyEntryTypeEnum.LobbyPlanGroupEntry}
              position={0}
              workoutFamily={planGroupData.workout_type_family_id}
              metrics={{
                duration: formatDuration(
                  planGroupData?.plans?.[0]?.plan_metric?.duration ?? 0,
                  "pretty"
                ),
                [t`IF®`]: planGroupData?.plans?.[0]?.plan_metric?.intensity_factor
                  ? String(planGroupData?.plans?.[0]?.plan_metric?.intensity_factor)
                  : "",
                [t`TSS®`]: formatTSS(planGroupData?.plans?.[0]?.plan_metric?.tss ?? 0),
              }}
              planGroupId={planGroupData.id}
              planId={planGroupData.plans?.[0].id ?? 0}
              premium={false}
              planJson={planGroupData.plans?.[0]?.file?.url ?? ""}
              image={planGroupData?.image?.url ? new URL(planGroupData?.image?.url) : undefined}
            />
          </Box>
        )}
      </Box>
      <Box p={3} width="100%" mt="auto" maxWidth="sm">
        <Button
          variant="contained"
          size="large"
          fullWidth
          onClick={isVideoPartOnly ? handleVideoPartOnlyClose : handleExit}
        >
          <Trans>Done</Trans>
        </Button>
      </Box>
    </>
  );
};

export default OnboardingCompleted;
