import GlobalDialog from "@/components/GlobalDialog";
import GlobalDrawer from "@/components/GlobalDrawer";
import GlobalSnackbar from "@/components/GlobalSnackbar";
import Header from "@/components/Header";
import { HeaderContextProvider } from "@/contexts/HeaderContext";
import {
  useConfigContext,
  useInitialRouteContext,
  useNativeMessagingContext,
} from "@WahooFitness/wahoo-offline-mfe";
import { Box, Container } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Outlet, useLocation, useNavigate } from "react-router";

function RouteWrapper() {
  const { globalBottomPadding } = useConfigContext();

  const location = useLocation();
  const { setRoute } = useInitialRouteContext();
  const { setOnNavigation } = useNativeMessagingContext();
  const navigate = useNavigate();

  // We have to do this to stop the index page from trying
  // to navigate back to a sub page automatically when
  // the route is sent in from the chassis
  useEffect(() => {
    if (location.state?.initial) {
      setRoute(undefined);
    }
  }, [location.state?.initial, setRoute]);

  useEffect(() => {
    setOnNavigation(() => navigate);
  }, [navigate, setOnNavigation]);

  return (
    <HeaderContextProvider>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
      </Helmet>

      <Box
        display="flex"
        flexDirection="column"
        height="100dvh"
        overflow="hidden"
        bgcolor="background.default"
      >
        <Header />
        <Box
          overflow="auto"
          display="flex"
          flexDirection="column"
          flexGrow={1}
          flexShrink={1}
          paddingBottom={globalBottomPadding}
        >
          <Container maxWidth="sm" disableGutters sx={{ height: "100%" }}>
            <Outlet />
          </Container>
        </Box>
      </Box>
      <GlobalDialog />
      <GlobalSnackbar />
      <GlobalDrawer />
    </HeaderContextProvider>
  );
}

export default RouteWrapper;
