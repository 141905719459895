import { Meter, Timer } from "@carbon/icons-react";
import { t } from "@lingui/macro";
import OnboardingHeader from "../../OnboardingHeader";
import BestKnownTimesQuestion from "./BestKnownTimesQuestion";
import useBestKnownTimes from "./useBestKnownTimesPage";
import BestKnownTimesInput from "./BestKnownTimesInput/BestKnownTimesInput";
import YourPaces from "../YourPaces/YourPaces";
import NoBestKnownTimesInput from "./NoBestKnownTimesInput/NoBestKnownTimesInput";
import { PaceZoneAlgorithmLookupsResponseType } from "@WahooFitness/cloud-client-types";

interface Props {
  paceResultsAvailable: boolean;
  setPaceResultsAvailable: (value: boolean) => void;
  yourPaceZones: PaceZoneAlgorithmLookupsResponseType | undefined;
  setYourPaceZones: (paceZones: PaceZoneAlgorithmLookupsResponseType) => void;
  handleSave: () => void;
}
const BestKnownTimePage = ({
  paceResultsAvailable,
  setPaceResultsAvailable,
  yourPaceZones,
  setYourPaceZones,
  handleSave,
}: Props) => {
  const { bestTimeIsKnown, bestKnownTimesQuestionItems } = useBestKnownTimes();

  return (
    <>
      {paceResultsAvailable && !!yourPaceZones ? (
        <>
          <OnboardingHeader
            Icon={Meter}
            title={t`Your training paces`}
            subtitle={t`We have estimated your training paces based upon your inputs, you can update them in Settings -> Running pace zones.`}
          />
          <YourPaces yourPaceZones={yourPaceZones} handleSave={handleSave} />
        </>
      ) : (
        <>
          <OnboardingHeader
            Icon={Timer}
            title={t`Best known times`}
            subtitle={t`Do you know your best known running times in the past 3 months? We will use those times to estimate your running paces.`}
          />
          <BestKnownTimesQuestion bestKnownTimesQuestionItems={bestKnownTimesQuestionItems} />
          {bestTimeIsKnown ? (
            <BestKnownTimesInput
              setPaceResultsAvailable={setPaceResultsAvailable}
              setYourPaceZones={setYourPaceZones}
            />
          ) : (
            <NoBestKnownTimesInput
              setPaceResultsAvailable={setPaceResultsAvailable}
              setYourPaceZones={setYourPaceZones}
            />
          )}
        </>
      )}
    </>
  );
};

export default BestKnownTimePage;
