import ZonesEditor from "@/components/Zones/ZonesEditor";
import { Zone } from "@/components/Zones/useZones";
import { PaceZoneAlgorithmLookupsResponseType } from "@WahooFitness/cloud-client-types";
import { useUnitFormatter } from "@/hooks/useUnitFormatter";
import { Trans } from "@lingui/macro";
import { Button, Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useLingui } from "@lingui/react";

interface YourPaceProps {
  yourPaceZones: PaceZoneAlgorithmLookupsResponseType;
  handleSave: () => void;
}

type NonBooleanKeys<T> = {
  [K in keyof T]: T[K] extends boolean ? never : K;
}[keyof T];

const YourPaces = ({ yourPaceZones, handleSave }: YourPaceProps) => {
  const { i18n } = useLingui();
  const { unitFormatter, subtractPaceSecondFromMps } = useUnitFormatter();

  const getBottomValue = (zone: number) => {
    if (zone === 1) {
      return 0;
    }
    const previousZoneTop =
      yourPaceZones[
        `zone_${zone - 1}` as keyof NonBooleanKeys<PaceZoneAlgorithmLookupsResponseType>
      ];

    return subtractPaceSecondFromMps(previousZoneTop);
  };

  const getTopValue = (zone: number): number | "max" => {
    if (zone === zones.length) {
      return "max";
    }

    return yourPaceZones[
      `zone_${zone}` as keyof NonBooleanKeys<PaceZoneAlgorithmLookupsResponseType>
    ];
  };

  const zones = Array.from({ length: 7 }, (_, i) => i + 1);
  const formattedPaceZones: Zone[] = zones.map((zone) => {
    const bottom = getBottomValue(zone);
    const top = getTopValue(zone);

    return {
      bottom,
      top,
      name: i18n._("Zone {zone}", { zone }),
    };
  });

  return (
    <>
      <Box display="flex" flexDirection="column" rowGap={1} px={3}>
        <Paper elevation={4} sx={{ p: 2 }}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="ui-base">
              <Trans>Threshold Pace</Trans>
            </Typography>
            <Typography variant="ui-base">
              {unitFormatter?.formatRunningPace(yourPaceZones.threshold_speed ?? 0).rawValue}
            </Typography>
          </Box>
          <Divider />
          <ZonesEditor
            type="speed"
            zones={formattedPaceZones}
            onZoneChange={() => {}}
            fixInvalidZones={() => {}}
          />
        </Paper>
      </Box>
      <Box display="flex" p={3} justifyContent="center" maxWidth={"sm"}>
        <Button size="large" variant="contained" fullWidth onClick={handleSave}>
          <Trans>Save</Trans>
        </Button>
      </Box>
    </>
  );
};

export default YourPaces;
