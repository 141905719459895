import {
  BikeIcon,
  CanoeingIcon,
  CardioClassIcon,
  ClimberIcon,
  CrossCountrySkiingIcon,
  CyclocrossIcon,
  RedesignrIconType,
  DownhillSkiingIcon,
  EBikingIcon,
  EllipticalIcon,
  GeneralTransitionIcon,
  GolfingIcon,
  HikingIcon,
  IceSkatingIcon,
  IndoorIcon,
  InlineSkatingIcon,
  KayakingIcon,
  KiteboardingIcon,
  LapSwimIcon,
  LongboardingIcon,
  MotorbikingIcon,
  MountainBikingIcon,
  MountaineeringIcon,
  NordicIcon,
  OpenWaterIcon,
  OtherIcon,
  RecumbentIcon,
  RoadIcon,
  RowerIcon,
  RowingIcon,
  RunningIcon,
  SailingIcon,
  SkatingIcon,
  SkiingIcon,
  SnowboardingIcon,
  SpeedWalkingIcon,
  StairClimberIcon,
  SUPIcon,
  SwimmingIcon,
  Tickr2XIcon,
  TrackIcon,
  TrackRunningIcon,
  TrailIcon,
  TreadmillIcon,
  TreadmillWalkingIcon,
  TriathlonIcon,
  WalkingIcon,
  WheelchairIcon,
  WindsurfingIcon,
  WorkoutBikeIcon,
  WorkoutIcon,
  YogaIcon,
  MentalTrainingIcon,
} from "@WahooFitness/redesignr";
import { WahooWorkoutType, WahooWorkoutTypeFamily } from "@WahooFitness/cloud-client-types";

const workoutTypeIcons: { [WahooWorkoutType: number]: RedesignrIconType | undefined | null } = {
  [WahooWorkoutType.BikingIndoor]: IndoorIcon,
  [WahooWorkoutType.BikingIndoorVirtual]: IndoorIcon,
  [WahooWorkoutType.BikingIndoorTraining]: IndoorIcon,
  [WahooWorkoutType.Yoga]: YogaIcon,
  [WahooWorkoutType.Strength]: WorkoutIcon,
  [WahooWorkoutType.BikingRoad]: RoadIcon,
  [WahooWorkoutType.Swimming]: SwimmingIcon,
  [WahooWorkoutType.Rowing]: RowingIcon,
  [WahooWorkoutType.Running]: RunningIcon,
  [WahooWorkoutType.RunningTreadmill]: TreadmillIcon,
  [WahooWorkoutType.Other]: OtherIcon,
  [WahooWorkoutType.Multisport]: TriathlonIcon,
  [WahooWorkoutType.BikingMountain]: MountainBikingIcon,
  [WahooWorkoutType.BikingCyclocross]: CyclocrossIcon,
  [WahooWorkoutType.Biking]: RoadIcon,
  [WahooWorkoutType.FE]: WorkoutIcon,
  [WahooWorkoutType.RunningTrack]: TrackRunningIcon,
  [WahooWorkoutType.RunningTrail]: TrailIcon,
  [WahooWorkoutType.Walking]: WalkingIcon,
  [WahooWorkoutType.WalkingSpeed]: SpeedWalkingIcon,
  [WahooWorkoutType.WalkingNordic]: NordicIcon,
  [WahooWorkoutType.Hiking]: HikingIcon,
  [WahooWorkoutType.Mountaineering]: MountaineeringIcon,
  [WahooWorkoutType.BikingRecumbent]: RecumbentIcon,
  [WahooWorkoutType.BikingTrack]: TrackIcon,
  [WahooWorkoutType.BikingMotocycling]: MotorbikingIcon,
  [WahooWorkoutType.FEGeneral]: WorkoutIcon,
  [WahooWorkoutType.FETreadmill]: TreadmillIcon,
  [WahooWorkoutType.FEElliptical]: EllipticalIcon,
  [WahooWorkoutType.FEBike]: BikeIcon,
  [WahooWorkoutType.FERower]: RowerIcon,
  [WahooWorkoutType.FEClimber]: ClimberIcon,
  [WahooWorkoutType.SwimmingLap]: LapSwimIcon,
  [WahooWorkoutType.SwimmingOpenWater]: OpenWaterIcon,
  [WahooWorkoutType.Snowboarding]: SnowboardingIcon,
  [WahooWorkoutType.Skiing]: SkiingIcon,
  [WahooWorkoutType.SkiingDownhill]: DownhillSkiingIcon,
  [WahooWorkoutType.SkiingCrossCountry]: CrossCountrySkiingIcon,
  [WahooWorkoutType.Skating]: SkatingIcon,
  [WahooWorkoutType.SkatingIce]: IceSkatingIcon,
  [WahooWorkoutType.SkatingInline]: InlineSkatingIcon,
  [WahooWorkoutType.LongBoarding]: LongboardingIcon,
  [WahooWorkoutType.Sailing]: SailingIcon,
  [WahooWorkoutType.WindSurfing]: WindsurfingIcon,
  [WahooWorkoutType.Canoeing]: CanoeingIcon,
  [WahooWorkoutType.Kayaking]: KayakingIcon,
  [WahooWorkoutType.Kiteboarding]: KiteboardingIcon,
  [WahooWorkoutType.StandUpPaddleBoard]: SUPIcon,
  [WahooWorkoutType.CardioClass]: CardioClassIcon,
  [WahooWorkoutType.StairClimber]: StairClimberIcon,
  [WahooWorkoutType.Wheelchair]: WheelchairIcon,
  [WahooWorkoutType.Golfing]: GolfingIcon,
  [WahooWorkoutType.BikingIndoorCyclingClass]: WorkoutBikeIcon,
  [WahooWorkoutType.WalkingTreadmill]: TreadmillWalkingIcon,
  [WahooWorkoutType.Transition]: GeneralTransitionIcon,
  [WahooWorkoutType.EBiking]: EBikingIcon,
  [WahooWorkoutType.TickrOffline]: Tickr2XIcon,
  [WahooWorkoutType.RunningRace]: RunningIcon,
  [WahooWorkoutType.MentalStrength]: MentalTrainingIcon,
};

const workoutTypeFamilyIcons: { [WahooWorkoutFamilyType: number]: RedesignrIconType | undefined } =
  {
    [WahooWorkoutTypeFamily.Cycling]: RoadIcon,
    [WahooWorkoutTypeFamily.Running]: RunningIcon,
    [WahooWorkoutTypeFamily.Swimming]: SwimmingIcon,
    [WahooWorkoutTypeFamily.Water]: SailingIcon,
    [WahooWorkoutTypeFamily.Snow]: SkiingIcon,
    [WahooWorkoutTypeFamily.Skating]: SkatingIcon,
    [WahooWorkoutTypeFamily.Gym]: EllipticalIcon,
    [WahooWorkoutTypeFamily.Yoga]: YogaIcon,
    [WahooWorkoutTypeFamily.Strength]: WorkoutIcon,
    [WahooWorkoutTypeFamily.Walking]: WalkingIcon,
    [WahooWorkoutTypeFamily.Other]: OtherIcon,
  };

export function getWorkoutTypeIcon(type: WahooWorkoutType | null | undefined): RedesignrIconType {
  if (type === null || type === undefined) return OtherIcon;
  return workoutTypeIcons[type] || OtherIcon;
}

export function getWorkoutTypeFamilyIcon(
  type: WahooWorkoutTypeFamily | null | undefined
): RedesignrIconType {
  if (type === null || type === undefined) return OtherIcon;
  return workoutTypeFamilyIcons[type] || OtherIcon;
}
