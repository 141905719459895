import { useCallback, useEffect, useMemo, useState } from "react";
import { Checkbox } from "@carbon/icons-react";
import { CheckMarkIcon, MenuListItemType, MenuListItemVariant } from "@WahooFitness/redesignr";
import {
  WahooWorkoutTypeFamily,
  WorkoutProfileResponseType,
} from "@WahooFitness/cloud-client-types";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import { getWorkoutTypeIcon } from "@/services/getWorkoutTypeIcon";

const useAssignedProfiles = (
  zoneSetId: number,
  type: "power" | "heartRate" | "speed",
  primaryZoneSetId: number | undefined,
  editing: boolean
) => {
  const { workoutProfiles, setSelectedAssociationList } = useWorkoutProfilesContext();
  const [open, setOpen] = useState<boolean>(false);
  const profileField: keyof WorkoutProfileResponseType = useMemo(() => {
    switch (type) {
      case "power":
        return "power_zone_id";
      case "heartRate":
        return "heart_rate_zone_id";
      case "speed":
        return "pace_zone_id";
    }
  }, [type]);
  const [selectedList, setSelectedList] = useState<number[] | undefined>(undefined);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCancel = useCallback(() => {
    handleClose();
    setSelectedList(undefined);
  }, [handleClose]);

  const selectedProfiles = useMemo(
    () =>
      workoutProfiles
        .filter(
          (profile) =>
            profile[profileField] === zoneSetId ||
            (zoneSetId === primaryZoneSetId && !profile[profileField])
        )
        .map((profile) => profile.id),
    [primaryZoneSetId, profileField, workoutProfiles, zoneSetId]
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
    if (!selectedList) {
      setSelectedList(selectedProfiles);
    }
  }, [selectedList, selectedProfiles]);

  useEffect(() => {
    if (!editing) {
      setSelectedList(selectedProfiles);
    }
  }, [selectedProfiles, editing]);

  const handleProfileClick = useCallback(
    (profileId: number) => {
      const newList = [...(selectedList || [])];
      if (newList.includes(profileId)) {
        setSelectedList(newList.filter((id) => id !== profileId));
      } else {
        newList.push(profileId);
        setSelectedList(newList);
      }
    },
    [selectedList]
  );

  const handleSelectAll = useCallback(() => {
    // filter out profiles that are attached to a different ZoneSet that is not the primary
    setSelectedList(
      workoutProfiles
        .filter(
          (profile) =>
            !profile[profileField] ||
            !(profile[profileField] !== zoneSetId && profile[profileField] !== primaryZoneSetId)
        )
        .map((profile) => profile.id)
    );
  }, [primaryZoneSetId, profileField, workoutProfiles, zoneSetId]);

  const handleSelectNone = useCallback(() => {
    setSelectedList([]);
  }, []);

  const handleSave = useCallback(async () => {
    setSelectedAssociationList(selectedList);
    handleClose();
  }, [handleClose, selectedList, setSelectedAssociationList]);

  const profileCount = useMemo(
    () => selectedList?.length ?? selectedProfiles.length,
    [selectedList?.length, selectedProfiles.length]
  );

  const isPrimary = useMemo(() => {
    return zoneSetId === primaryZoneSetId;
  }, [primaryZoneSetId, zoneSetId]);

  const sportSections = useMemo(() => {
    return workoutProfiles.reduce(
      (acc, cur) => {
        const family: WahooWorkoutTypeFamily =
          cur.workout_type_family_id ?? WahooWorkoutTypeFamily.Other;
        if (!acc[family]) {
          acc[family] = [];
        }
        const WorkoutTypeIcon = getWorkoutTypeIcon(cur.workout_type_id);
        acc[family].push({
          id: String(cur.id),
          icon: <WorkoutTypeIcon />,
          content: cur.name,
          variant: MenuListItemVariant.Action,
          disabled:
            isPrimary ||
            (!!cur[profileField] &&
              cur[profileField] !== zoneSetId &&
              cur[profileField] !== primaryZoneSetId),
          action: () => handleProfileClick(cur.id),
          actionComponent: selectedList?.includes(cur.id) ? (
            <CheckMarkIcon />
          ) : (
            <Checkbox size={24} />
          ),
        });
        return acc;
      },
      {} as Record<WahooWorkoutTypeFamily, MenuListItemType[]>
    );
  }, [
    handleProfileClick,
    isPrimary,
    primaryZoneSetId,
    profileField,
    selectedList,
    workoutProfiles,
    zoneSetId,
  ]);

  return {
    workoutProfiles,
    profileCount,
    isPrimary,
    sportSections,
    selectedList,
    open,
    handleOpen,
    handleCancel,
    handleSelectAll,
    handleSelectNone,
    handleSave,
  };
};

export default useAssignedProfiles;
