import { WorkoutPlanGroups } from "@WahooFitness/cloud-client-ts";
import { PlanGroupType } from "@WahooFitness/cloud-client-types";
import {
  useAnalyticsContext,
  useConfigContext,
  useCloudContext,
  postRoute,
  postStartPlanMessage,
} from "@WahooFitness/wahoo-offline-mfe";
import { useState, useMemo, useCallback, useEffect } from "react";

function useOnboardingCompleted() {
  const { getRemoteConfigString } = useAnalyticsContext();
  const { wahooToken } = useConfigContext();
  const { getCloudClient } = useCloudContext();
  const [planGroupData, setPlanGroupData] = useState<PlanGroupType | null>(null);
  const planGroupClient = useMemo(() => getCloudClient(WorkoutPlanGroups), [getCloudClient]);
  const planGroupId = useMemo(
    () => getRemoteConfigString("KICKR_RUN_ONBOARDING_PLAN_GROUP_ID"),
    [getRemoteConfigString]
  );
  const fetchPlanGroupData = useCallback(async () => {
    if (planGroupId !== "" && planGroupId) {
      const data = await planGroupClient.getById(wahooToken, Number(planGroupId), [
        "plans",
        "plans:plan_metric",
      ]);
      setPlanGroupData(data);
    }
  }, [planGroupClient, wahooToken, planGroupId]);

  useEffect(() => {
    if (planGroupId !== "" && planGroupId) {
      fetchPlanGroupData();
    }
  }, [fetchPlanGroupData, planGroupId]);

  const handleExit = useCallback(() => postRoute("onboardingCompleted"), []);
  const handleCardClick = useCallback(() => {
    postStartPlanMessage(planGroupData?.plans?.[0]?.id ?? 0);
  }, [planGroupData?.plans]);

  return {
    planGroupData,
    handleExit,
    handleCardClick,
  };
}

export default useOnboardingCompleted;
