import useSubscription, { SubscriptionState } from "@/hooks/useSubscription";
import { useWorkoutScheduler } from "@/hooks/useWorkoutScheduler";
import { convertWorkoutFamilyToWorkoutType } from "@/services/workoutFamilyToWorkoutTypeConverter";
import { t } from "@lingui/macro";
import { Typography } from "@mui/material";
import { WahooWorkoutTypeFamily } from "@WahooFitness/cloud-client-types";
import {
  GenericComputerIcon,
  MenuList,
  MenuListItemType,
  MenuListItemVariant,
  SystmAppIcon,
  WahooAppIcon,
} from "@WahooFitness/redesignr";
import {
  postStartPlanMessage,
  useAnalyticsContext,
  useConfigContext,
  useDialogContext,
  useDrawerContext,
  useUserContext,
} from "@WahooFitness/wahoo-offline-mfe";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { ExtendedPlanGroupLobbyCardType } from "./useWorkoutsFilters";
import Membership from "../Membership/Membership";

const SYSTM_ONLY_WORKOUT_TYPE_FAMILIES = [
  WahooWorkoutTypeFamily.Mental,
  WahooWorkoutTypeFamily.Yoga,
  WahooWorkoutTypeFamily.Strength,
  WahooWorkoutTypeFamily.Gym,
  WahooWorkoutTypeFamily.Other,
];

const PLAYR_ONLY_WORKOUT_TYPE_FAMILIES = [
  WahooWorkoutTypeFamily.Running,
  WahooWorkoutTypeFamily.Swimming,
  WahooWorkoutTypeFamily.Walking,
];

const ELEMNT_WORKOUT_TYPE_FAMILIES = [WahooWorkoutTypeFamily.Cycling];
const SYSTM_CONTENT_DETAILS_URL = "https://systm.wahoofitness.com/content-details/";

function useWorkoutClickHandler() {
  const { fitnessAppId, componentId } = useConfigContext();
  const { subscriptionState } = useSubscription();
  const { logEvent } = useAnalyticsContext();
  const { setDialog, handleClose } = useDialogContext();
  const navigate = useNavigate();
  const { currentUser } = useUserContext();
  const { setDrawer, handleClose: handleCloseDrawer } = useDrawerContext();
  const { scheduleWorkoutInCloud, sendWorkoutToElemnt } = useWorkoutScheduler();

  const logAnalyticsEvent = useCallback(
    (eventName: string, selectedApp: string, entry: ExtendedPlanGroupLobbyCardType) => {
      logEvent(eventName, {
        workout_type_family_id: entry.workoutFamily,
        launch_target_app: selectedApp,
        lobby_card_type: entry.type,
        id: entry.id,
        title: entry.title,
        plan_group_id: entry.planGroupId,
      });
    },
    [logEvent]
  );

  const showMissingPaceZonesDialog = useCallback(() => {
    setDialog({
      open: true,
      title: t`Missing pace zones`,
      body: t`Set up your running pace zones to do this workout.`,
      actions: [
        { text: t`Cancel`, action: handleClose },
        {
          text: t`Set up zones`,
          action: () => {
            handleClose();
            navigate("/pace-zones");
          },
        },
      ],
    });
  }, [handleClose, navigate, setDialog]);

  const showMembershipDrawer = useCallback(
    (entry: ExtendedPlanGroupLobbyCardType) => {
      const membershipDrawerTitle =
        subscriptionState === SubscriptionState.CanTrial
          ? t`Free Wahoo X Trial`
          : t`Wahoo X Membership`;
      setDrawer({
        open: true,
        title: membershipDrawerTitle,
        hidePuller: true,
        children: (
          <Membership
            onClose={handleCloseDrawer}
            analyticsParams={{
              plan_group_id: entry.planGroupId,
              fitness_app_id: fitnessAppId,
              component_app_id: componentId,
              title: entry.title,
              id: entry.id,
            }}
          />
        ),
      });
    },
    [componentId, fitnessAppId, handleCloseDrawer, setDrawer, subscriptionState]
  );

  const noActiveSub = useMemo(
    () => [SubscriptionState.CanTrial, SubscriptionState.Expired].includes(subscriptionState),
    [subscriptionState]
  );

  const userNeedsPaceZones = useCallback(
    (entry: ExtendedPlanGroupLobbyCardType) => {
      const isRunningWorkout = entry.workoutFamily === WahooWorkoutTypeFamily.Running;
      const userHasPaceZones = currentUser?.pace_zones?.length;
      return isRunningWorkout && !userHasPaceZones;
    },
    [currentUser?.pace_zones?.length]
  );

  const startOnElemnt = useCallback(
    async (entry: ExtendedPlanGroupLobbyCardType) => {
      const workoutId = await scheduleWorkoutInCloud(
        entry.title,
        Math.round((entry.outdoorPlan?.plan_metric.duration || 0) / 60),
        entry.planGroupId,
        convertWorkoutFamilyToWorkoutType(entry.workoutFamily ?? 31)
      );
      logAnalyticsEvent("launch_workout_initiate", "elemnt", entry);
      sendWorkoutToElemnt(
        entry.outdoorPlan?.plan_provider_type_id,
        `${entry.outdoorPlan?.id}`,
        workoutId,
        entry.outdoorPlan?.fitness_app_id
      );
    },
    [logAnalyticsEvent, scheduleWorkoutInCloud, sendWorkoutToElemnt]
  );

  const populateDrawerItems = useCallback(
    (entry: ExtendedPlanGroupLobbyCardType) => {
      const startSystmAndCloseDrawer = () => {
        logAnalyticsEvent("launch_workout_initiate", "systm", entry);
        handleCloseDrawer();
      };
      const startPlayrAndCloseDrawer = () => {
        logAnalyticsEvent("launch_workout_initiate", "playr", entry);
        postStartPlanMessage(entry.planId);
        handleCloseDrawer();
      };
      const startElemnt = async () => {
        logAnalyticsEvent("launch_workout_initiate", "elemnt", entry);
        await startOnElemnt(entry);
      };
      const systmContentId = entry.indoorPlan?.external_id;
      const systmDrawerItem: MenuListItemType = {
        id: "0",
        content: <Typography variant="ui-lg-bold">{t`Open in SYSTM app`}</Typography>,
        secondaryContent: t`For immersive video & audio experience`,
        variant: MenuListItemVariant.ExternalLink,
        action: startSystmAndCloseDrawer,
        linkLocation: systmContentId ? `${SYSTM_CONTENT_DETAILS_URL}${systmContentId}` : undefined,
        icon: <SystmAppIcon height={24} width={24} style={{ borderRadius: 4 }} />,
      };

      const playrDrawerItem: MenuListItemType = {
        id: "1",
        content: <Typography variant="ui-lg-bold">{t`Start now`}</Typography>,
        secondaryContent: t`Launch this workout`,
        variant: MenuListItemVariant.Action,
        action: startPlayrAndCloseDrawer,
        icon: <WahooAppIcon height={24} width={24} style={{ borderRadius: 4 }} />,
      };

      const sendToElemntDrawerItem: MenuListItemType = {
        id: "2",
        content: <Typography variant="ui-lg-bold">{t`Send to ELEMNT`}</Typography>,
        secondaryContent: t`Add to your ELEMNT Bike Computer`,
        variant: MenuListItemVariant.Action,
        icon: <GenericComputerIcon height={24} width={24} />,
        action: startElemnt,
      };

      const isPlayrOnlyType =
        entry.workoutFamily !== undefined &&
        PLAYR_ONLY_WORKOUT_TYPE_FAMILIES.includes(entry.workoutFamily);
      const isSystmOnlyType =
        entry.workoutFamily !== undefined &&
        SYSTM_ONLY_WORKOUT_TYPE_FAMILIES.includes(entry.workoutFamily);
      const isElemntSupportedType =
        entry.workoutFamily !== undefined &&
        ELEMNT_WORKOUT_TYPE_FAMILIES.includes(entry.workoutFamily);

      const supportedLobbyActions: MenuListItemType[] = [];

      if (isSystmOnlyType && systmContentId) {
        supportedLobbyActions.push(systmDrawerItem);
      } else if (isPlayrOnlyType) {
        supportedLobbyActions.push(playrDrawerItem);
      } else {
        if (systmContentId) {
          supportedLobbyActions.push(systmDrawerItem);
        }
        supportedLobbyActions.push(playrDrawerItem);
      }
      if (isElemntSupportedType) {
        supportedLobbyActions.push(sendToElemntDrawerItem);
      }
      return supportedLobbyActions;
    },
    [handleCloseDrawer, logAnalyticsEvent, startOnElemnt]
  );

  const generateBottomSheet = useCallback(
    (entry: ExtendedPlanGroupLobbyCardType) => {
      const drawerListItems = populateDrawerItems(entry);
      setDrawer({
        open: true,
        title: entry.title,
        children: <MenuList listItems={drawerListItems} />,
        hidePuller: true,
        hideDivider: true,
      });
    },
    [populateDrawerItems, setDrawer]
  );

  const handlePlanGroupClick = useCallback(
    (entry: ExtendedPlanGroupLobbyCardType) => {
      if (noActiveSub) {
        showMembershipDrawer(entry);
      } else if (userNeedsPaceZones(entry)) {
        showMissingPaceZonesDialog();
      } else generateBottomSheet(entry);
    },
    [
      generateBottomSheet,
      noActiveSub,
      showMembershipDrawer,
      showMissingPaceZonesDialog,
      userNeedsPaceZones,
    ]
  );

  return {
    handlePlanGroupClick,
  };
}

export default useWorkoutClickHandler;
