import { ChevronRightIcon, MenuListItemType, MenuListItemVariant } from "@WahooFitness/redesignr";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import Switch from "@mui/material/Switch";
import useWorkoutPageDisplayStrings from "./useWorkoutPageDisplayStrings";
import { useUserContext } from "@WahooFitness/wahoo-offline-mfe";
import { getCruxAppProfile } from "@/services/AppProfileUtil";
import { IconButton } from "@mui/material";
import { CloseOutline, Menu } from "@carbon/icons-react";
import { getWorkoutPagesData } from "@/services/getWorkoutPagesData";
import {
  BoltDisplayConfig,
  BoltDisplayPage,
  crux_bolt_display_category_e,
  crux_bolt_display_page_type_e,
  CruxJsDisplayConfig,
} from "@WahooFitness/crux-js-display-cfg";
import wasmModule from "node_modules/@WahooFitness/crux-js-display-cfg/wasm/CruxBoltDisplayCfgModule.wasm?arraybuffer";
import useAssociatedDevices from "@/hooks/useAssociatedDevices";
import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import { FlaggedFeature } from "@/hooks/types/FlaggedFeature";
import { Profile, WahooWorkoutTypeLocation } from "@WahooFitness/cloud-client-types";
import { hasMinProfile } from "@/services/profileService";

export type WorkoutPage = {
  name: string;
  description: string;
  toggleable: boolean;
  editable: boolean;
  enabled: boolean;
  deleteable: boolean;
  id: number;
  hasSubPage: boolean;
};

const ACE_ONLY_CATEGORIES = [crux_bolt_display_category_e.WIND];

const useWorkoutPages = (elevation?: boolean) => {
  const { profileId } = useParams();
  const {
    getWorkoutProfile,
    saveWorkoutPagesDebounce,
    saveWorkoutPagesImmediately,
    workoutProfilesError,
  } = useWorkoutProfilesContext();

  const { checkIsFeatureEnabled } = useFlaggedFeatures();

  const { workoutPageDescriptions, workoutPageTranslations } = useWorkoutPageDisplayStrings();

  const workoutProfile = useMemo(
    () => (profileId ? getWorkoutProfile(+profileId) : undefined),
    [getWorkoutProfile, profileId]
  );

  const { currentUser, userIsLoading } = useUserContext();

  const checkPageEnabled = useCallback(
    (page: BoltDisplayPage) => {
      if (
        !workoutPageTranslations[page.getType()] &&
        !hasMinProfile(Profile.alpha, currentUser?.app?.profile)
      ) {
        return false;
      }
      switch (page.getType()) {
        case crux_bolt_display_page_type_e.BOLTAPP_MULTISPORT:
          return checkIsFeatureEnabled(FlaggedFeature.ElemntMultisportPage);
        case crux_bolt_display_page_type_e.BOLTAPP_PEDAL_MONITOR:
          return checkIsFeatureEnabled(FlaggedFeature.ElemntPedalsPage);
        case crux_bolt_display_page_type_e.BOLTAPP_SEGMENT:
          return (
            workoutProfile?.workout_type_location_id !== WahooWorkoutTypeLocation.Indoor &&
            checkIsFeatureEnabled(FlaggedFeature.StravaLiveSegments)
          );
        default:
          return true;
      }
    },
    [
      checkIsFeatureEnabled,
      currentUser?.app?.profile,
      workoutPageTranslations,
      workoutProfile?.workout_type_location_id,
    ]
  );

  const flushPendingSave = useCallback(
    () => saveWorkoutPagesDebounce.flush(),
    [saveWorkoutPagesDebounce]
  );

  const { hasAssociatedAce } = useAssociatedDevices();

  useEffect(() => {
    // This ensures that any pending save actions are executed when the component is unmounted
    return () => {
      flushPendingSave();
    };
  }, [flushPendingSave, saveWorkoutPagesDebounce]);

  const [displayConfig, setDisplayConfig] = useState<BoltDisplayConfig | undefined>(undefined);

  const cacheKey = useMemo(() => `displayConfig_${profileId}`, [profileId]);

  const appProfile = useMemo(() => currentUser?.app?.profile, [currentUser]);

  useEffect(() => {
    if (userIsLoading) return;
    (async () => {
      if (!workoutProfile) return;
      const crux = await CruxJsDisplayConfig.get(wasmModule);
      let cloudDisplayConfig = undefined;
      if (workoutProfile.workout_pages_file?.url) {
        const cloudDisplayConfigData = await getWorkoutPagesData(
          workoutProfile.workout_pages_file.url
        );
        cloudDisplayConfig = crux.getDisplayConfigFromBuffer(cloudDisplayConfigData);
      }

      const displayConfig =
        cloudDisplayConfig ||
        crux.getDisplayConfig(
          workoutProfile.workout_type_id?.valueOf() ?? 0,
          getCruxAppProfile(appProfile)
        );
      if (!displayConfig) {
        return;
      }
      setDisplayConfig(displayConfig);
    })();
  }, [appProfile, cacheKey, profileId, userIsLoading, workoutProfile]);

  const [pagesAreLoading, setPagesAreLoading] = useState(true);

  const elevationPage = useMemo(() => {
    if (!displayConfig) return undefined;
    for (let i = 0; i < displayConfig.getPageCount(); i++) {
      const page = displayConfig.getPageAt(i);
      if (page.getType() === crux_bolt_display_page_type_e.BOLTAPP_ELEVATION) {
        return page;
      }
    }
    return undefined;
  }, [displayConfig]);

  const boltDisplayPages = useMemo(() => {
    if (!displayConfig) return [];
    if (elevation) {
      const climbSubPage = elevationPage?.getClimbSubPage();
      setPagesAreLoading(false);
      const pages: BoltDisplayPage[] = [];
      if (elevationPage) {
        pages.push(elevationPage);
      }
      if (climbSubPage) {
        pages.push(climbSubPage);
      }
      return pages;
    }
    const pageCount = displayConfig.getPageCount();
    setPagesAreLoading(false);
    return new Array(pageCount)
      .fill(0)
      .map((_, i) => displayConfig.getPageAt(i))
      .filter((p) => checkPageEnabled(p));
  }, [checkPageEnabled, displayConfig, elevation, elevationPage]);

  const [pages, setPages] = useState<Array<WorkoutPage>>([]);

  useEffect(
    () =>
      setPages(
        boltDisplayPages.map((page) => ({
          name:
            page.getCustomTitle() ||
            workoutPageTranslations[page.getType()] ||
            `${page.getName()} (alpha only)`,
          description: workoutPageDescriptions[page.getType()],
          toggleable: page.canDisable(),
          editable: page.canEdit(),
          enabled: page.isEnabled(),
          deleteable: page.canDelete(),
          id: page.getId(),
          hasSubPage: !!page.getClimbSubPage(),
        }))
      ),
    [boltDisplayPages, workoutPageDescriptions, workoutPageTranslations]
  );

  const savePages = useCallback(
    async (immediately?: boolean, encodedDisplayConfig?: Buffer) => {
      if (workoutProfile && displayConfig) {
        const saveFunction = immediately ? saveWorkoutPagesImmediately : saveWorkoutPagesDebounce;
        await saveFunction(workoutProfile.id, encodedDisplayConfig ?? displayConfig.getEncoded());
      }
    },
    [displayConfig, saveWorkoutPagesDebounce, saveWorkoutPagesImmediately, workoutProfile]
  );

  const togglePage = useCallback(
    (pageIndex: number, newValue: boolean) => {
      const boltDisplayPage = boltDisplayPages[pageIndex];
      boltDisplayPage.setEnabled(newValue);
      const updatePage = boltDisplayPage.updateParentPage();
      displayConfig?.update(updatePage);
      savePages();
      setPages((oldPages) => {
        const page = oldPages[pageIndex];
        const newPages = [...oldPages];
        newPages[pageIndex] = { ...page, enabled: newValue };
        return newPages;
      });
    },
    [boltDisplayPages, displayConfig, savePages]
  );

  const handlePageSwitchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, index: number) =>
      togglePage(index, event.target.checked),
    [togglePage]
  );

  const navigate = useNavigate();

  const addCustomPage = useCallback(
    (pageName: string) => {
      if (!workoutProfile || !displayConfig) return;
      displayConfig.addPage(
        workoutProfile.workout_type_id?.valueOf() ?? 0,
        getCruxAppProfile(appProfile)
      );
      const newPage = displayConfig.getPageAt(displayConfig.getPageCount() - 1);
      newPage.setCustomTitle(pageName);
      const newDisplayConfig = displayConfig.update(newPage);
      setDisplayConfig(newDisplayConfig);
      savePages(true, newDisplayConfig.getEncoded());
      navigate(`/workout-profiles/${profileId}/workout-pages/${newPage.getId()}`);
    },
    [appProfile, displayConfig, navigate, profileId, savePages, workoutProfile]
  );

  const resetPagesToDefault = useCallback(() => {
    if (!workoutProfile || !displayConfig) return;
    const newDisplayConfig = displayConfig.resetToDefault(
      workoutProfile.workout_type_id?.valueOf() ?? 0,
      getCruxAppProfile(appProfile)
    );
    setDisplayConfig(newDisplayConfig);
    savePages(true, newDisplayConfig.getEncoded());
  }, [appProfile, displayConfig, savePages, workoutProfile]);

  const [editing, setEditing] = useState(false);

  const toggleEditing = useCallback(() => setEditing((oldEditing) => !oldEditing), []);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [pageToDelete, setPageToDelete] = useState<WorkoutPage | undefined>(undefined);

  const deletePage = useCallback(() => {
    if (!workoutProfile || !displayConfig || !pageToDelete) return;
    displayConfig.deletePageWithId(pageToDelete.id);
    savePages(true);
  }, [displayConfig, pageToDelete, savePages, workoutProfile]);

  const onDeleteClick = useCallback((page: WorkoutPage) => {
    setPageToDelete(page);
    setShowDeleteDialog(true);
  }, []);

  const onCloseDeleteDialog = useCallback(() => {
    setPageToDelete(undefined);
    setShowDeleteDialog(false);
  }, []);

  const goToWorkoutPageEditor = useCallback(
    (pageId: number) => {
      navigate(`/workout-profiles/${profileId}/workout-pages/${pageId}`);
    },
    [navigate, profileId]
  );

  const stopEventPropagation = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  const handleDeleteClick = useCallback(
    (event: MouseEvent, page: WorkoutPage) => {
      event.stopPropagation();
      onDeleteClick(page);
    },
    [onDeleteClick]
  );

  const movePage = useCallback(
    (startIndex: number, newIndex: number) => {
      setPages((oldPages) => {
        const [page] = oldPages.splice(startIndex, 1);
        return [...oldPages.slice(0, newIndex), page, ...oldPages.slice(newIndex)];
      });
      displayConfig?.movePage(startIndex, newIndex);
      savePages();
    },
    [displayConfig, savePages]
  );

  const getPageAction = useCallback(
    (page: WorkoutPage) => {
      if (page.hasSubPage && !elevation) {
        return () => navigate(`/workout-profiles/${profileId}/workout-pages/elevation`);
      }
      if (elevation && !page.hasSubPage) {
        return () => navigate(`/workout-profiles/${profileId}/workout-pages/elevation/edit`);
      }
      return () => goToWorkoutPageEditor(page.id);
    },
    [elevation, goToWorkoutPageEditor, navigate, profileId]
  );

  const pageMenuLists = useMemo(
    () =>
      pages.map(
        (page, index) =>
          ({
            key: page.id.toString(),
            pageListItems: [
              {
                content: page.name,
                secondaryContent: elevation && page.hasSubPage ? undefined : page.description,
                variant: page.editable ? MenuListItemVariant.Action : MenuListItemVariant.NoAction,
                icon: editing && page.deleteable && (
                  <IconButton
                    size="small"
                    color="error"
                    onClick={(event) => handleDeleteClick(event, page)}
                  >
                    <CloseOutline size="24px" />
                  </IconButton>
                ),
                actionComponent: (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    {editing ? (
                      <Menu size="24px" />
                    ) : (
                      <>
                        {page.toggleable && !elevation && (
                          <Switch
                            color="info"
                            checked={page.enabled}
                            onClick={stopEventPropagation}
                            onChange={(event) => handlePageSwitchChange(event, index)}
                          />
                        )}
                        {page.editable ? (
                          <ChevronRightIcon sx={{ color: "text.secondary" }} />
                        ) : (
                          <Box height="24px" width="24px" />
                        )}
                      </>
                    )}
                  </Box>
                ),
                action: page.editable && getPageAction(page),
              },
            ],
          }) as { key: string; pageListItems: MenuListItemType[] }
      ),
    [
      editing,
      elevation,
      getPageAction,
      handleDeleteClick,
      handlePageSwitchChange,
      pages,
      stopEventPropagation,
    ]
  );

  const getPage = useCallback(
    (pageId: number) => {
      return boltDisplayPages.find((page) => page.getId() === pageId);
    },
    [boltDisplayPages]
  );

  const getCategoriesForPageType = useCallback(
    (pageType?: number) => {
      if (
        pageType === undefined ||
        workoutProfile?.workout_type_id === undefined ||
        !displayConfig
      ) {
        return [];
      }
      return displayConfig
        .getCategories(workoutProfile.workout_type_id, pageType, getCruxAppProfile(appProfile))
        .filter((category) => !ACE_ONLY_CATEGORIES.includes(category.getId()) || hasAssociatedAce);
    },
    [appProfile, displayConfig, hasAssociatedAce, workoutProfile]
  );

  const maxPageNameLength = 25;

  return {
    pagesAreLoading,
    pagesError: workoutProfilesError,
    pageMenuLists,
    togglePage,
    savePages,
    flushPendingSave,
    getPage,
    getCategoriesForPageType,
    displayConfig,
    setDisplayConfig,
    addCustomPage,
    resetPagesToDefault,
    editing,
    toggleEditing,
    showDeleteDialog,
    onCloseDeleteDialog,
    deletePage,
    movePage,
    elevationPage,
    maxPageNameLength,
  };
};

export default useWorkoutPages;
