import { ZoneSet } from "@/components/Zones/useZones";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";

interface ZoneSelectProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  zoneChoice: number | undefined;
  zoneSets: Record<number, ZoneSet>;
}

const ZoneSelect = ({ handleChange, zoneChoice, zoneSets }: ZoneSelectProps) => {
  return (
    <FormControl>
      <RadioGroup value={zoneChoice} name="radio-buttons-group" onChange={handleChange}>
        {Object.values(zoneSets).map((zoneSet) => {
          return (
            <FormControlLabel
              key={zoneSet.id}
              value={zoneSet.id}
              control={<Radio />}
              label={<Typography variant="ui-md-medium">{zoneSet.name}</Typography>}
              sx={{ mt: 1, ml: 0.5 }}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default ZoneSelect;
