import { Box, Grid, InputAdornment, TextField } from "@mui/material";
import { FocusEventHandler } from "react";
import { Trans, t } from "@lingui/macro";
import { ImperialMetricDisplayPreference } from "@WahooFitness/cloud-client-types";
import { useHeight } from "@/hooks/useHeight";

type Props = {
  value: number;
  unitPreference: ImperialMetricDisplayPreference;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const HeightInput = ({ value, unitPreference, onBlur }: Props) => {
  const {
    feet,
    inches,
    centimeters,
    handleFeetBlur,
    handleInchesBlur,
    handleFeetChange,
    handleInchesChange,
    feetValid,
    inchesValid,
    handleCentimetersChange,
    handleCentimetersBlur,
    centimetersValid,
  } = useHeight({
    handleSaveToCloud: onBlur,
    userHeight: value,
  });

  return (
    <Box display="flex" flexDirection="column">
      {unitPreference === ImperialMetricDisplayPreference.metric ? (
        <TextField
          label={t`Height`}
          value={centimeters}
          variant="outlined"
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Trans>cm</Trans>
              </InputAdornment>
            ),
          }}
          inputProps={{ inputMode: "decimal", "data-testid": "metricHeightInput" }}
          onBlur={handleCentimetersBlur}
          onChange={handleCentimetersChange}
          error={!centimetersValid}
          helperText={centimetersValid ? undefined : t`Enter a value between 60 and 242`}
        />
      ) : (
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <TextField
              label={t`Height`}
              value={feet}
              variant="outlined"
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Trans>ft</Trans>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                "data-testid": "imperialFeetInput",
              }}
              onBlur={handleFeetBlur}
              onChange={handleFeetChange}
              error={!feetValid}
              helperText={feetValid ? undefined : t`Enter a value between 2 and 7`}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={inches}
              variant="outlined"
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Trans>in</Trans>
                  </InputAdornment>
                ),
              }}
              inputProps={{ inputMode: "decimal", "data-testid": "imperialInchesInput" }}
              onBlur={handleInchesBlur}
              onChange={handleInchesChange}
              error={!inchesValid}
              helperText={inchesValid ? undefined : t`Enter a value less than 12`}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default HeightInput;
