import { useConfigContext, useDrawerContext } from "@WahooFitness/wahoo-offline-mfe";
import { SlidingDrawer } from "@WahooFitness/redesignr";

const GlobalDrawer: React.FC = () => {
  const {
    drawer: {
      open = false,
      title = "",
      children,
      hidePuller,
      hideDivider,
      hideClose,
      elevation,
      maxHeight,
      logDismissEvent,
      footer,
      parentDrawerRef,
      zIndex,
      fullWidth,
      isPersistent,
      globalBottomPadding = "",
      leftAction,
      rightAction,
      hideHeader,
    },
    handleClose,
    handleOpen,
  } = useDrawerContext();
  const { platform } = useConfigContext();

  return (
    <SlidingDrawer
      platform={platform}
      hidePuller={hidePuller}
      handleClose={handleClose}
      handleOpen={handleOpen}
      open={open}
      title={title}
      hideDivider={hideDivider}
      hideClose={hideClose}
      elevation={elevation}
      maxHeight={maxHeight}
      logDismissEvent={logDismissEvent}
      globalBottomPadding={globalBottomPadding}
      footer={footer}
      parentDrawerRef={parentDrawerRef}
      zIndex={zIndex}
      fullWidth={fullWidth}
      isPersistent={isPersistent}
      leftAction={leftAction}
      rightAction={rightAction}
      hideHeader={hideHeader}
    >
      {children}
    </SlidingDrawer>
  );
};

export default GlobalDrawer;
