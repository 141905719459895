import { ImperialMetricDisplayPreference } from "@WahooFitness/cloud-client-types";
import { truncateToTwoDecimalPlaces } from "@/services/truncateNumbers";
import { useState, useEffect, FocusEventHandler, useCallback, ChangeEventHandler } from "react";

type Props = {
  userHeight: number | null;
  setUserHeight?: (height: string) => void;
  userHeightUnit?: ImperialMetricDisplayPreference | null | undefined;
  setUserHeightUnit?: (unit: ImperialMetricDisplayPreference) => void;
  handleSaveToCloud?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
};
export const useHeight = ({
  setUserHeight,
  userHeight,
  userHeightUnit,
  setUserHeightUnit,
  handleSaveToCloud,
}: Props) => {
  const [feet, setFeet] = useState<string>("");
  const [inches, setInches] = useState<string>("");
  const [centimeters, setCentimeters] = useState<string>("");

  const [feetValid, setFeetValid] = useState<boolean>(true);
  const [inchesValid, setInchesValid] = useState<boolean>(true);
  const [centimetersValid, setCentimetersValid] = useState<boolean>(true);

  const handleOnBlur: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback(
    (event) => {
      if (handleSaveToCloud) {
        handleSaveToCloud(event);
      } else {
        const newValue =
          userHeightUnit === ImperialMetricDisplayPreference.metric
            ? +event.target.value / 100
            : +event.target.value / 39.37;
        setUserHeight && setUserHeight(String(newValue));
      }
    },
    [handleSaveToCloud, setUserHeight, userHeightUnit]
  );

  useEffect(() => {
    if (userHeight === null || userHeight === 0) {
      return;
    }
    setFeet(Math.floor(userHeight / 12).toString());
    setInches(truncateToTwoDecimalPlaces(userHeight % 12));
    setCentimeters(truncateToTwoDecimalPlaces(userHeight));
  }, [userHeight]);

  const handleFeetBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      if (!feetValid && userHeight) {
        setFeet(Math.floor(userHeight / 12).toString());
        setFeetValid(true);
        return;
      }

      const newValue = Math.round(+event.target.value);
      if (newValue !== +event.target.value) {
        setFeet(newValue.toString());
      }

      handleOnBlur({
        ...event,
        target: { ...event.target, value: (newValue * 12 + +inches).toString() },
      });
    },
    [feetValid, inches, handleOnBlur, userHeight]
  );

  const handleInchesBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      if (!inchesValid && userHeight) {
        setInches(truncateToTwoDecimalPlaces(userHeight % 12));
        setInchesValid(true);
        return;
      }

      const newValue = truncateToTwoDecimalPlaces(event.target.value);
      if (newValue !== event.target.value && userHeight !== null) {
        setInches(newValue.toString());
      }
      handleOnBlur({
        ...event,
        target: { ...event.target, value: (+feet * 12 + +newValue).toString() },
      });
    },
    [feet, inchesValid, handleOnBlur, userHeight]
  );

  const handleCentimetersBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    useCallback(
      (event) => {
        if (!centimetersValid && userHeight) {
          setCentimeters(truncateToTwoDecimalPlaces(userHeight));
          setCentimetersValid(true);
          return;
        }

        const newValue = truncateToTwoDecimalPlaces(event.target.value);
        if (newValue !== event.target.value && userHeight !== null) {
          setCentimeters(newValue);
        }
        handleOnBlur({
          ...event,
          target: { ...event.target, value: newValue },
        });
      },
      [centimetersValid, handleOnBlur, userHeight]
    );

  const handleFeetChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      setFeetValid(
        !Number.isNaN(+event.target.value) && +event.target.value >= 2 && +event.target.value <= 7
      );

      setFeet(event.target.value);
    },
    []
  );

  const handleInchesChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    useCallback((event) => {
      setInchesValid(
        event.target.value !== "" &&
          !Number.isNaN(+event.target.value) &&
          +event.target.value >= 0 &&
          +event.target.value < 12
      );

      setInches(event.target.value);
    }, []);

  const handleCentimetersChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    useCallback((event) => {
      setCentimetersValid(
        !Number.isNaN(+event.target.value) &&
          +event.target.value >= 60 &&
          +event.target.value <= 242
      );

      setCentimeters(event.target.value);
    }, []);

  const handleUnitChange = (
    _event: React.MouseEvent<HTMLElement>,
    value: "metric" | "imperial"
  ) => {
    if (setUserHeightUnit) {
      setUserHeightUnit(
        value === "imperial"
          ? ImperialMetricDisplayPreference.imperial
          : ImperialMetricDisplayPreference.metric
      );
    }
  };

  return {
    feet,
    inches,
    centimeters,
    handleFeetBlur,
    handleInchesBlur,
    handleCentimetersBlur,
    handleFeetChange,
    handleInchesChange,
    handleCentimetersChange,
    handleUnitChange,
    centimetersValid,
    feetValid,
    inchesValid,
  };
};
