import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import { useEffect, useMemo } from "react";
import { Trans } from "@lingui/macro";
import { UserType } from "@WahooFitness/cloud-client-types";
import CustomStepper from "./CustomStepper";
import useAthleteOnboarding from "./useAthleteOnboarding";

export interface StepContent {
  stepId: number;
  stepName: string;
  headline: string;
  subheader: string;
  component: JSX.Element;
  skippable?: boolean;
  onContinue?: () => void;
  disabled?: boolean;
}

const AthleteOnboarding = ({ user }: { user: UserType }) => {
  const { activeStep, steps, handleNext, handleBack, handleSkip } = useAthleteOnboarding(user);
  const { setNavHeader } = useHeaderContext();

  const SkipButton = useMemo(
    () => (
      <Button variant="text" onClick={handleSkip}>
        <Trans>Skip</Trans>
      </Button>
    ),
    [handleSkip]
  );

  const headerProps = useMemo(
    () => ({
      title: "",
      backAction: handleBack,
      disableBackButton: activeStep === 0,
      isTransparent: true,
      headerAction: steps[activeStep].skippable ? SkipButton : undefined,
    }),
    [SkipButton, activeStep, handleBack, steps]
  );

  useEffect(() => {
    setNavHeader(headerProps);
  }, [headerProps, setNavHeader]);

  return (
    <>
      <Box display="flex" position="absolute" left={0} pl={2}>
        <CustomStepper steps={steps} activeStep={activeStep} />
      </Box>
      <Box display="flex" flexDirection="column" width="100%" height="100%">
        <Box display="flex" flexDirection="column" px={2} py={1.5} rowGap={1.5}>
          <Typography variant="ui-4xl-bold">{steps[activeStep].headline}</Typography>
          <Typography variant="prose-base" color="text.secondary">
            {steps[activeStep].subheader}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" pt={10} width="100%" px={2}>
          {steps[activeStep].component}
        </Box>
        <Box p={3} width="100%" mt="auto" maxWidth="sm">
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={
              activeStep === steps.length - 1 ? () => alert("route splash page") : handleNext
            }
            disabled={steps[activeStep].disabled}
          >
            <Trans>Continue</Trans>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AthleteOnboarding;
