import { Box, InputAdornment, TextField } from "@mui/material";
import {
  ChangeEventHandler,
  FocusEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { t } from "@lingui/macro";
import { truncateToTwoDecimalPlaces } from "@/services/truncateNumbers";
import { ImperialMetricDisplayPreference } from "@WahooFitness/cloud-client-types";

type Props = {
  value: number;
  unitPreference: ImperialMetricDisplayPreference;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const WeightInput = ({ value, unitPreference, onBlur }: Props) => {
  const [currentValue, setCurrentValue] = useState<string>("");
  const [weightValid, setWeightValid] = useState<boolean>(true);

  useEffect(() => {
    setCurrentValue(truncateToTwoDecimalPlaces(value));
  }, [value]);

  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      if (!weightValid) {
        setCurrentValue(truncateToTwoDecimalPlaces(value));
        setWeightValid(true);
        return;
      }

      const newValue = truncateToTwoDecimalPlaces(event.target.value);
      if (newValue !== event.target.value) {
        setCurrentValue(newValue);
      }
      onBlur?.({
        ...event,
        target: { ...event.target, value: newValue },
      });
    },
    [onBlur, value, weightValid]
  );

  const errorMessage = useMemo(
    () =>
      unitPreference === ImperialMetricDisplayPreference.metric
        ? t`Enter a value between 0 and 255`
        : t`Enter a value between 0 and 562`,
    [unitPreference]
  );

  const maxWeight = useMemo(
    () => (unitPreference === ImperialMetricDisplayPreference.metric ? 255 : 562),
    [unitPreference]
  );

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      setWeightValid(
        event.target.value !== "" &&
          !Number.isNaN(+event.target.value) &&
          +event.target.value >= 0 &&
          +event.target.value <= maxWeight
      );
      setCurrentValue(event.target.value);
    },
    [maxWeight]
  );

  return (
    <Box display="flex" flexDirection="column">
      <TextField
        label={t`Weight`}
        value={currentValue}
        variant="outlined"
        type="number"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {unitPreference === ImperialMetricDisplayPreference.metric ? t`kg` : t`lbs`}
            </InputAdornment>
          ),
        }}
        inputProps={{ inputMode: "decimal", "data-testid": "weightInput" }}
        onBlur={handleBlur}
        onChange={handleChange}
        error={!weightValid}
        helperText={weightValid ? undefined : errorMessage}
      />
    </Box>
  );
};

export default WeightInput;
