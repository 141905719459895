import { useCallback } from "react";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import {
  AutoLap,
  AutoLapUnit,
  AutoLapUnitType,
  WorkoutProfileResponseType,
} from "@WahooFitness/cloud-client-types";
import { FieldChangeType } from "@WahooFitness/redesignr/Components/WorkoutProfile/useWorkoutProfileSettingsSection";

const useEditWorkoutProfile = (profile?: WorkoutProfileResponseType) => {
  const { updateProfile, updateProfileWithObject } = useWorkoutProfilesContext();

  const handleNameChange = useCallback(
    (newValue: string) => {
      if (profile?.id) {
        updateProfile(profile.id, "name", newValue);
      }
    },
    [updateProfile, profile?.id]
  );

  const handleFieldChange: FieldChangeType = useCallback(
    (field, newValue) => {
      if (profile?.id) {
        updateProfile(profile.id, field, newValue);
      }
    },
    [profile?.id, updateProfile]
  );

  const handleAutoLapChange = useCallback(
    ({
      auto_lap,
      auto_lap_unit,
      auto_lap_unit_type,
      auto_lap_interval,
    }: {
      auto_lap: AutoLap;
      auto_lap_unit: AutoLapUnit;
      auto_lap_unit_type?: AutoLapUnitType;
      auto_lap_interval?: number;
    }) => {
      if (profile?.id) {
        updateProfileWithObject(profile.id, {
          auto_lap,
          auto_lap_unit,
          auto_lap_unit_type,
          auto_lap_interval,
        });
      }
    },
    [profile?.id, updateProfileWithObject]
  );

  return {
    handleNameChange,
    handleFieldChange,
    handleAutoLapChange,
  };
};

export default useEditWorkoutProfile;
