import {
  WahooWorkoutTypeFamily,
  WorkoutProfileResponseType,
} from "@WahooFitness/cloud-client-types";
import { MenuList, MenuListItemType, MenuListItemVariant } from "@WahooFitness/redesignr";
import { t } from "@lingui/macro";
import { useMemo } from "react";
import { useTheme } from "@mui/material";
import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import { WatsonHealthStackedScrolling_1, ChartClusterBar, Mountain } from "@carbon/icons-react";
import FeatureInDev from "@/components/FeatureInDev";
import StravaSvgIcon from "@/components/WorkoutProfiles/StravaLiveSegments/StravaSvgIcon";
import LEDsSvgIcon from "../LEDs/LEDsSvgIcon";
import { ReactRouterLinkWrapper } from "@/components/MenuList/ReactRouterLinkWrapper";
import { useWsmPlugin } from "@/hooks/useWsmPlugin";
import useAssociatedDevices from "@/hooks/useAssociatedDevices";
import { FlaggedFeature } from "@/hooks/types/FlaggedFeature";

const ElementSection = ({ profile }: { profile?: WorkoutProfileResponseType }) => {
  const theme = useTheme();

  const { checkIsFeatureEnabled, featuresAreLoading } = useFlaggedFeatures();

  const { hasAssociatedBolt, hasAssociatedRoam } = useAssociatedDevices();
  const { hasBoltPaired, hasRoamPaired } = useWsmPlugin();

  const ledsEnabled = hasAssociatedBolt || hasAssociatedRoam || hasBoltPaired || hasRoamPaired;

  const sectionItems = useMemo(
    () =>
      [
        {
          id: "workoutPages",
          content: t`Workout pages`,
          variant: MenuListItemVariant.InternalLink,
          linkLocation: `/workout-profiles/${profile?.id}/workout-pages`,
          icon: <WatsonHealthStackedScrolling_1 size="24px" color={theme.palette.text.primary} />,
          linkComponent: ReactRouterLinkWrapper,
        },
        {
          id: "plannedWorkouts",
          content: (
            <FeatureInDev featureId="plannedWorkoutSettings">{t`Planned workouts`}</FeatureInDev>
          ),
          variant: MenuListItemVariant.InternalLink,
          linkLocation: `/workout-profiles/${profile?.id}/planned-workout-settings`,
          icon: <ChartClusterBar size="24px" color={theme.palette.text.primary} />,
          linkComponent: ReactRouterLinkWrapper,
        },
        {
          id: "stravaLiveSegments",
          content: (
            <FeatureInDev featureId="stravaLiveSegmentSettings">{t`Strava Live Segments`}</FeatureInDev>
          ),
          variant: MenuListItemVariant.InternalLink,
          linkLocation: `/workout-profiles/${profile?.id}/strava-live-segment-settings`,
          icon: <StravaSvgIcon width={24} height={24} fill={theme.palette.text.primary} />,
          linkComponent: ReactRouterLinkWrapper,
          hidden: !checkIsFeatureEnabled(FlaggedFeature.StravaLiveSegments),
        },
        {
          id: "summitSegments",
          content: (
            <FeatureInDev featureId="summitSegmentSettings">{t`Summit Segments`}</FeatureInDev>
          ),
          variant: MenuListItemVariant.InternalLink,
          linkLocation: `/workout-profiles/${profile?.id}/summit-segment-settings`,
          icon: <Mountain size="24px" color={theme.palette.text.primary} />,
          linkComponent: ReactRouterLinkWrapper,
        },
        {
          id: "leds",
          content: <FeatureInDev featureId="ledSettings">{t`LEDs`}</FeatureInDev>,
          secondaryContent: t`ELEMNT, BOLT, ROAM, BOLT2, ROAM2 only`,
          variant: MenuListItemVariant.InternalLink,
          linkLocation: `/workout-profiles/${profile?.id}/led-settings`,
          icon: <LEDsSvgIcon width={24} height={24} fill={theme.palette.text.primary} />,
          linkComponent: ReactRouterLinkWrapper,
          hidden: !ledsEnabled,
        },
      ] as MenuListItemType[],
    [checkIsFeatureEnabled, ledsEnabled, profile?.id, theme.palette.text.primary]
  );

  const isCyclingProfile = useMemo(
    () => profile?.workout_type_family_id === WahooWorkoutTypeFamily.Cycling,
    [profile?.workout_type_family_id]
  );

  if (
    featuresAreLoading ||
    !checkIsFeatureEnabled(FlaggedFeature.ElemntConfig) ||
    !isCyclingProfile
  ) {
    return undefined;
  }

  return <MenuList title={t`ELEMNT settings`} listItems={sectionItems} />;
};

export default ElementSection;
