import { useCallback, useMemo, useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Fab,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { t, Trans } from "@lingui/macro";
import { useRoutesContext } from "@/hooks/useRoutesContext";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import useRouteDetails from "./useRouteDetails";
import ElevationSection from "./Elevation/ElevationSection";
import Summary from "./Summary/Summary";
import { useUnitFormatter } from "@/hooks/useUnitFormatter";
import {
  ArrowsHorizontal,
  CheckmarkFilled,
  Edit,
  OverflowMenuHorizontal,
  Share,
  Warning,
  WarningFilled,
} from "@carbon/icons-react";
import { RouteSentStatus } from "@/contexts/RouteSentStatus";
import { useRouteElevationContext } from "@/hooks/useRouteElevationContext";
import MapContainer from "./Map/MapContainer";
import { MapProvider } from "@/contexts/MapContext";
import ShareDrawer from "./ShareDrawer";
import { useConfigContext, useDialogContext } from "@WahooFitness/wahoo-offline-mfe";
import { formatDuration } from "@WahooFitness/unit-convertr-ts";
import useCurrentTime from "@/hooks/useCurrentTime";
import { AppleShareIcon } from "@WahooFitness/redesignr";
import EditDrawer from "./EditDrawer";
import { PLATFORM_ENUM, RouteProviderTypeEnum } from "@WahooFitness/cloud-client-types";
import ErrorBoundary from "@/components/ErrorBoundary";
import useCloudSyncListener from "@/hooks/useCloudSyncListener";
import { useNavigate } from "react-router";
import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import SelectRouteDrawer from "../SelectRouteDrawer";
import { FlaggedFeature } from "@/hooks/types/FlaggedFeature";

const RouteDetails = ({ fromPublicRouteList }: { fromPublicRouteList: boolean }) => {
  const { platform } = useConfigContext();
  const {
    routeDetails,
    routeJson,
    sendRouteToConnectedDevices,
    selectedProviderId,
    selectedRouteSentState,
    isRouteReversed,
    reverseRoute,
    pendingPublicRouteIds,
    pendingRemovedPublicRouteIds,
    stopSharingRoutePublicly,
    routesSyncError,
    routeFetchError,
    routeIsLoading,
    refreshRouteList,
    editRoute,
    openSelectRouteDrawer,
    closeSelectRouteDrawer,
    selectRouteDrawerOpen,
  } = useRoutesContext();
  const { totalAscentCalculated, totalDescentCalculated } = useRouteElevationContext();
  const { setNavHeader } = useHeaderContext();
  const { getProviderIconImgUrl, getLocation } = useRouteDetails();
  const { palette } = useTheme();
  const [openFullScreenMap, setOpenFullScreenMap] = useState(false);
  const handleOpenMapFullScreen = useCallback(() => {
    setOpenFullScreenMap(true);
  }, []);
  const { formatDistance } = useUnitFormatter();

  const [shareDrawerOpen, setShareDrawerOpen] = useState(false);
  const openShareDrawer = useCallback(() => {
    setShareDrawerOpen(true);
  }, []);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuIsOpen = Boolean(menuAnchorEl);

  const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const [editDrawerIsOpen, setEditDrawerIsOpen] = useState(false);

  const handleCloseEditDrawer = useCallback(() => {
    setEditDrawerIsOpen(false);
  }, []);

  const isWahooRoute = useMemo(
    () =>
      [
        RouteProviderTypeEnum.WAHOO,
        RouteProviderTypeEnum.WAHOO_IMPORTED,
        RouteProviderTypeEnum.WAHOO_TMP,
      ].includes(routeDetails?.route_provider_type ?? RouteProviderTypeEnum.UNKNOWN),
    [routeDetails]
  );

  const theme = useTheme();

  const { setDialog, handleClose } = useDialogContext();

  const navigate = useNavigate();

  const showDeleteDialog = useCallback(() => {
    setDialog({
      open: true,
      title: t`Delete route?`,
      body: t`If you delete this route, it cannot be undone.`,
      actions: [
        { text: t`Cancel` },
        {
          text: t`Confirm delete`,
          color: "error",
          action: async () => {
            handleClose();
            routeDetails?.token && (await editRoute(routeDetails.token, { deleted: true }));
            navigate("/routes", { replace: true });
          },
        },
      ],
    });
  }, [editRoute, handleClose, navigate, routeDetails?.token, setDialog]);

  const HeaderActionComponent = useMemo(
    () => (
      <>
        {openFullScreenMap ? (
          <Box display="flex" gap={1}>
            {!fromPublicRouteList && (
              <Fab size="small" color="primary" aria-label="share route" onClick={openShareDrawer}>
                {platform === PLATFORM_ENUM.ios ? (
                  <AppleShareIcon height={24} width={24} />
                ) : (
                  <Share size={24} />
                )}
              </Fab>
            )}
            <Fab size="small" color="primary" aria-label="menu" onClick={openMenu}>
              <OverflowMenuHorizontal size={24} />
            </Fab>
          </Box>
        ) : (
          <Box>
            {!fromPublicRouteList && (
              <IconButton onClick={openShareDrawer} edge="end">
                {platform === PLATFORM_ENUM.ios ? (
                  <AppleShareIcon height={24} width={24} />
                ) : (
                  <Share size={24} />
                )}
              </IconButton>
            )}
            <IconButton onClick={openMenu}>
              <OverflowMenuHorizontal size={24} />
            </IconButton>
          </Box>
        )}
        <Menu anchorEl={menuAnchorEl} open={menuIsOpen} onClose={closeMenu}>
          {isWahooRoute && !fromPublicRouteList && (
            <MenuItem
              onClick={() => {
                closeMenu();
                setEditDrawerIsOpen(true);
              }}
            >
              <ListItemIcon>
                <Box mr={2.5}>
                  <Edit size={24} color={theme.palette.text.primary} />
                </Box>
              </ListItemIcon>
              <ListItemText>
                <Trans>Edit</Trans>
              </ListItemText>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              closeMenu();
              reverseRoute();
            }}
          >
            <ListItemIcon>
              <Box mr={2.5}>
                <ArrowsHorizontal size={24} color={theme.palette.text.primary} />
              </Box>
            </ListItemIcon>
            <ListItemText>{isRouteReversed ? t`Undo reverse` : t`Reverse`}</ListItemText>
          </MenuItem>
          {isWahooRoute && !fromPublicRouteList && (
            <MenuItem
              onClick={() => {
                closeMenu();
                showDeleteDialog();
              }}
            >
              <ListItemIcon color="error">
                <Box mr={2.5}>
                  <Edit size={24} color={theme.palette.error.main} />
                </Box>
              </ListItemIcon>
              <ListItemText>
                <Typography color="error" variant="inherit">
                  <Trans>Delete</Trans>
                </Typography>
              </ListItemText>
            </MenuItem>
          )}
        </Menu>
      </>
    ),
    [
      openFullScreenMap,
      fromPublicRouteList,
      openShareDrawer,
      platform,
      openMenu,
      menuAnchorEl,
      menuIsOpen,
      closeMenu,
      isWahooRoute,
      theme.palette.text.primary,
      theme.palette.error.main,
      isRouteReversed,
      reverseRoute,
      showDeleteDialog,
    ]
  );

  const headerProps = useMemo(() => {
    return {
      title: "",
      headerAction: routeDetails ? HeaderActionComponent : undefined,
      maxTitleWidths: [
        { mediaQuery: theme.breakpoints.down("sm"), maxWidth: "50dvw" },
        { mediaQuery: theme.breakpoints.down("md"), maxWidth: "65dvw" },
        { mediaQuery: theme.breakpoints.down("lg"), maxWidth: "75dvw" },
        { mediaQuery: theme.breakpoints.up("lg"), maxWidth: "80dvw" },
      ],
    };
  }, [HeaderActionComponent, routeDetails, theme.breakpoints]);

  useEffect(() => {
    if (!openFullScreenMap) {
      setNavHeader(headerProps);
    } else {
      setNavHeader({ ...headerProps, isTransparent: true, isFixed: true });
    }
  });

  const routeSentStatus = useMemo(() => {
    if (routeDetails?.provider_id && selectedProviderId === routeDetails?.provider_id) {
      return selectedRouteSentState;
    }
    return RouteSentStatus.UNSENT;
  }, [routeDetails?.provider_id, selectedProviderId, selectedRouteSentState]);

  const sendRoute = useCallback(() => {
    const providerType = routeDetails?.route_provider_type;
    const providerId = routeDetails?.provider_id;
    const fitnessAppId = routeDetails?.fitness_app_id;
    if (providerType !== undefined && providerId && fitnessAppId) {
      sendRouteToConnectedDevices(providerType, providerId, fitnessAppId);
    }
  }, [routeDetails, sendRouteToConnectedDevices]);

  const [startLocationString, setStartLocationString] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchLocation = async () => {
      let lon_deg, lat_deg;

      if (isRouteReversed && routeJson?.records?.length) {
        const firstRecord = routeJson.records[0];
        if (firstRecord?.lat_deg && firstRecord?.lon_deg) {
          lon_deg = firstRecord.lon_deg;
          lat_deg = firstRecord.lat_deg;
        }
      } else if (routeDetails?.start_lng && routeDetails?.start_lat) {
        lon_deg = routeDetails.start_lng;
        lat_deg = routeDetails.start_lat;
      }

      if (lon_deg && lat_deg) {
        const startLocation = await getLocation(lon_deg, lat_deg);
        setStartLocationString(startLocation);
      }
    };

    fetchLocation();
  }, [
    getLocation,
    isRouteReversed,
    routeDetails?.start_lat,
    routeDetails?.start_lng,
    routeJson?.records,
  ]);

  // TODO: TSS-1198: Remove code below once feature flag is obsolete
  const routeStatusIcon = useMemo(() => {
    switch (routeSentStatus) {
      case RouteSentStatus.UNSENT:
        return undefined;
      case RouteSentStatus.PENDING:
        return <CircularProgress size={24} color={"info"} />;
      case RouteSentStatus.SUCCESS:
        return <CheckmarkFilled size={24} color={palette.success.main} />;
      case RouteSentStatus.FAILED:
        return <WarningFilled size={24} color={palette.warning.main} />;
    }
  }, [palette.success.main, palette.warning.main, routeSentStatus]);

  const buttonText = useMemo(() => {
    switch (routeSentStatus) {
      case RouteSentStatus.UNSENT:
        return isRouteReversed ? t`Select route (reversed)` : t`Select route`;
      case RouteSentStatus.PENDING:
        return t`Sending to ELEMNT...`;
      case RouteSentStatus.SUCCESS:
        return t`Success!`;
      case RouteSentStatus.FAILED:
        return t`Failed to send to ELEMNT`;
    }
  }, [isRouteReversed, routeSentStatus]);

  const { routeAscent } = useMemo(() => {
    if (isRouteReversed) {
      return {
        routeAscent: routeDetails?.descent ?? totalDescentCalculated,
        routeDescent: routeDetails?.ascent ?? totalAscentCalculated,
      };
    }

    return {
      routeAscent: routeDetails?.ascent ?? totalAscentCalculated,
      routeDescent: routeDetails?.descent ?? totalDescentCalculated,
    };
  }, [
    isRouteReversed,
    routeDetails?.ascent,
    routeDetails?.descent,
    totalAscentCalculated,
    totalDescentCalculated,
  ]);

  const handleCloseShareDrawer = useCallback(() => {
    setShareDrawerOpen(false);
  }, []);

  const routeIsPendingPublic = useMemo(
    () => pendingPublicRouteIds?.includes(routeDetails?.id || -1),
    [pendingPublicRouteIds, routeDetails?.id]
  );

  const routeIsPendingPublicRemoval = useMemo(
    () => pendingRemovedPublicRouteIds?.includes(routeDetails?.id || -1),
    [pendingRemovedPublicRouteIds, routeDetails?.id]
  );

  const currentTime = useCurrentTime(1000);

  const currentTimeMillis = useMemo(() => currentTime.getTime(), [currentTime]);
  const expiresAt = useMemo(
    () => (routeDetails?.expires_at ? new Date(routeDetails?.expires_at) : undefined),
    [routeDetails?.expires_at]
  );
  const expiresAtMillis = useMemo(() => expiresAt?.getTime() || 0, [expiresAt]);

  const routeIsPublic = useMemo(() => {
    return (
      !routeIsPendingPublicRemoval &&
      (routeIsPendingPublic || (expiresAt && expiresAt > currentTime))
    );
  }, [currentTime, expiresAt, routeIsPendingPublic, routeIsPendingPublicRemoval]);

  const handleStopSharingRoutePublicly = useCallback(() => {
    routeDetails && stopSharingRoutePublicly(routeDetails);
    handleClose();
  }, [handleClose, routeDetails, stopSharingRoutePublicly]);

  const openStopSharingDialog = useCallback(() => {
    setDialog({
      open: true,
      title: t`Stop sharing route`,
      body: t`Are you sure you want to stop sharing this route? This route will no longer be public.`,
      actions: [
        { text: t`Cancel`, action: handleClose },
        {
          text: t`Stop sharing`,
          action: handleStopSharingRoutePublicly,
          color: "error",
        },
      ],
    });
  }, [handleClose, handleStopSharingRoutePublicly, setDialog]);

  const { addListener, removeListener } = useCloudSyncListener();

  const [resyncingRoutes, setResyncingRoutes] = useState(false);

  const reloadRoutes = useCallback(() => {
    setResyncingRoutes(true);
  }, []);

  useEffect(() => {
    if (!resyncingRoutes) return;
    const callback = () => {
      setResyncingRoutes(false);
    };
    addListener(callback);
    refreshRouteList();
    return () => removeListener(callback);
  }, [addListener, refreshRouteList, removeListener, resyncingRoutes]);

  const { checkIsFeatureEnabled } = useFlaggedFeatures();

  if (routeJson?.records?.length === 0) {
    return (
      <Stack justifyContent="center" alignItems="center" marginTop={5}>
        <Stack gap={1} alignItems="center" marginLeft={2} marginRight={2}>
          <Warning height={48} width={48} color={theme.palette.error.main} />
          <Typography textAlign="center" variant="prose-lg-bold">
            <Trans>Oops! We weren&apos;t able to load your route.</Trans>
          </Typography>
        </Stack>
      </Stack>
    );
  }
  return (
    <ErrorBoundary
      error={routesSyncError || routeFetchError || (!routeIsLoading && !routeDetails)}
      isLoading={resyncingRoutes || routeIsLoading}
      onReload={reloadRoutes}
      fallback={
        <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
          <Typography textAlign="center" variant="ui-lg-medium">
            <Trans>Route not found</Trans>
          </Typography>
        </Box>
      }
    >
      <MapProvider>
        <Box display="flex" flexDirection="column" height="100%">
          {routeIsPublic && !fromPublicRouteList && (
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              onClick={routeIsPendingPublic ? undefined : openStopSharingDialog}
              component={Paper}
              elevation={4}
              square
              px={2}
              py={1}
            >
              {routeIsPendingPublic ? (
                <>
                  <Typography variant="ui-sm-medium">
                    <Trans>Sharing public route...</Trans>
                  </Typography>
                  <CircularProgress variant="indeterminate" size={16} />
                </>
              ) : (
                <>
                  <Typography variant="ui-sm-medium" color="warning.main">
                    <Trans>Stop sharing public route</Trans>
                  </Typography>
                  <Typography variant="ui-sm-medium">
                    {routeDetails?.expires_at &&
                      formatDuration((expiresAtMillis - currentTimeMillis) / 1000, "pretty")}
                  </Typography>
                </>
              )}
            </Box>
          )}
          <Box display="flex" flexDirection="column" rowGap={3} overflow="auto">
            <Box width="100%">
              <MapContainer
                mapFullScreenOpen={openFullScreenMap}
                handleOpenMapFullScreen={handleOpenMapFullScreen}
                routeJsonRecords={routeJson?.records ?? []}
                handleClose={() => setOpenFullScreenMap(false)}
                handleRouteReversalToggle={reverseRoute}
                routeIsReversed={isRouteReversed}
              />
            </Box>
            {!openFullScreenMap && (
              <Box display="flex" flexDirection="column" p={2} rowGap={4}>
                <Summary
                  name={routeDetails?.name ?? ""}
                  distance={routeDetails?.distance}
                  ascent={routeAscent}
                  workoutTypeFamily={routeDetails?.workout_type_family_id}
                  providerIconImgUrl={getProviderIconImgUrl(
                    routeDetails?.fitness_app_id,
                    routeDetails?.route_provider_type
                  )}
                  createdDate={routeDetails?.created_at ?? ""}
                  location={startLocationString}
                  proximity={
                    routeDetails?.distanceToStart
                      ? formatDistance(routeDetails.distanceToStart)
                      : undefined
                  }
                />
                <ElevationSection
                  ascent={routeAscent}
                  // descent={routeDescent} //postponed from MVP since it doesn't come from cloud and the
                  // calculation can diverge from the ascent that comes from cloud. Looks weird for loop routes.
                  handleOpenFullScreenMap={handleOpenMapFullScreen}
                />
                {/* <SurfaceTypeSection /> //postponed from MVP */}
              </Box>
            )}
          </Box>
          {!openFullScreenMap && (
            <Box p={3} width="100%" mt="auto" maxWidth="sm">
              {/* // TODO: TSS-1198: Remove once feature flag is obsolete */}
              {checkIsFeatureEnabled(FlaggedFeature.RouteToPlayr) ? (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  fullWidth
                  onClick={(event) =>
                    routeDetails?.token && openSelectRouteDrawer(event, routeDetails.token)
                  }
                >
                  <Trans>Select route</Trans>
                </Button>
              ) : (
                <Button variant="contained" size="large" fullWidth onClick={sendRoute}>
                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                    {routeStatusIcon}
                    <Typography variant="prose-md-medium">{buttonText}</Typography>
                  </Stack>
                </Button>
              )}
            </Box>
          )}
          {routeDetails && (
            <>
              <ShareDrawer
                open={shareDrawerOpen}
                route={routeDetails}
                onClose={handleCloseShareDrawer}
                zIndex={2000}
              />
              <EditDrawer
                open={editDrawerIsOpen}
                onClose={handleCloseEditDrawer}
                route={routeDetails}
              />
              <SelectRouteDrawer
                open={selectRouteDrawerOpen}
                handleClose={closeSelectRouteDrawer}
              />
            </>
          )}
        </Box>
      </MapProvider>
    </ErrorBoundary>
  );
};

export default RouteDetails;
