import { t } from "@lingui/macro";
import { useMemo } from "react";
import {
  crux_bolt_display_pedaling_monitor_graph_type_e,
  crux_defn_type_e,
} from "@WahooFitness/crux-js-display-cfg";

const useWorkoutPageFieldStrings = () => {
  const workoutFieldTranslations = useMemo(
    () =>
      ({
        [crux_defn_type_e.NONE]: "NONE",
        [crux_defn_type_e.TORQUE_BIKE]: t`Torque`,
        [crux_defn_type_e.TORQUE_BIKE_AVG]: t`Torque Avg (workout)`,
        [crux_defn_type_e.TORQUE_BIKE_LAP_AVG]: t`Torque Avg (lap)`,
        [crux_defn_type_e.TORQUE_BIKE_LAST_LAP_AVG]: t`Torque Avg (last lap)`,
        [crux_defn_type_e.POWER_KICKR]: t`KICKR Power`,
        [crux_defn_type_e.SPEED]: t`Current Speed`,
        [crux_defn_type_e.SPEED_CMP_LAP_AVG]: t`Current Speed (vs lap avg)`,
        [crux_defn_type_e.SPEED_CMP_AVG]: t`Current Speed (vs workout avg)`,
        [crux_defn_type_e.SPEED_LAP_AVG]: t`Avg Speed (lap)`,
        [crux_defn_type_e.SPEED_AVG]: t`Avg Speed (workout)`,
        [crux_defn_type_e.SPEED_LAST_LAP_AVG]: t`Avg Speed (last lap)`,
        [crux_defn_type_e.SPEED_BEST_AVG]: t`Avg Speed (fastest lap)`,
        [crux_defn_type_e.SPEED_MAX]: t`Max Speed (workout)`,
        [crux_defn_type_e.SPEED_LAP_MAX]: t`Max Speed (lap)`,
        [crux_defn_type_e.SPEED_MIN]: "SPEED_MIN",
        [crux_defn_type_e.DISTANCE]: t`Distance (workout)`,
        [crux_defn_type_e.DISTANCE_LAP]: t`Distance (lap)`,
        [crux_defn_type_e.DISTANCE_LAST_LAP]: t`Distance (last lap)`,
        [crux_defn_type_e.DISTANCE_DAY]: "DISTANCE_DAY",
        [crux_defn_type_e.ROUTE_DISTANCE_REMAINING]: t`Distance Remaining (route)`,
        [crux_defn_type_e.ROUTE_DISTANCE_NEXT_COURSE_POINT]: t`Distance Next Cue`,
        [crux_defn_type_e.ROUTE_ETA]: "ROUTE_ETA",
        [crux_defn_type_e.ROUTE_TIME_REMAINING]: "ROUTE_TIME_REMAINING",
        [crux_defn_type_e.HEADING_GPS]: t`GPS Heading`,
        [crux_defn_type_e.HOR_ACC_GPS]: t`GPS Accuracy`,
        [crux_defn_type_e.LAT]: t`Latitude`,
        [crux_defn_type_e.LON]: t`Longitude`,
        [crux_defn_type_e.TIME]: t`Time of Day`,
        [crux_defn_type_e.DURATION_TOTAL]: t`Workout Time (total)`,
        [crux_defn_type_e.DURATION_ACTIVE]: t`Active Time (workout)`,
        [crux_defn_type_e.DURATION_PAUSED]: t`Paused Time (workout)`,
        [crux_defn_type_e.DURATION_ACTIVE_LAP]: t`Current Lap Active Time`,
        [crux_defn_type_e.DURATION_ACTIVE_LAST]: t`Last Lap Active Time`,
        [crux_defn_type_e.DURATION_ACTIVE_BEST]: t`Shortest Lap Active Time`,
        [crux_defn_type_e.SEGMENT_DURATION_ACTIVE]: t`Elapsed Segment Time`,
        [crux_defn_type_e.SEGMENT_AHEAD_BEHIND_TIME]: t`Ahead/Behind Time`,
        [crux_defn_type_e.SEGMENT_DURATION_TARGET]: t`Goal Duration`,
        [crux_defn_type_e.SEGMENT_DISTANCE_REMAINING]: t`Distance Remaining`,
        [crux_defn_type_e.SEGMENT_DURATION_ESTIMATED]: t`Estimated Duration`,
        [crux_defn_type_e.CLIMB_DURATION_ACTIVE]: t`Elapsed Summit Segments Live Time`,
        [crux_defn_type_e.CLIMB_ASCENT_REMAINING]: t`Ascent Remaining (summit)`,
        [crux_defn_type_e.CLIMB_GRADE_REMAINING]: t`Avg Grade Remaining (summit)`,
        [crux_defn_type_e.CLIMB_DISTANCE_REMAINING]: t`Distance Remaining (summit)`,
        [crux_defn_type_e.CLIMB_DURATION_REMAINING_ESTIMATED]: t`Estimated Time Remaining (summit)`,
        [crux_defn_type_e.CLIMB_ASCENT_TOTAL]: t`Total Ascent (summit)`,
        [crux_defn_type_e.CLIMB_GRADE_AVG]: t`Avg Grade (summit)`,
        [crux_defn_type_e.CLIMB_ELEVATION_MAX]: t`Max Elevation (summit)`,
        [crux_defn_type_e.ROUTE_ASCENT_REMAINING]: t`Ascent Remaining (route)`,
        [crux_defn_type_e.ASCENT]: t`Total Ascent (workout)`,
        [crux_defn_type_e.DESCENT]: t`Total Descent (workout)`,
        [crux_defn_type_e.ASCENT_LAP]: t`Total Ascent (lap)`,
        [crux_defn_type_e.DESCENT_LAP]: t`Total Descent (lap)`,
        [crux_defn_type_e.ASCENT_LAST_LAP]: t`Total Ascent (last lap)`,
        [crux_defn_type_e.DESCENT_LAST_LAP]: t`Total Descent (last lap)`,
        [crux_defn_type_e.GRADE]: t`Grade`,
        [crux_defn_type_e.GRADE_AVG]: t`Avg Grade (workout)`,
        [crux_defn_type_e.GRADE_LAP_AVG]: t`Avg Grade (lap)`,
        [crux_defn_type_e.GRADE_LAST_LAP_AVG]: t`Avg Grade (last lap)`,
        [crux_defn_type_e.ELEVATION]: t`Current Elevation`,
        [crux_defn_type_e.ELEVATION_MAX]: t`Max Elevation (workout)`,
        [crux_defn_type_e.ELEVATION_MIN]: t`Min Elevation (workout)`,
        [crux_defn_type_e.VERT_SPEED]: t`VAM (m/hr)`,
        [crux_defn_type_e.GAP]: t`Grade Adjusted Pace`,
        [crux_defn_type_e.GAP_AVG]: t`Grade Adjusted Pace (workout)`,
        [crux_defn_type_e.GAP_LAP_AVG]: t`Grade Adjusted Pace (lap)`,
        [crux_defn_type_e.GAP_LAST_LAP_AVG]: t`Grade Adjusted Pace (last lap)`,
        [crux_defn_type_e.TEMPERATURE]: t`Temperature`,
        [crux_defn_type_e.TEMPERATURE_MAX]: t`Max Temperature (workout)`,
        [crux_defn_type_e.TEMPERATURE_MIN]: t`Min Temperature (workout)`,
        [crux_defn_type_e.TEMPERATURE_AVG]: t`Avg Temperature (workout)`,
        [crux_defn_type_e.CADENCE]: t`Cadence`,
        [crux_defn_type_e.CADENCE_AVG]: t`Avg Cadence (workout)`,
        [crux_defn_type_e.CADENCE_LAP_AVG]: t`Avg Cadence (lap)`,
        [crux_defn_type_e.CADENCE_LAST_LAP_AVG]: t`Avg Cadence (last lap)`,
        [crux_defn_type_e.CADENCE_MAX]: t`Max Cadence (workout)`,
        [crux_defn_type_e.CADENCE_LAP_MAX]: t`Max Cadence (lap)`,
        [crux_defn_type_e.CADENCE_MIN]: "CADENCE_MIN",
        [crux_defn_type_e.STRIDES_DAY]: "STRIDES_DAY",
        [crux_defn_type_e.HEARTRATE]: t`Heart Rate`,
        [crux_defn_type_e.HEARTRATE_AVG]: t`Avg Heart Rate (workout)`,
        [crux_defn_type_e.HEARTRATE_LAP_AVG]: t`Avg Heart Rate (lap)`,
        [crux_defn_type_e.HEARTRATE_LAST_LAP_AVG]: t`Avg Heart Rate (last lap)`,
        [crux_defn_type_e.HEARTRATE_MAX]: t`Max Heart Rate (workout)`,
        [crux_defn_type_e.HEARTRATE_LAP_MAX]: t`Max Heart Rate (lap)`,
        [crux_defn_type_e.HEARTRATE_LAST_LAP_MAX]: t`Max Heart Rate (last lap)`,
        [crux_defn_type_e.HEARTRATE_PERC]: t`Heart Rate % Max`,
        [crux_defn_type_e.HEARTRATE_ZONE]: t`Heart Rate Zone`,
        [crux_defn_type_e.HEARTRATE_TIZ_VISUAL]: t`Heart Rate Zone Graph`,
        [crux_defn_type_e.TIZ_HR1]: t`HR Time In Zone 1`,
        [crux_defn_type_e.TIZ_HR2]: t`HR Time In Zone 2`,
        [crux_defn_type_e.TIZ_HR3]: t`HR Time In Zone 3`,
        [crux_defn_type_e.TIZ_HR4]: t`HR Time In Zone 4`,
        [crux_defn_type_e.TIZ_HR5]: t`HR Time In Zone 5`,
        [crux_defn_type_e.CALORIES]: t`Calories (workout)`,
        [crux_defn_type_e.CALORIES_WORKOUT]: t`Calories (overall)`,
        [crux_defn_type_e.CALORIES_DAY]: "CALORIES_DAY",
        [crux_defn_type_e.CALORIE_RATE]: t`Calorie Burn Rate`,
        [crux_defn_type_e.MECHANICAL_WORK_BIKE_KJ]: t`Kilojoules (workout)`,
        [crux_defn_type_e.POWER_BIKE]: t`Power`,
        [crux_defn_type_e.POWER_CMP_LAP_AVG]: t`Power (vs lap avg)`,
        [crux_defn_type_e.POWER_CMP_AVG]: t`Power (vs workout avg)`,
        [crux_defn_type_e.POWER_TO_WEIGHT]: t`Power To Weight (Watts/Kg)`,
        [crux_defn_type_e.POWER_TO_WEIGHT_AVG]: t`Power To Weight (workout Watts/Kg)`,
        [crux_defn_type_e.POWER_TO_WEIGHT_LAP_AVG]: t`Power To Weight (lap Watts/Kg)`,
        [crux_defn_type_e.POWER_TO_WEIGHT_LAST_LAP_AVG]: t`Power To Weight (last lap Watts/Kg)`,
        [crux_defn_type_e.POWER_AVG]: t`Avg Power (workout)`,
        [crux_defn_type_e.POWER_LAP_AVG]: t`Avg Power (lap)`,
        [crux_defn_type_e.POWER_LAST_LAP_AVG]: t`Avg Power (last lap)`,
        [crux_defn_type_e.POWER_MAX]: t`Max Power (workout)`,
        [crux_defn_type_e.POWER_LAP_MAX]: t`Max Power (lap)`,
        [crux_defn_type_e.POWER_BIKE_TSS]: t`Training Stress Score®`,
        [crux_defn_type_e.POWER_BIKE_VI]: t`Variability Index`,
        [crux_defn_type_e.POWER_BIKE_NP]: t`Normalized Power®`,
        [crux_defn_type_e.POWER_BIKE_NP_LAP]: t`Normalized Power® (lap)`,
        [crux_defn_type_e.POWER_BIKE_NP_LAST_LAP]: t`Normalized Power® (last lap)`,
        [crux_defn_type_e.POWER_BIKE_IF]: t`Intensity Factor®`,
        [crux_defn_type_e.POWER_BIKE_LR_BALANCE]: t`Left/Right Balance`,
        [crux_defn_type_e.POWER_BIKE_LR_BALANCE_AVG]: t`Left/Right Balance Avg`,
        [crux_defn_type_e.POWER_BIKE_LR_BALANCE_LAP_AVG]: t`Left/Right Balance Lap`,
        [crux_defn_type_e.POWER_BIKE_LR_BALANCE_LAST_LAP_AVG]: t`Left/Right Balance Last Lap`,
        [crux_defn_type_e.POWER_BIKE_LR_BALANCE_03S]: t`Left/Right Balance Rolling Avg (3 sec)`,
        [crux_defn_type_e.POWER_BIKE_LR_BALANCE_05S]: t`Left/Right Balance Rolling Avg (5 sec)`,
        [crux_defn_type_e.POWER_BIKE_LR_BALANCE_20S]: t`Left/Right Balance Rolling Avg (20 sec)`,
        [crux_defn_type_e.POWER_BIKE_LR_BALANCE_30S]: t`Left/Right Balance Rolling Avg (30 sec)`,
        [crux_defn_type_e.POWER_BIKE_PEDAL_SMOOTHNESS]: t`Pedal Smoothness`,
        [crux_defn_type_e.POWER_BIKE_PEDAL_SMOOTHNESS_AVG]: t`Avg Pedal Smoothness (workout)`,
        [crux_defn_type_e.POWER_BIKE_PEDAL_SMOOTHNESS_LAP_AVG]: t`Avg Pedal Smoothness (lap)`,
        [crux_defn_type_e.POWER_BIKE_PEDAL_SMOOTHNESS_LAST_LAP_AVG]: t`Avg Pedal Smoothness (last lap)`,
        [crux_defn_type_e.POWER_BIKE_TORQUE_EFFECTIVENESS]: t`Torque Effectiveness`,
        [crux_defn_type_e.POWER_BIKE_TORQUE_EFFECTIVENESS_AVG]: t`Torque Effectiveness (workout)`,
        [crux_defn_type_e.POWER_BIKE_TORQUE_EFFECTIVENESS_LAP_AVG]: t`Torque Effectiveness (lap)`,
        [crux_defn_type_e.POWER_BIKE_TORQUE_EFFECTIVENESS_LAST_LAP_AVG]: t`Torque Effectiveness (last lap)`,
        [crux_defn_type_e.POWER_BIKE_PERCENT_FTP]: t`Power/FTP %`,
        [crux_defn_type_e.POWER_BIKE_PERCENT_FTP_AVG]: t`Power/FTP % (workout)`,
        [crux_defn_type_e.POWER_BIKE_PERCENT_FTP_LAP]: t`Power/FTP % (lap)`,
        [crux_defn_type_e.POWER_BIKE_PERCENT_FTP_LAST_LAP]: t`Power/FTP % (last lap)`,
        [crux_defn_type_e.POWER_BIKE_03S_PERCENT_FTP]: t`Power (3 sec)/FTP %`,
        [crux_defn_type_e.POWER_BIKE_05S_PERCENT_FTP]: t`Power (5 sec)/FTP %`,
        [crux_defn_type_e.POWER_BIKE_20S_PERCENT_FTP]: t`Power (20 sec)/FTP %`,
        [crux_defn_type_e.POWER_BIKE_30S_PERCENT_FTP]: t`Power (30 sec)/FTP %`,
        [crux_defn_type_e.POWER_RUN]: t`Power`,
        [crux_defn_type_e.POWER_RUN_PERCENT_CP]: t`Power CP %`,
        [crux_defn_type_e.POWER_RUN_PERCENT_CP_AVG]: t`Power CP % (workout)`,
        [crux_defn_type_e.POWER_RUN_PERCENT_CP_LAP]: t`Power CP % (lap)`,
        [crux_defn_type_e.POWER_RUN_PERCENT_CP_LAST_LAP]: t`Power CP % (last lap)`,
        [crux_defn_type_e.POWER_RUN_03S_PERCENT_CP]: t`Power (3 sec) CP %`,
        [crux_defn_type_e.POWER_RUN_05S_PERCENT_CP]: t`Power (5 sec) CP %`,
        [crux_defn_type_e.POWER_RUN_20S_PERCENT_CP]: t`Power (20 sec) CP %`,
        [crux_defn_type_e.POWER_RUN_30S_PERCENT_CP]: t`Power (30 sec) CP %`,
        [crux_defn_type_e.POWER_RUN_IF]: t`Intensity Factor®`,
        [crux_defn_type_e.POWER_RUN_VI]: t`Variability Index`,
        [crux_defn_type_e.POWER_RUN_RSS]: t`Running Stress Score`,
        [crux_defn_type_e.LEG_SPRING_STIFFNESS]: t`Leg Spring Stiffness`,
        [crux_defn_type_e.RUNNING_EFFECTIVENESS]: t`Running Effectiveness`,
        [crux_defn_type_e.RUNNING_EFFECTIVENESS_AVG]: t`Running Effectiveness (workout)`,
        [crux_defn_type_e.POWER_ZONE]: t`Power Zone`,
        [crux_defn_type_e.POWER_TIZ_VISUAL]: t`Power Zone Graph`,
        [crux_defn_type_e.TIZ_PWR1]: t`PWR Time In Zone 1`,
        [crux_defn_type_e.TIZ_PWR2]: t`PWR Time In Zone 2`,
        [crux_defn_type_e.TIZ_PWR3]: t`PWR Time In Zone 3`,
        [crux_defn_type_e.TIZ_PWR4]: t`PWR Time In Zone 4`,
        [crux_defn_type_e.TIZ_PWR5]: t`PWR Time In Zone 5`,
        [crux_defn_type_e.TIZ_PWR6]: t`PWR Time In Zone 6`,
        [crux_defn_type_e.TIZ_PWR7]: t`PWR Time In Zone 7`,
        [crux_defn_type_e.TIZ_PWR8]: t`PWR Time In Zone 8`,
        [crux_defn_type_e.TORQUE_BIKE_03S]: t`Torque Avg (3 sec)`,
        [crux_defn_type_e.TORQUE_BIKE_05S]: t`Torque Avg (5 sec)`,
        [crux_defn_type_e.TORQUE_BIKE_20S]: t`Torque Avg (20 sec)`,
        [crux_defn_type_e.TORQUE_BIKE_30S]: t`Torque Avg (30 sec)`,
        [crux_defn_type_e.POWER_03S]: t`Avg Power (3 sec)`,
        [crux_defn_type_e.POWER_05S]: t`Avg Power (5 sec)`,
        [crux_defn_type_e.POWER_20S]: t`Avg Power (20 sec)`,
        [crux_defn_type_e.POWER_30S]: t`Avg Power (30 sec)`,
        [crux_defn_type_e.POWER_60S]: t`Avg Power (1 min)`,
        [crux_defn_type_e.POWER_05M]: t`Avg Power (5 min)`,
        [crux_defn_type_e.POWER_20M]: t`Avg Power (20 min)`,
        [crux_defn_type_e.POWER_30M]: t`Avg Power (30 min)`,
        [crux_defn_type_e.POWER_60M]: t`Avg Power (1 hr)`,
        [crux_defn_type_e.POWER_03S_MAX]: t`Max Power (3 sec)`,
        [crux_defn_type_e.POWER_05S_MAX]: t`Max Power (5 sec)`,
        [crux_defn_type_e.POWER_20S_MAX]: t`Max Power (20 sec)`,
        [crux_defn_type_e.POWER_30S_MAX]: t`Max Power (30 sec)`,
        [crux_defn_type_e.POWER_60S_MAX]: t`Max Power (1 min)`,
        [crux_defn_type_e.POWER_05M_MAX]: t`Max Power (5 min)`,
        [crux_defn_type_e.POWER_20M_MAX]: t`Max Power (20 min)`,
        [crux_defn_type_e.POWER_30M_MAX]: t`Max Power (30 min)`,
        [crux_defn_type_e.POWER_60M_MAX]: t`Max Power (1 hr)`,
        [crux_defn_type_e.KICKR_ERG]: t`Target ERG Power`,
        [crux_defn_type_e.KICKR_LVL]: t`KICKR Level`,
        [crux_defn_type_e.KICKR_RES]: t`Brake %`,
        [crux_defn_type_e.KICKR_CADENCE]: t`Target RPM`,
        [crux_defn_type_e.KICKR_HEARTRATE]: t`Target BPM`,
        [crux_defn_type_e.KICKR_TORQUE]: t`Target NM`,
        [crux_defn_type_e.TARGET_POWER]: t`Target Power`,
        [crux_defn_type_e.TARGET_SPEED]: t`Target Speed`,
        [crux_defn_type_e.BATTERY_LOCAL]: t`Battery`,
        [crux_defn_type_e.BATTERY_LOCAL_COLOR]: t`Battery (color)`,
        [crux_defn_type_e.BATTERY_REMOTE]: t`Phone Battery`,
        [crux_defn_type_e.GEAR_SELECTION_NUMBER]: t`Current Gear`,
        [crux_defn_type_e.GEAR_SELECTION_NUMBER_VISUAL]: t`Current Gear (visual)`,
        [crux_defn_type_e.GEAR_SELECTION_NUMBER_WITH_TOTAL]: t`Current Gear (with total)`,
        [crux_defn_type_e.GEAR_SELECTION_NUMBER_FRONT]: t`Current Front Gear`,
        [crux_defn_type_e.GEAR_SELECTION_NUMBER_FRONT_WITH_TOTAL]: t`Current Front Gear (with total)`,
        [crux_defn_type_e.GEAR_SELECTION_NUMBER_REAR]: t`Current Rear Gear`,
        [crux_defn_type_e.GEAR_SELECTION_NUMBER_REAR_WITH_TOTAL]: t`Current Rear Gear (with total)`,
        [crux_defn_type_e.GEAR_SELECTION_RATIO_NUMBER_OF_TEETH]: t`Current Gear Ratio`,
        [crux_defn_type_e.GEAR_SELECTION_BATTERY_SHIFTERS]: t`Shifter Battery`,
        [crux_defn_type_e.GEAR_SELECTION_BATTERY_DERAILLEURS]: t`Derailleur Battery`,
        [crux_defn_type_e.MUSCLE_OXYGEN_TOTAL_CONCENTRATION]: t`Total Hemoglobin`,
        [crux_defn_type_e.MUSCLE_OXYGEN_TOTAL_CONCENTRATION_AVG]: t`Avg Total Hemoglobin (workout)`,
        [crux_defn_type_e.MUSCLE_OXYGEN_TOTAL_CONCENTRATION_LAP_AVG]: t`Avg Total Hemoglobin (lap)`,
        [crux_defn_type_e.MUSCLE_OXYGEN_TOTAL_CONCENTRATION_MIN]: t`Min Total Hemoglobin (workout)`,
        [crux_defn_type_e.MUSCLE_OXYGEN_TOTAL_CONCENTRATION_LAP_MIN]: t`Min Total Hemoglobin (lap)`,
        [crux_defn_type_e.MUSCLE_OXYGEN_TOTAL_CONCENTRATION_MAX]: t`Max Total Hemoglobin (workout)`,
        [crux_defn_type_e.MUSCLE_OXYGEN_TOTAL_CONCENTRATION_LAP_MAX]: t`Max Total Hemoglobin (lap)`,
        [crux_defn_type_e.MUSCLE_OXYGEN_SATURATED_PERCENT]: t`Saturated Hemoglobin`,
        [crux_defn_type_e.MUSCLE_OXYGEN_SATURATED_PERCENT_AVG]: t`Avg Saturated Hemoglobin (workout)`,
        [crux_defn_type_e.MUSCLE_OXYGEN_SATURATED_PERCENT_LAP_AVG]: t`Avg Saturated Hemoglobin (lap)`,
        [crux_defn_type_e.MUSCLE_OXYGEN_SATURATED_PERCENT_MIN]: t`Min Saturated Hemoglobin (workout)`,
        [crux_defn_type_e.MUSCLE_OXYGEN_SATURATED_PERCENT_LAP_MIN]: t`Min Saturated Hemoglobin (lap)`,
        [crux_defn_type_e.MUSCLE_OXYGEN_SATURATED_PERCENT_MAX]: t`Max Saturated Hemoglobin (workout)`,
        [crux_defn_type_e.MUSCLE_OXYGEN_SATURATED_PERCENT_LAP_MAX]: t`Max Saturated Hemoglobin (lap)`,
        [crux_defn_type_e.LAP_NUMBER]: t`Lap Number`,
        [crux_defn_type_e.START_TIME]: t`Workout Start Time`,
        [crux_defn_type_e.STRAVA_SUFFER_SCORE]: "STRAVA_SUFFER_SCORE",
        [crux_defn_type_e.WORKOUT_TYPE]: t`Workout Type`,
        [crux_defn_type_e.SWIM_STROKE_RATE]: t`Avg Stroke Rate`,
        [crux_defn_type_e.SWIM_STROKES]: t`Strokes`,
        [crux_defn_type_e.SWIM_STROKES_INTERVAL]: t`Strokes (interval)`,
        [crux_defn_type_e.SWIM_STROKES_LAST_INTERVAL]: t`Strokes (last interval)`,
        [crux_defn_type_e.SWIM_POOL_LENGTHS]: t`Lengths`,
        [crux_defn_type_e.SWIM_POOL_LENGTHS_INTERVAL]: t`Lengths (interval)`,
        [crux_defn_type_e.SWIM_POOL_LENGTHS_LAST_INTERVAL]: t`Lengths (last interval)`,
        [crux_defn_type_e.TYRE_PRESSURE]: t`Tyre Pressure`,
        [crux_defn_type_e.STRAVA_RELATIVE_EFFORT]: t`Strava Live Relative Effort`,
        [crux_defn_type_e.PLAN_TARGET_POWER]: t`Target Power`,
        [crux_defn_type_e.PLAN_TARGET_CADENCE]: t`Target Cadence`,
        [crux_defn_type_e.PLAN_TARGET_HEARTRATE]: t`Target Heartrate`,
        [crux_defn_type_e.PLAN_TARGET_TORQUE]: t`Target Torque`,
        [crux_defn_type_e.PLAN_INTERVAL_REMAINING]: t`Remaining Time/Distance - Interval`,
        [crux_defn_type_e.PLAN_WORKOUT_REMAINING]: t`Remaining Time/Distance - Workout`,
        [crux_defn_type_e.PLAN_INTERVAL_COUNT]: t`Interval Count`,
        [crux_defn_type_e.PLAN_INTERVAL_LENGTH]: t`Interval Length`,
        [crux_defn_type_e.PIONEER_PM_EFFICIENCY]: t`Pedaling LR Efficiency`,
        [crux_defn_type_e.PIONEER_PM_EFFICIENCY_AVG]: t`Avg Pedaling LR Efficiency (workout)`,
        [crux_defn_type_e.PIONEER_PM_EFFICIENCY_LAP_AVG]: t`Avg Pedaling LR Efficiency (lap)`,
        [crux_defn_type_e.GLUCOSE]: t`GLUCOSE`,
        [crux_defn_type_e.SWOLF]: t`SWOLF`,
        [crux_defn_type_e.SWOLF_INTERVAL]: t`SWOLF (interval)`,
        [crux_defn_type_e.SWOLF_LAST_INTERVAL]: t`SWOLF (last interval)`,
        [crux_defn_type_e.SWOLF_LENGTH]: t`SWOLF (length)`,
        [crux_defn_type_e.SWOLF_LAST_LENGTH]: t`SWOLF (last length)`,
        [crux_defn_type_e.LENGTH_IS_ACTIVE]: t`LENGTH IS ACTIVE`,
        [crux_defn_type_e.DURATION_ACTIVE_LENGTH]: t`Duration Active (length)`,
        [crux_defn_type_e.DURATION_ACTIVE_LAST_LENGTH]: t`Duration Active (last length)`,
        [crux_defn_type_e.SWIM_STROKES_LENGTH]: t`Strokes (length)`,
        [crux_defn_type_e.SWIM_STROKES_LAST_LENGTH]: t`Strokes (last length)`,
        [crux_defn_type_e.SWIM_STROKE_RATE_AVG]: t`Avg Stroke Rate`,
        [crux_defn_type_e.SWIM_STROKE_RATE_INTERVAL_AVG]: t`Avg Stroke Rate (interval)`,
        [crux_defn_type_e.SWIM_STROKE_RATE_LAST_INTERVAL_AVG]: t`Avg Stroke Rate (last interval)`,
        [crux_defn_type_e.SWIM_STROKE_RATE_MAX]: t`Max Stroke Rate`,
        [crux_defn_type_e.SWIM_STROKE_RATE_INTERVAL_MAX]: t`Max Stroke Rate (interval)`,
        [crux_defn_type_e.DURATION_SWIM_ACTIVE]: t`Active Time (workout)`,
        [crux_defn_type_e.DURATION_SWIM_ACTIVE_INTERVAL]: t`Interval Duration`,
        [crux_defn_type_e.DURATION_SWIM_IDLE]: t`Duration Idle`,
        [crux_defn_type_e.DURATION_SWIM_IDLE_INTERVAL]: t`Duration Idle (interval)`,
        [crux_defn_type_e.SWIM_INTERVAL_COUNT_IN_SESSION]: t`Intervals`,
        [crux_defn_type_e.SWIM_ACTIVE_LENGTH_COUNT_IN_SESSION]: t`Length Count In Session`,
        [crux_defn_type_e.DURATION_INTERVAL]: t`Duration (interval)`,
        [crux_defn_type_e.DISTANCE_INTERVAL]: t`Distance (interval)`,
        [crux_defn_type_e.INTERVAL_NUMBER]: t`Interval Number`,
        [crux_defn_type_e.HEARTRATE_INTERVAL_AVG]: t`Avg Heart Rate (set)`,
        [crux_defn_type_e.HEARTRATE_LAST_INTERVAL_AVG]: t`Avg Heart Rate (last set)`,
        [crux_defn_type_e.HEARTRATE_INTERVAL_MAX]: t`Max Heart Rate (set)`,
        [crux_defn_type_e.HEARTRATE_LAST_INTERVAL_MAX]: t`Max Heart Rate (last set)`,
        [crux_defn_type_e.SWIM_STROKES_PER_LENGTH]: t`Strokes (length)`,
        [crux_defn_type_e.SWIM_STROKES_PER_LENGTH_MIN]: t`Min Strokes Per Length`,
        [crux_defn_type_e.SWIM_STROKES_PER_LENGTH_MAX]: t`Max Strokes Per Length`,
        [crux_defn_type_e.SWIM_STROKES_PER_LENGTH_AVG]: t`Avg Strokes Per Length`,
        [crux_defn_type_e.SWIM_STROKES_PER_LENGTH_INTERVAL_MIN]: t`Min Strokes Per Length (interval)`,
        [crux_defn_type_e.SWIM_STROKES_PER_LENGTH_INTERVAL_MAX]: t`Max Strokes Per Length (interval)`,
        [crux_defn_type_e.SWIM_STROKES_PER_LENGTH_INTERVAL_AVG]: t`Avg Strokes Per Length (interval)`,
        [crux_defn_type_e.SWIM_PACE]: t`Pace`,
        [crux_defn_type_e.SWIM_PACE_MIN]: t`Min Pace`,
        [crux_defn_type_e.SWIM_PACE_MAX]: t`Max Pace`,
        [crux_defn_type_e.SWIM_PACE_AVG]: t`Avg Pace`,
        [crux_defn_type_e.SWIM_PACE_INTERVAL_MIN]: t`Min Pace (interval)`,
        [crux_defn_type_e.SWIM_PACE_INTERVAL_MAX]: t`Max Pace (interval)`,
        [crux_defn_type_e.SWIM_PACE_INTERVAL_AVG]: t`Avg Pace (interval)`,
        [crux_defn_type_e.DURATION_SWIM_ACTIVE_INTERVAL_LATEST]: t`Interval Duration`,
        [crux_defn_type_e.SWIM_REPEAT_ON]: t`Repeat On`,
        [crux_defn_type_e.DISTANCE_SWIM]: t`Distance`,
        [crux_defn_type_e.LEV_TRAVEL_ASSIST_LEVEL]: t`LEV Travel Mode`,
        [crux_defn_type_e.LEV_REMAINING_RANGE]: t`LEV Range`,
        [crux_defn_type_e.LEV_BATTERY_PERC]: t`LEV Battery`,
        [crux_defn_type_e.DURATION_ACTIVE_WORKOUT]: t`Active Time (overall)`,
        [crux_defn_type_e.DURATION_TOTAL_WORKOUT]: t`Total Time (overall)`,
        [crux_defn_type_e.DURATION_ACTIVE_LAST_LEG]: t`Last Leg Time (eg Swim Time)`,
        [crux_defn_type_e.DURATION_ACTIVE_LATEST_TRANSITION]: t`Latest Transition Time`,
        [crux_defn_type_e.DURATION_ACTIVE_PREVIOUS_TRANSITION]: t`Previous Transition Time`,
        [crux_defn_type_e.BATTERY_WATCH]: t`Watch Battery`,
        [crux_defn_type_e.MA_GCT_MS]: t`Ground Contact Time`,
        [crux_defn_type_e.MA_GCT_MS_AVG]: t`Ground Contact Time (avg)`,
        [crux_defn_type_e.MA_GCT_MS_LAP_AVG]: t`Ground Contact Time (lap avg)`,
        [crux_defn_type_e.MA_GCT_MS_LAST_LAP_AVG]: t`Ground Contact Time (last lap avg)`,
        [crux_defn_type_e.MA_GCT_MS_MAX]: t`Ground Contact Time (max)`,
        [crux_defn_type_e.MA_GCT_MS_MIN]: t`Ground Contact Time (min)`,
        [crux_defn_type_e.MA_VERT_OCS_CM]: t`Vertical Oscillation`,
        [crux_defn_type_e.MA_VERT_OCS_CM_AVG]: t`Vertical Oscillation (avg)`,
        [crux_defn_type_e.MA_VERT_OCS_CM_LAP_AVG]: t`Vertical Oscillation (lap avg)`,
        [crux_defn_type_e.MA_VERT_OCS_CM_LAST_LAP_AVG]: t`Vertical Oscillation (last lap avg)`,
        [crux_defn_type_e.MA_VERT_OCS_CM_MAX]: t`Vertical Oscillation (max)`,
        [crux_defn_type_e.MA_VERT_OCS_CM_MIN]: t`Vertical Oscillation (min)`,
        [crux_defn_type_e.HEARTRATE_ZONE_COLOR]: t`Heart Rate (zone color)`,
        [crux_defn_type_e.POWER_03S_ZONE_COLOR]: t`Avg Power (3 sec - zone color)`,
        [crux_defn_type_e.POWER_05S_ZONE_COLOR]: t`Avg Power (5 sec - zone color)`,
        [crux_defn_type_e.POWER_20S_ZONE_COLOR]: t`Avg Power (20 sec - zone color)`,
        [crux_defn_type_e.POWER_30S_ZONE_COLOR]: t`Avg Power (30 sec - zone color)`,
        [crux_defn_type_e.POWER_60S_ZONE_COLOR]: t`Avg Power (1 min - zone color)`,
        [crux_defn_type_e.POWER_05M_ZONE_COLOR]: t`Avg Power (5 min - zone color)`,
        [crux_defn_type_e.POWER_20M_ZONE_COLOR]: t`Avg Power (20 min - zone color)`,
        [crux_defn_type_e.POWER_30M_ZONE_COLOR]: t`Avg Power (30 min - zone color)`,
        [crux_defn_type_e.POWER_60M_ZONE_COLOR]: t`Avg Power (1 hr - zone color)`,
        [crux_defn_type_e.TREADMILL_INCLINE_UP_DOWN]: "TREADMILL_INCLINE_UP_DOWN",
        [crux_defn_type_e.TREADMILL_INCLINE_LEFT_RIGHT]: "TREADMILL_INCLINE_LEFT_RIGHT",
        [crux_defn_type_e.TREADMILL_ESTOP_MODE_ENABLED]: "TREADMILL_ESTOP_MODE_ENABLED",
        [crux_defn_type_e.TREADMILL_FREE_RUN_MODE_ENABLED]: "TREADMILL_FREE_RUN_MODE_ENABLED",
        [crux_defn_type_e.POWER_AIR]: t`Air Power`,
        [crux_defn_type_e.POWER_FORM]: t`Form Power`,
        [crux_defn_type_e.SWIM_STROKE_TYPE]: t`Stroke Type`,
        [crux_defn_type_e.GRADE_SLOPE_BG]: t`Grade (slope background)`,
        [crux_defn_type_e.CORE_BODY_TEMP]: t`Body Temp`,
        [crux_defn_type_e.AVG_CORE_BODY_TEMP]: t`Avg Body Temp (workout)`,
        [crux_defn_type_e.MAX_CORE_BODY_TEMP]: t`Max Body Temp (workout)`,
        [crux_defn_type_e.MIN_CORE_BODY_TEMP]: t`Min Body Temp (workout)`,
        [crux_defn_type_e.CORE_BODY_TEMP_LAP_AVG]: t`Avg Body Temp (lap)`,
        [crux_defn_type_e.CORE_BODY_TEMP_LAP_MAX]: t`Max Body Temp (lap)`,
        [crux_defn_type_e.CORE_BODY_TEMP_LAP_MIN]: t`Min Body Temp (lap)`,
        [crux_defn_type_e.TREADMILL_TERRAIN_MODE_ENABLED]: "TREADMILL_TERRAIN_MODE_ENABLED",
        [crux_defn_type_e.WIND_SPEED]: t`Wind Speed`,
        [crux_defn_type_e.WIND_ADJUSTED_SPEED]: t`Adjusted Wind Speed`,
        [crux_defn_type_e.WIND_ADJUSTED_SPEED_AVG]: t`Adjusted Wind Speed (avg)`,
        [crux_defn_type_e.WIND_GRADE_POWER_RATIO]: t`Wind Grade Power Ratio`,
        [crux_defn_type_e.TIZ_HEADWIND]: t`Time in Headwind`,
        [crux_defn_type_e.TIZ_HEADWIND_MAX]: t`Time in Headwind (max)`,
        [crux_defn_type_e.TIZ_TAILWIND]: t`Time in Tailwind`,
        [crux_defn_type_e.TIZ_TAILWIND_MAX]: t`Time in Tailwind (max)`,
        [crux_defn_type_e.WIND_AIR_SPEED]: t`Airspeed`,
        [crux_defn_type_e.WIND_AIR_SPEED_AVG]: t`Airspeed (avg)`,
        [crux_defn_type_e.WIND_ZONE]: t`Wind Zone`,
        [crux_defn_type_e.TIZ_WIND_0]: t`Wind Time In Zone 0`,
        [crux_defn_type_e.TIZ_WIND_1]: t`Wind Time In Zone 1`,
        [crux_defn_type_e.TIZ_WIND_2]: t`Wind Time In Zone 2`,
        [crux_defn_type_e.TIZ_WIND_3]: t`Wind Time In Zone 3`,
        [crux_defn_type_e.TIZ_WIND_4]: t`Wind Time In Zone 4`,
        [crux_defn_type_e.PLAN_AUTO_TARGET]: t`Plan Auto Target`,
        [crux_defn_type_e.NEXT_VALUE]: "NEXT_VALUE",
      }) as Record<number, string>,
    []
  );

  const pedalMonitorGraphTypeFieldTranslations = useMemo(
    () => ({
      [crux_bolt_display_pedaling_monitor_graph_type_e.FORCE_VECTORS_BALANCE_LR]: t`Force Vectors & Balance L/R`,
      [crux_bolt_display_pedaling_monitor_graph_type_e.FORCE_VECTORS_EFFICIENCY_LR]: t`Force Vectors & Efficiency L/R`,
      [crux_bolt_display_pedaling_monitor_graph_type_e.FORCE_VECTORS_POWER_LR]: t`Force Vectors & Power L/R`,
      [crux_bolt_display_pedaling_monitor_graph_type_e.TORQUE_CHART_BALANCE_LR]: t`Torque Chart & Balance L/R`,
      [crux_bolt_display_pedaling_monitor_graph_type_e.TORQUE_CHART_EFFICIENCY_LR]: t`Torque Chart & Efficiency L/R`,
      [crux_bolt_display_pedaling_monitor_graph_type_e.TORQUE_CHART_POWER_LR]: t`Torque Chart & Power L/R`,
    }),
    []
  );
  return { workoutFieldTranslations, pedalMonitorGraphTypeFieldTranslations };
};

export default useWorkoutPageFieldStrings;
