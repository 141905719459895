import { t } from "@lingui/macro";
import BestKnownTimesPage from "./BestKnownTimes/BestKnownTimesPage";
import { PaceZoneAlgorithmLookupsResponseType } from "@WahooFitness/cloud-client-types";
import FullScreenDialog from "@/components/FullScreenDialog";

interface SetUpPacesDrawerProps {
  open: boolean;
  handleClose: () => void;
  yourPaceZones: PaceZoneAlgorithmLookupsResponseType | undefined;
  setYourPaceZones: (paceZones: PaceZoneAlgorithmLookupsResponseType) => void;
  showResultPage: boolean;
  setShowResultPage: any;
  handleBackFromResult: () => void;
  handleSave: () => void;
}

const SetUpPacesDrawer = ({
  open,
  handleClose,
  yourPaceZones,
  setYourPaceZones,
  showResultPage,
  setShowResultPage,
  handleBackFromResult,
  handleSave,
}: SetUpPacesDrawerProps) => {
  return (
    <FullScreenDialog
      open={open}
      handleClose={handleClose}
      headerProps={{
        title: t`Set up paces`,
        backAction: showResultPage ? handleBackFromResult : handleClose,
        backText: showResultPage ? t`Back` : t`Cancel`,
      }}
      slideDirection="up"
    >
      <BestKnownTimesPage
        paceResultsAvailable={showResultPage}
        setPaceResultsAvailable={setShowResultPage}
        yourPaceZones={yourPaceZones}
        setYourPaceZones={setYourPaceZones}
        handleSave={handleSave}
      />
    </FullScreenDialog>
  );
};

export default SetUpPacesDrawer;
