// TODO: The scheduling call for workouts only accepts workout_type_id, not
// workout_type_family_id. Currently we only get workout_type_family_id from the lobby entry
// we need to inform cloud, either they return a workout_type_id in lobby or accept

import { WahooWorkoutType, WahooWorkoutTypeFamily } from "@WahooFitness/cloud-client-types";

export const convertWorkoutFamilyToWorkoutType = (input: number) => {
  const mapping: { [key: number]: number } = {
    [WahooWorkoutTypeFamily.Cycling]: WahooWorkoutType.Biking,
    [WahooWorkoutTypeFamily.Running]: WahooWorkoutType.Running,
    [WahooWorkoutTypeFamily.Swimming]: WahooWorkoutType.Swimming,
    [WahooWorkoutTypeFamily.Gym]: WahooWorkoutType.FE,
    [WahooWorkoutTypeFamily.Walking]: WahooWorkoutType.Walking,
    [WahooWorkoutTypeFamily.Snow]: WahooWorkoutType.Snowboarding,
    [WahooWorkoutTypeFamily.Skating]: WahooWorkoutType.Skating,
    [WahooWorkoutTypeFamily.Water]: WahooWorkoutType.Rowing,
    [WahooWorkoutTypeFamily.Yoga]: WahooWorkoutType.Yoga,
    [WahooWorkoutTypeFamily.Strength]: WahooWorkoutType.Strength,
    [WahooWorkoutTypeFamily.Other]: WahooWorkoutType.Other,
  };
  return mapping[input];
};
