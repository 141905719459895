import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { t } from "@lingui/macro";
import { Box, Paper, Slider, Typography, useTheme } from "@mui/material";
import Chip from "./StyledChip";
import { useUnitFormatter } from "@/hooks/useUnitFormatter";
import { FilterParams } from "./FilterParams";
import { SlidingDrawer } from "@WahooFitness/redesignr";
import { useConfigContext } from "@WahooFitness/wahoo-offline-mfe";

interface ElevationFilterProps {
  max: number;
  param: keyof Pick<FilterParams, "duration" | "tss" | "distance" | "elevation_gain">;
  label: string;
  defaultFilterParams: FilterParams;
  filterParams: FilterParams;
  updateFilter: (newValue: Partial<FilterParams>) => void;
}

// increase to make curvature more exponential
// (i.e. higher numbers get closer together on slider)
const NONLINEARITY = 1.5;

function RangeFilterChip({
  max,
  param,
  label,
  filterParams,
  defaultFilterParams,
  updateFilter,
}: PropsWithChildren<ElevationFilterProps>) {
  const { palette } = useTheme();
  const { globalBottomPadding, platform } = useConfigContext();
  const { unitFormatter, formatDuration } = useUnitFormatter();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const handleOpen = useCallback(() => setDrawerOpen(true), []);
  const handleClose = useCallback(() => setDrawerOpen(false), []);

  const resetFilter = useCallback(() => {
    updateFilter({ [param]: defaultFilterParams[param] });
  }, [defaultFilterParams, param, updateFilter]);

  // converts the slider value to a number of meters
  const calculateValue = useCallback(
    (value: number) => {
      return (max / (Math.exp(NONLINEARITY) - 1)) * (Math.exp(NONLINEARITY * value) - 1);
    },
    [max]
  );

  const displayValue = useCallback(
    (value: number) => {
      let formatted = { rawValue: 0, formattedValue: "", formattedUnit: "" };
      switch (param) {
        case "elevation_gain":
          formatted = unitFormatter?.formatElevation(value) || formatted;
          return {
            value: Math.round(formatted.rawValue / 10) * 10,
            unit: formatted?.formattedUnit,
          };
        case "distance":
          formatted = unitFormatter?.formatDistance(value) || formatted;
          return { value: formatted?.rawValue.toPrecision(3), unit: formatted?.formattedUnit };
        case "duration":
          return { value: value === 0 ? "0:00" : formatDuration(value), unit: "" };
        case "tss":
          formatted = unitFormatter?.formatTSS(value) || formatted;
          return { value: Math.round(formatted?.rawValue), unit: formatted?.formattedUnit };
        default:
          return { value: formatted?.rawValue.toPrecision(3), unit: formatted?.formattedUnit };
      }
    },
    [param, unitFormatter, formatDuration]
  );

  const handleSliderChange = useCallback(
    (_ev: any, newValues: number | number[]) => {
      updateFilter({
        [param]: {
          userSelection: newValues,
          min_m: calculateValue((newValues as number[])[0]),
          max_m: calculateValue((newValues as number[])[1]),
        },
      });
    },
    [updateFilter, calculateValue, param]
  );

  const filterRangeString = useMemo(() => {
    const minValue = displayValue(filterParams?.[param]?.min_m || 0);
    const maxValue = displayValue(filterParams?.[param]?.max_m || max);
    const maxAtMax = Math.abs((filterParams?.[param]?.max_m || 0) - max) < 0.1;
    return `${minValue?.value}-${maxValue?.value}${maxAtMax ? "+ " : " "} ${minValue?.unit}`;
  }, [displayValue, filterParams, max, param]);

  const { chipLabel, chipColor, chipTextColor } = useMemo(() => {
    if (
      Math.abs(
        (filterParams?.[param]!.min_m || defaultFilterParams[param]!.min_m) -
          defaultFilterParams[param]!.min_m
      ) < 0.1 &&
      Math.abs(
        (filterParams?.[param]!.max_m || defaultFilterParams[param]!.max_m) -
          defaultFilterParams[param]!.max_m
      ) < 0.1
    ) {
      return { chipLabel: label, chipColor: undefined, chipTextColor: undefined };
    }
    return {
      chipLabel: filterRangeString,
      chipColor: palette.info.main,
      chipTextColor: palette.info.contrastText,
    };
  }, [
    filterParams,
    param,
    defaultFilterParams,
    filterRangeString,
    palette.info.main,
    palette.info.contrastText,
    label,
  ]);

  return (
    <>
      <Chip
        label={chipLabel}
        onClick={handleOpen}
        sx={{ background: chipColor, color: chipTextColor }}
      />
      <SlidingDrawer
        platform={platform}
        globalBottomPadding={globalBottomPadding}
        open={drawerOpen}
        title={label}
        handleClose={handleClose}
        handleOpen={handleOpen}
        hidePuller
        rightAction={
          <Typography
            onClick={handleClose}
            color={palette.text.primary}
            sx={{ marginRight: 2, cursor: "pointer" }}
          >{t`Apply`}</Typography>
        }
        leftAction={
          <Typography
            onClick={resetFilter}
            color={palette.text.primary}
            sx={{ marginRight: 2, cursor: "pointer" }}
          >{t`Reset`}</Typography>
        }
      >
        <Box m={2}>
          <Paper elevation={4}>
            <Box m={4}>
              <Typography my={2} gutterBottom>
                {filterRangeString}
              </Typography>
              <Slider
                min={0}
                max={1}
                step={0.001}
                value={filterParams?.[param]?.userSelection}
                valueLabelDisplay="off"
                onChange={handleSliderChange}
              />
            </Box>
          </Paper>
        </Box>
      </SlidingDrawer>
    </>
  );
}

export default RangeFilterChip;
