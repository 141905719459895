import { Box } from "@mui/system";
import AverageTimeQuestion from "./AverageTimeQuestion.tsx";
import AverageIntensityQuestion from "./AverageIntensityQuestion";
import Moderate5kmQuestion from "./Moderate5kmQuestion.tsx";
import useNoBestKnownTimesInput from "./useNoBestKnownTimesInput.tsx";
import AboutYouQuestions from "./AboutYouQuestions.tsx";
import { Button } from "@mui/material";
import { Trans } from "@lingui/macro";
import { PaceZoneAlgorithmLookupsResponseType } from "@WahooFitness/cloud-client-types/PaceZoneAlgorithmLookupsType";

type NoBestKnownTimesInputProps = {
  setPaceResultsAvailable: (value: boolean) => void;
  setYourPaceZones: (paceZones: PaceZoneAlgorithmLookupsResponseType) => void;
};

const NoBestKnownTimesInput = ({
  setPaceResultsAvailable,
  setYourPaceZones,
}: NoBestKnownTimesInputProps) => {
  const {
    averageTimeListItems,
    averageIntensityListItems,
    moderate5kmListItems,
    currentUser,
    handleApply,
    nextDisabled,
  } = useNoBestKnownTimesInput(setPaceResultsAvailable, setYourPaceZones);

  return (
    <>
      <Box display="flex" flexDirection="column" rowGap={2} sx={{ mt: 4 }}>
        {currentUser && (
          <AboutYouQuestions averageTimeListItems={averageTimeListItems} user={currentUser} />
        )}
        <AverageTimeQuestion averageTimeListItems={averageTimeListItems} />
        <AverageIntensityQuestion averageIntensityListItems={averageIntensityListItems} />
        <Moderate5kmQuestion moderate5kmListItems={moderate5kmListItems} />
      </Box>
      <Box display="flex" p={3} justifyContent="center" maxWidth={"sm"}>
        <Button
          size="large"
          variant="contained"
          disabled={nextDisabled}
          fullWidth
          onClick={handleApply}
        >
          <Trans>Next</Trans>
        </Button>
      </Box>
    </>
  );
};

export default NoBestKnownTimesInput;
