import { useCallback, useMemo } from "react";
import useSWR from "swr";
import { Subscriptions } from "@WahooFitness/cloud-client-ts";
import { useCloudContext, useConfigContext } from "@WahooFitness/wahoo-offline-mfe";
import { WahooTokenType } from "@WahooFitness/cloud-client-types";

export enum SubscriptionState {
  Active = "active",
  CanTrial = "canTrial",
  Expired = "expired",
}
const useSubscription = () => {
  const { wahooToken, fitnessAppId } = useConfigContext();
  const { getCloudClient } = useCloudContext();
  const subscriptionClient = useMemo(() => getCloudClient(Subscriptions), [getCloudClient]);

  const {
    data: subscription,
    error: subscriptionError,
    isLoading: subscriptionIsLoading,
    mutate: mutateSubscription,
  } = useSWR(
    wahooToken && ["subscription", wahooToken],
    ([_key, wahooToken]: [_key: string, wahooToken: WahooTokenType]) => {
      return subscriptionClient.get(wahooToken);
    }
  );

  const startTrial = useCallback(async () => {
    await subscriptionClient.startTrial(wahooToken, fitnessAppId);
    mutateSubscription();
  }, [fitnessAppId, mutateSubscription, subscriptionClient, wahooToken]);

  const { data: trialDuration } = useSWR(
    wahooToken && ["trialDuration", wahooToken],
    ([_key, wahooToken]: [_key: string, wahooToken: WahooTokenType]) => {
      return subscriptionClient.getTrialDuration(wahooToken);
    }
  );

  const isEligibleForPremiumContent = useMemo(() => {
    return subscription?.status === "active" || subscription?.status === "trialing";
  }, [subscription?.status]);

  const subscriptionState = useMemo(() => {
    const { days } = trialDuration || {};
    if (!isEligibleForPremiumContent && (days ?? 0) > 0) {
      return SubscriptionState.CanTrial;
    }
    if (!isEligibleForPremiumContent && (days ?? 0) <= 0) {
      return SubscriptionState.Expired;
    }
    return SubscriptionState.Active;
  }, [isEligibleForPremiumContent, trialDuration]);

  return {
    subscription,
    isEligibleForPremiumContent,
    error: subscriptionError,
    isLoading: subscriptionIsLoading,
    subscriptionState,
    mutateSubscription,
    startTrial,
  };
};

export default useSubscription;
