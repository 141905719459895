import { useMemo } from "react";
import { FavoriteFilled, FlashFilled, Meter } from "@carbon/icons-react";
import { Avatar, Box, Skeleton, useTheme } from "@mui/material";
import { UserType } from "@WahooFitness/cloud-client-types";
import useRiderType from "../RiderType/useRiderType";
import { t } from "@lingui/macro";
import { getLocalizedRiderTypeData } from "../RiderType/LocalizedRiderTypes";
import { getKnightTitle } from "@/services/getKnightTitle";
import { useConfigContext, postRoute } from "@WahooFitness/wahoo-offline-mfe";
import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import { MenuListItemType, MenuListItemVariant } from "@WahooFitness/redesignr";
import { ReactRouterLinkWrapper } from "../MenuList/ReactRouterLinkWrapper";
import { FlaggedFeature } from "@/hooks/types/FlaggedFeature";

type MenuListItemTypeWithDataTestId = MenuListItemType & { "data-testid": string };

function useSettings(user?: UserType) {
  const { checkIsFeatureEnabled, featuresAreLoading } = useFlaggedFeatures();
  const {
    wahooToken,
    fitnessAppId,
    cloudConfig: { outOfTheBoxUrl, wahooMyAccountUrl },
    locale,
  } = useConfigContext();
  const { palette } = useTheme();
  const {
    userWorkoutBikingProfilesError,
    userWorkoutBikingProfilesLoading,
    indoorRiderTypeName,
    indoorRiderTypeIconUrl,
  } = useRiderType();

  const riderTypeItem = useMemo(
    (): MenuListItemTypeWithDataTestId =>
      userWorkoutBikingProfilesError || userWorkoutBikingProfilesLoading
        ? {
            id: "riderType",
            icon: <Skeleton variant="circular" width={24} height={24} />,
            content: t`Rider type`,
            secondaryContent: (
              <Box py={3 / 8}>
                <Skeleton width={100} height="0.875rem" variant="rectangular" />
              </Box>
            ),
            variant: MenuListItemVariant.InternalLink,
            linkComponent: ReactRouterLinkWrapper,
            linkLocation: "/rider-type",
            "data-testid": "settings.riderType",
          }
        : {
            id: "riderType",
            icon: indoorRiderTypeIconUrl ? (
              <img src={indoorRiderTypeIconUrl} alt="Rider type" width={24} height={24} />
            ) : (
              <></>
            ),
            content: t`Rider type`,
            secondaryContent:
              indoorRiderTypeName && getLocalizedRiderTypeData()[indoorRiderTypeName]?.name,
            variant: MenuListItemVariant.InternalLink,
            linkComponent: ReactRouterLinkWrapper,
            linkLocation: "/rider-type",
            "data-testid": "settings.riderType",
          },
    [
      indoorRiderTypeIconUrl,
      indoorRiderTypeName,
      userWorkoutBikingProfilesError,
      userWorkoutBikingProfilesLoading,
    ]
  );

  const knightTitle = useMemo(() => getKnightTitle(user), [user]);
  const settingsItems: MenuListItemType[][] = useMemo((): MenuListItemTypeWithDataTestId[][] => {
    const zoneOptions: MenuListItemTypeWithDataTestId[] = [
      {
        icon: <FavoriteFilled size={24} fill={palette.dataHeartRate.main} />,
        id: "heartRateZones",
        content: t`Heart rate zones`,
        variant: MenuListItemVariant.InternalLink,
        linkComponent: ReactRouterLinkWrapper,
        linkLocation: "/heart-rate-zones",
        "data-testid": "settings.hrZones",
      },
      {
        icon: <FlashFilled size={24} fill={palette.dataPower.main} />,
        id: "powerZones",
        content: t`Power zones`,
        variant: MenuListItemVariant.InternalLink,
        linkComponent: ReactRouterLinkWrapper,
        linkLocation: "/power-zones",
        "data-testid": "settings.powerZones",
      },
    ];
    if (checkIsFeatureEnabled(FlaggedFeature.PaceZones)) {
      zoneOptions.push({
        icon: <Meter size={24} fill={palette.dataSpeed.main} />,
        id: "paceZones",
        content: t`Running pace zones`,
        variant: MenuListItemVariant.InternalLink,
        linkComponent: ReactRouterLinkWrapper,
        linkLocation: "/pace-zones",
        "data-testid": "settings.paceZones",
      });
    }
    return [
      [
        {
          id: "athleteProfile",
          icon: user ? (
            <Avatar
              src={user?.avatar?.url}
              imgProps={{ height: 40, width: 40 }}
              sx={{ color: "text.primary" }}
            />
          ) : (
            <Skeleton height={40} width={40} variant="circular" />
          ),
          content: user ? (
            `${knightTitle} ${user.first} ${user.last}`
          ) : (
            <Box py={3 / 8}>
              <Skeleton width={150} height="1rem" variant="rectangular" />
            </Box>
          ),
          secondaryContent: user ? (
            user.email
          ) : (
            <Box py={3 / 8}>
              <Skeleton width={250} height="0.875rem" variant="rectangular" />
            </Box>
          ),
          variant: MenuListItemVariant.InternalLink,
          linkComponent: ReactRouterLinkWrapper,
          linkLocation: "/athlete-profile",
          "data-testid": "settings.profile",
          disabled: !user,
        },
      ],
      zoneOptions,
      [riderTypeItem],
      [
        {
          id: "trainingPreferences",
          content: t`Training preferences`,
          variant: MenuListItemVariant.InternalLink,
          linkComponent: ReactRouterLinkWrapper,
          linkLocation: "/training-preferences",
          "data-testid": "settings.trainingPreferences",
        },
      ],
      [
        {
          id: "workoutProfiles",
          content: t`Workout profiles`,
          variant: MenuListItemVariant.InternalLink,
          linkComponent: ReactRouterLinkWrapper,
          linkLocation: "/workout-profiles",
          "data-testid": "settings.workoutProfiles",
        },
        {
          id: "authorizedApps",
          content: t`Authorized apps`,
          variant: MenuListItemVariant.InternalLink,
          linkComponent: ReactRouterLinkWrapper,
          linkLocation: "/third-party-sharing",
          "data-testid": "settings.sharing",
        },
        {
          id: "setupGuides",
          content: t`Set up guides`,
          variant: MenuListItemVariant.ExternalLink,
          linkLocation: `${outOfTheBoxUrl}/guides?locale=${locale}`,
          "data-testid": "settings.setupGuides",
        },
      ],
      [
        {
          id: "account",
          content: t`My account`,
          variant: MenuListItemVariant.ExternalLink,
          linkLocation: `${wahooMyAccountUrl}/?faid=${fitnessAppId}&auth_token=${wahooToken}`,
          "data-testid": "settings.myAccount",
        },
        {
          id: "tos",
          content: t`Terms of Service`,
          variant: MenuListItemVariant.ExternalLink,
          linkLocation: "https://wahoosystm.com/TermsOfService",
          "data-testid": "settings.tos",
        },
        {
          id: "privacyPolicy",
          content: t`Privacy Policy`,
          variant: MenuListItemVariant.ExternalLink,
          linkLocation: "https://wahoosystm.com/PrivacyPolicy",
          "data-testid": "settings.privacyPolicy",
        },
        {
          id: "attributions",
          content: t`Attributions`,
          variant: MenuListItemVariant.Action,
          action: () => postRoute("attributions"),
          "data-testid": "settings.attributions",
        },
        {
          id: "contactSupport",
          content: t`Contact customer support`,
          variant: MenuListItemVariant.ExternalLink,
          linkLocation: "https://wahoosystm.com/SubmitATicket",
          "data-testid": "settings.contactSupport",
        },
        {
          id: "deleteAccount",
          content: t`Delete account`,
          variant: MenuListItemVariant.InternalLink,
          linkComponent: ReactRouterLinkWrapper,
          linkLocation: "/deleteAccount",
          "data-testid": "settings.deleteAccount",
        },
      ],
    ];
  }, [
    palette.dataHeartRate.main,
    palette.dataPower.main,
    palette.dataSpeed.main,
    checkIsFeatureEnabled,
    user,
    knightTitle,
    riderTypeItem,
    outOfTheBoxUrl,
    locale,
    wahooMyAccountUrl,
    fitnessAppId,
    wahooToken,
  ]);
  const availableItems = useMemo(
    () => settingsItems.map((section) => section.filter((item) => item.isAvailable !== false)),
    [settingsItems]
  );
  return {
    settingsItems: availableItems,
    isLoading: featuresAreLoading,
  };
}

export default useSettings;
