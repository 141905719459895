import { Trans } from "@lingui/macro";
import { Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { Checkmark } from "@carbon/icons-react";
import { useMembershipConfig } from "@/hooks/useMembershipConfig";
import { AsyncButton } from "@WahooFitness/redesignr";

const Membership = ({
  onClose,
  analyticsParams,
}: {
  onClose: () => void;
  externalId?: string;
  analyticsParams?: { [key: string]: string | number | undefined };
}) => {
  const {
    onButtonClick,
    primaryText,
    membershipBenefitsText,
    buttonText,
    linkHref,
    membershipBenefitsTitle,
    secondaryText,
  } = useMembershipConfig({
    onClose,
    analyticsParams,
  });

  return (
    <Box
      display="flex"
      height="100%"
      justifyContent="space-between"
      width="100%"
      flexDirection="column"
    >
      <Box display="flex" flexDirection="column" p={3} gap={3} alignItems="center">
        <Typography textAlign="center" variant="ui-base-bold">
          {primaryText}
        </Typography>
        {secondaryText && (
          <Typography textAlign="center" variant="ui-base-bold">
            {secondaryText}
          </Typography>
        )}
      </Box>
      <Box
        component={Paper}
        elevation={4}
        display="flex"
        flexDirection="column"
        gap={2}
        padding={3}
        sx={{ borderRadius: 0 }}
        width="100%"
      >
        <Typography variant="ui-base-bold">{membershipBenefitsTitle}</Typography>
        {membershipBenefitsText.map((text, index) => (
          <Stack key={index} direction="row" alignItems="center" gap={2}>
            <Box display="flex">
              <Checkmark size={16} />
            </Box>
            <Typography fontSize={16} variant="prose-base">
              {text}
            </Typography>
          </Stack>
        ))}
        <AsyncButton
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          onClick={onButtonClick}
          href={linkHref}
        >
          <Trans>{buttonText}</Trans>
        </AsyncButton>
      </Box>
    </Box>
  );
};

export default Membership;
