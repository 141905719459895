import { useCallback, useEffect, useMemo, useRef } from "react";
import { Box, Button, IconButton, ListItem, Paper, Typography } from "@mui/material";
import { AsyncButton, AutoSelectTextField, SlidingDrawer } from "@WahooFitness/redesignr";
import ErrorBoundary from "../ErrorBoundary";
import useZones from "./useZones";
import ZoneSetView from "./ZoneSetView";
import { AddFilled } from "@carbon/icons-react";
import FullScreenDialog from "../FullScreenDialog";
import { Trans, t } from "@lingui/macro";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import { useConfigContext } from "@WahooFitness/wahoo-offline-mfe";

const Zones = ({ type }: { type: "power" | "heartRate" | "speed" }) => {
  const { globalBottomPadding, platform } = useConfigContext();
  const { setNavHeader } = useHeaderContext();
  const newZoneDrawerRef = useRef<HTMLDivElement>(null);

  const {
    isLoading,
    error,
    reloadZones,
    updatedAt,
    savedZoneSets,
    zoneSets,
    handleNameChange,
    handleZoneRangeChange,
    handleDiscardChanges,
    handleSaveZoneSet,
    handleZoneCountChange,
    fixInvalidZones,
    editing,
    enableEditing,
    disableEditing,
    zonesSetIsValid,
    zoneCounts,
    zoneCountChoices,
    autoCalculateVariant,
    handleAutoCalcZones,
    addingNewZone,
    handleAddNew,
    handleClose,
    primaryZoneSetId,
    handleDeleteZoneSet,
    duplicateZoneSetNameError,
    maxAutoCalculateValues,
    savingZones,
    replaceZoneSet,
  } = useZones(type);

  const handleReload = useCallback(() => {
    reloadZones();
  }, [reloadZones]);

  const enableEditDialog = useCallback(
    (zoneSetId: number) => {
      enableEditing(zoneSetId);
    },
    [enableEditing]
  );
  const disableEditDialog = useCallback(() => {
    disableEditing();
  }, [disableEditing]);

  const getProps = useCallback(
    (zoneSetId: number) => ({
      zoneSet: zoneSets[zoneSetId],
      zoneSetId: zoneSetId,
      savedZoneSet: savedZoneSets[zoneSetId],
      zoneCount: zoneCounts[zoneSetId],
      zoneCountChoices: zoneCountChoices,
      updatedAt: updatedAt?.[zoneSetId],
      enableEditing: enableEditDialog,
      handleZoneCountChange: handleZoneCountChange,
      handleZoneRangeChange: handleZoneRangeChange,
      autoCalculateVariant: autoCalculateVariant,
      maxAutoCalculateValue: maxAutoCalculateValues[autoCalculateVariant],
      handleAutoCalcZones: handleAutoCalcZones,
      fixInvalidZones: fixInvalidZones,
      allowEditing: true,
      type: type,
      primaryZoneSetId,
      parentDrawerRef: newZoneDrawerRef,
      replaceZoneSet,
    }),
    [
      autoCalculateVariant,
      enableEditDialog,
      fixInvalidZones,
      handleAutoCalcZones,
      handleZoneCountChange,
      handleZoneRangeChange,
      maxAutoCalculateValues,
      primaryZoneSetId,
      savedZoneSets,
      type,
      updatedAt,
      zoneCountChoices,
      zoneCounts,
      zoneSets,
      replaceZoneSet,
    ]
  );

  const getZoneSetNameHelperText = useCallback(
    (zoneSetId: number) => {
      if (duplicateZoneSetNameError) {
        return t`name already in use`;
      }
      if (!zoneSets[zoneSetId]?.name) {
        return t`Zone Set name cannot be blank`;
      }
    },
    [duplicateZoneSetNameError, zoneSets]
  );

  const zoneSetNameHelperText = useMemo(
    () => getZoneSetNameHelperText(editing || 0),
    [editing, getZoneSetNameHelperText]
  );

  const HeaderAction = useMemo(
    () => (
      <IconButton onClick={handleAddNew} data-testid="addZonesButton" color="primary">
        <AddFilled size="24" />
      </IconButton>
    ),
    [handleAddNew]
  );

  const headerProps = useMemo(() => {
    let title = "";
    switch (type) {
      case "power":
        title = t`Power zones`;
        break;
      case "heartRate":
        title = t`Heart rate zones`;
        break;
      default:
        title = t`Running pace zones`;
        break;
    }
    return {
      title,
      headerAction: HeaderAction,
    };
  }, [HeaderAction, type]);

  useEffect(() => {
    setNavHeader(headerProps);
  });

  const hasZoneSets = Object.keys(zoneSets).length > 0;

  return (
    <ErrorBoundary error={error} isLoading={isLoading} onReload={handleReload}>
      {hasZoneSets ? (
        <Box display="flex" flexDirection="column" mx={2} py={2} gap={2}>
          {Object.keys(zoneSets)
            .filter((zoneSetId) => +zoneSetId !== -1)
            .sort((a, b) => zoneSets[+a].displayIndex - zoneSets[+b].displayIndex)
            .map((zoneSetIdString, index) => {
              const zoneSetId = +zoneSetIdString;
              return (
                <Box component={Paper} elevation={4} pb={2} key={zoneSetId}>
                  <Box p={2}>
                    <Typography variant="prose-base-bold">{zoneSets[zoneSetId].name}</Typography>
                  </Box>
                  <ZoneSetView {...getProps(zoneSetId)} editing={false} displayIndex={index} />
                </Box>
              );
            })}
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" m={2} gap={2}>
          <Typography textAlign="center">
            <Trans>
              Set up running pace zones to do pace-based workouts and track time spent in each zone.
            </Trans>
          </Typography>
          <Button variant="contained" fullWidth onClick={handleAddNew}>
            <Trans>Create running pace zones</Trans>
          </Button>
        </Box>
      )}
      <SlidingDrawer
        platform={platform}
        globalBottomPadding={globalBottomPadding}
        open={addingNewZone}
        handleClose={handleClose}
        handleOpen={handleAddNew}
        title={t`New zone set`}
        ref={newZoneDrawerRef}
        footer={
          <Box mx={3} my={2} display="flex" justifyContent="flex-end">
            <Button
              onClick={handleClose}
              data-testid="cancelNewZoneSetButton"
              disabled={savingZones}
            >
              <Trans>Cancel</Trans>
            </Button>
            <AsyncButton
              onClick={() => handleSaveZoneSet(-1)}
              disabled={duplicateZoneSetNameError}
              data-testid="saveNewZoneSetButton"
            >
              <Trans>Done</Trans>
            </AsyncButton>
          </Box>
        }
      >
        {zoneSets[-1] && (
          <Box m={2}>
            <Box m={2}>
              <AutoSelectTextField
                value={zoneSets[-1].name}
                onChange={(ev) => handleNameChange(ev, -1)}
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                }}
                error={!!getZoneSetNameHelperText(-1)}
                helperText={getZoneSetNameHelperText(-1)}
                inputProps={{ "data-testid": "newZoneSetNameInput" }}
              />
            </Box>
            <ZoneSetView {...getProps(-1)} editing={true} displayIndex={0} />
          </Box>
        )}
      </SlidingDrawer>
      {editing && (
        <FullScreenDialog
          open={editing !== undefined}
          handleClose={disableEditDialog}
          headerProps={{
            title: t`Edit Zones`,
            backAction: handleDiscardChanges,
            backText: t`Cancel`,
            headerAction: (
              <AsyncButton
                variant="contained"
                color="primary"
                onClick={() => handleSaveZoneSet(editing)}
                disabled={
                  !zonesSetIsValid(zoneSets[editing], zoneCounts[editing]) ||
                  !zoneSets[editing].name ||
                  duplicateZoneSetNameError
                }
                datatest-id="saveZoneSetButton"
              >
                <Trans>Save</Trans>
              </AsyncButton>
            ),
          }}
          slideDirection="up"
        >
          <Box mt={2} component={Paper} elevation={4} square borderRadius={1}>
            <Box p={2}>
              <AutoSelectTextField
                value={zoneSets[editing].name}
                onChange={(ev) => handleNameChange(ev, editing)}
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                }}
                error={!!zoneSetNameHelperText}
                helperText={zoneSetNameHelperText}
                inputProps={{ "data-testid": "zoneNameInput" }}
                label={<Trans>Zone name</Trans>}
                sx={{ width: "100%" }}
              />
            </Box>
            <ZoneSetView {...getProps(editing)} editing={true} displayIndex={0}>
              <ListItem>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  width="100%"
                  gap={1}
                  py={0.5}
                >
                  {editing !== primaryZoneSetId && (
                    <Button
                      variant="outlined"
                      size="large"
                      color="error"
                      fullWidth
                      onClick={() => handleDeleteZoneSet(editing)}
                      data-testid="deleteZoneSetButton"
                    >
                      <Trans>Delete zone set</Trans>
                    </Button>
                  )}
                </Box>
              </ListItem>
            </ZoneSetView>
          </Box>
        </FullScreenDialog>
      )}
    </ErrorBoundary>
  );
};

export default Zones;
