import { useHeaderContext } from "@/hooks/useHeaderContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import WarmingUp from "@/components/WarmUp";
import { useInView } from "react-intersection-observer";
import { t, Trans } from "@lingui/macro";
import { useRoutesContext } from "@/hooks/useRoutesContext";
import SearchBar from "@/components/SearchBar";
import FilterBar from "@/components/FilterBar/FilterBar";
import AddRouteDrawer from "../AddRouteDrawer";
import { AddFilled } from "@carbon/icons-react";
import { PullToRefresh, useDrawerContext } from "@WahooFitness/wahoo-offline-mfe";
import useCurrentTime from "@/hooks/useCurrentTime";
import RouteGroup from "./RouteGroup";
import { WSMRoute } from "@WahooFitness/wsm-native/dist/esm/types/route";
import { defaultRouteFilterParams } from "@/components/FilterBar/FilterParams";
import { useSearchParams } from "react-router-dom";
import SelectRouteDrawer from "../SelectRouteDrawer";

function RouteList() {
  const { palette } = useTheme();
  const {
    routeList,
    filtersApplied,
    clearAllFilters,
    refreshRouteList,
    pendingPublicRouteIds,
    pendingRemovedPublicRouteIds,
    filterParams,
    updateFilter,
    sortOptions,
    sortDirections,
    sortParams,
    setSortDirection,
    setSortKey,
    selectRouteDrawerOpen,
    closeSelectRouteDrawer,
  } = useRoutesContext();
  const { setNavHeader } = useHeaderContext();
  const { setDrawer, handleClose } = useDrawerContext();
  const [visibleCount, setVisibleCount] = useState(7);

  const openAddRouteDrawer = useCallback(() => {
    setDrawer({
      open: true,
      title: t`Add a route`,
      hidePuller: true,
      children: <AddRouteDrawer handleClose={handleClose} />,
    });
  }, [handleClose, setDrawer]);

  const HeaderActionComponent = useMemo(
    () => (
      <Box mr={1}>
        <IconButton onClick={openAddRouteDrawer}>
          <AddFilled size={24} />
        </IconButton>
      </Box>
    ),
    [openAddRouteDrawer]
  );

  const [searchParams] = useSearchParams();

  const headerProps = useMemo(
    () => ({
      title: t`My Routes`,
      headerAction: HeaderActionComponent,
      disableBackButton: searchParams.get("back") !== "true",
    }),
    [HeaderActionComponent, searchParams]
  );
  useEffect(() => {
    setNavHeader(headerProps);
  }, [setNavHeader, headerProps]);
  const { ref: loadMoreRef } = useInView({
    onChange: (inView: boolean) => {
      if (inView) {
        setTimeout(() => {
          setVisibleCount((prevCount) => prevCount + 3);
        }, 400);
      }
    },
    rootMargin: "0px 0px 400px 0px",
  });

  const currentTime = useCurrentTime(1000);

  const isPublic = useCallback(
    (route: WSMRoute) =>
      (route.expires_at &&
        new Date(route.expires_at) > currentTime &&
        !pendingRemovedPublicRouteIds.includes(route.id)) ||
      pendingPublicRouteIds.includes(route.id),
    [currentTime, pendingPublicRouteIds, pendingRemovedPublicRouteIds]
  );

  const publicRouteList = useMemo(() => {
    return routeList.filter(isPublic);
  }, [isPublic, routeList]);

  const mainRouteList = useMemo(() => {
    return filtersApplied
      ? routeList
      : routeList.filter((route) => !publicRouteList.includes(route));
  }, [filtersApplied, publicRouteList, routeList]);

  if (!routeList) return <WarmingUp />;
  return (
    <Box display="flex" flexDirection="column" pt={2}>
      <PullToRefresh onRefresh={refreshRouteList} />
      <SearchBar searchTerm={filterParams?.search} updateFilter={updateFilter} />
      <FilterBar
        sortParams={sortParams}
        sortDirections={sortDirections}
        sortOptions={sortOptions}
        setSortDirection={setSortDirection}
        setSortKey={setSortKey}
        filterParams={filterParams}
        updateFilter={updateFilter}
        defaultFilterParams={defaultRouteFilterParams}
      />
      {!filtersApplied && publicRouteList.length > 0 && (
        <>
          <Box mx={2} my={1}>
            <RouteGroup routes={publicRouteList} title={t`Shared as public`} />
          </Box>
        </>
      )}
      <Box display="flex" mx={4} my={1} alignItems="center" justifyContent="space-between">
        {filtersApplied && (
          <Box aria-role="button" sx={{ cursor: "pointer" }} onClick={clearAllFilters}>
            <Typography variant="prose-xs-medium" color={palette.info.main}>
              <Trans>Reset filters</Trans>
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="column" rowGap={1} alignItems="center" mx={2}>
        {routeList.length === 0 && (
          <Typography>
            <Trans>No routes matching your current filter selection.</Trans>
          </Typography>
        )}
        <RouteGroup
          routes={mainRouteList.slice(0, visibleCount)}
          title={t`${mainRouteList.length} routes`}
        />
        {visibleCount < mainRouteList.length ? (
          <Box display="flex" justifyContent="center" ref={loadMoreRef} my={0.5}>
            <WarmingUp noTopSpacing />
          </Box>
        ) : (
          mainRouteList.length > 0 && (
            <Box my={1} mx={6}>
              <Typography align="center">
                <Trans>That&apos;s all your results for the current filter selection!</Trans>
              </Typography>
            </Box>
          )
        )}
      </Box>
      <SelectRouteDrawer open={selectRouteDrawerOpen} handleClose={closeSelectRouteDrawer} />
    </Box>
  );
}

export default RouteList;
