import { WahooWorkoutTypeFamily } from "@WahooFitness/cloud-client-types";
import { t } from "@lingui/macro";

const getTemplateTypes = (): {
  workoutFamilyId: WahooWorkoutTypeFamily;
  workoutFamilyName: string;
  workoutFamily: string;
  workoutTypes: number[];
}[] => [
  {
    workoutFamilyId: WahooWorkoutTypeFamily.Cycling,
    workoutFamily: "cycling",
    workoutFamilyName: t`Cycling`,
    workoutTypes: [0, 13, 15, 11, 21, 14, 16, 12, 64],
  },
  {
    workoutFamilyId: WahooWorkoutTypeFamily.Running,
    workoutFamily: "running",
    workoutFamilyName: t`Running`,
    workoutTypes: [1, 5, 3, 4, 67],
  },
  {
    workoutFamilyId: WahooWorkoutTypeFamily.Walking,
    workoutFamily: "walking",
    workoutFamilyName: t`Walking`,
    workoutTypes: [6, 7, 8, 56, 9, 10],
  },
  {
    workoutFamilyId: WahooWorkoutTypeFamily.Swimming,
    workoutFamily: "swimming",
    workoutFamilyName: t`Swimming`,
    workoutTypes: [25, 26],
  },
  {
    workoutFamilyId: WahooWorkoutTypeFamily.Skating,
    workoutFamily: "skating",
    workoutFamilyName: t`Skating`,
    workoutTypes: [31, 32, 33, 34],
  },
  {
    workoutFamilyId: WahooWorkoutTypeFamily.Water,
    workoutFamily: "waterSports",
    workoutFamilyName: t`Water Sports`,
    workoutTypes: [35, 36, 37, 38, 39, 40, 41],
  },
  {
    workoutFamilyId: WahooWorkoutTypeFamily.Snow,
    workoutFamily: "snowSports",
    workoutFamilyName: t`Snow Sports`,
    workoutTypes: [27, 28, 29, 30],
  },
  {
    workoutFamilyId: WahooWorkoutTypeFamily.Gym,
    workoutFamily: "gym",
    workoutFamilyName: t`Gym`,
    workoutTypes: [42, 43, 44, 66, 20, 22],
  },
  {
    workoutFamilyId: WahooWorkoutTypeFamily.Other,
    workoutFamily: "other",
    workoutFamilyName: t`Other`,
    workoutTypes: [69, 45, 46, 17, 47],
  },
];

export { getTemplateTypes };
