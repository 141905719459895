import Sex from "@/services/types/Sex";
import { t } from "@lingui/macro";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction, useCallback } from "react";

interface SexProps {
  userSex: Sex;
  setUserSex: Dispatch<SetStateAction<Sex>>;
}

function SexSelect({ userSex, setUserSex }: SexProps) {
  const handleChange = useCallback(
    (_ev: ChangeEvent<HTMLInputElement>, value: string) => {
      setUserSex(Number(value));
    },
    [setUserSex]
  );

  return (
    <FormControl>
      <RadioGroup
        name="sex-select-radio-button-group"
        onChange={handleChange}
        value={String(userSex)}
      >
        <FormControlLabel value="1" control={<Radio />} label={t`Female`} />
        <FormControlLabel value="0" control={<Radio />} label={t`Male`} />
        <FormControlLabel value="3" control={<Radio />} label={t`Prefer not to answer`} />
      </RadioGroup>
    </FormControl>
  );
}

export default SexSelect;
