import { AddFilled } from "@carbon/icons-react";
import { Box, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import FullScreenDialog from "../FullScreenDialog";
import WarmingUp from "../WarmUp";
import AddWorkoutProfile from "./AddWorkoutProfile/AddWorkoutProfile";
import useWorkoutProfiles from "./useWorkoutProfiles";
import { Trans, t } from "@lingui/macro";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import { postClose, PullToRefresh } from "@WahooFitness/wahoo-offline-mfe";
import { useSearchParams } from "react-router-dom";
import { WahooWorkoutTypeFamily } from "@WahooFitness/cloud-client-types";
import { MenuList } from "@WahooFitness/redesignr";

const WorkoutProfiles = ({ openAsDrawer }: { openAsDrawer?: boolean }) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const [searchParams, _] = useSearchParams();
  const familyFilter = useMemo(
    () =>
      searchParams.get("familyFilter")
        ? (Number(searchParams.get("familyFilter")) as WahooWorkoutTypeFamily)
        : undefined,
    [searchParams]
  );
  const {
    editing,
    toggleEditing,
    workoutProfilesList,
    workoutProfilesLoading,
    mutateWorkoutProfiles,
  } = useWorkoutProfiles({ isNew: false, familyFilter });
  const { setNavHeader } = useHeaderContext();

  const EditButton = useMemo(
    () => (
      <Box mr={1}>
        <Button size="small" variant="text" onClick={toggleEditing}>
          <Typography variant="prose-base">{editing ? t`Done` : t`Edit`}</Typography>
        </Button>
      </Box>
    ),
    [editing, toggleEditing]
  );

  const closeDrawer = useCallback(() => postClose("workoutProfiles"), []);

  const headerProps = useMemo(
    () => ({
      title: t`Workout profiles`,
      headerAction: EditButton,
      showCloseIcon: openAsDrawer,
      backAction: openAsDrawer ? closeDrawer : undefined,
    }),
    [EditButton, closeDrawer, openAsDrawer]
  );
  useEffect(() => {
    setNavHeader(headerProps);
  });

  const handleClose = useCallback(() => {
    setOpen(false);
    setNavHeader(headerProps);
  }, [headerProps, setNavHeader]);

  if (workoutProfilesLoading) {
    return (
      <React.Fragment>
        <Box>
          <WarmingUp />
        </Box>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <PullToRefresh onRefresh={mutateWorkoutProfiles} />
      <Box pb={1}>
        <Box p={2} pb={1}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            startIcon={<AddFilled size={24} />}
            onClick={handleOpen}
            size="large"
          >
            <Trans>Add workout profile</Trans>
          </Button>
        </Box>
        <MenuList listItems={workoutProfilesList} />
      </Box>
      <FullScreenDialog
        slideDirection="up"
        open={open}
        handleClose={handleClose}
        headerProps={{
          title: t`Add workout profile`,
          backAction: handleClose,
          hasDivider: true,
          closeIcon: true,
        }}
      >
        <AddWorkoutProfile closeParent={handleClose} familyFilter={familyFilter} />
      </FullScreenDialog>
    </React.Fragment>
  );
};

export default WorkoutProfiles;
