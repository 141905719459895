import { useCallback, useMemo, useState } from "react";
import {
  ChevronRightIcon,
  DeleteIcon,
  MenuListItemType,
  MenuListItemVariant,
} from "@WahooFitness/redesignr";
import { getWorkoutTypeIcon } from "@/services/getWorkoutTypeIcon";
import { useDialogContext } from "@WahooFitness/wahoo-offline-mfe";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import { t } from "@lingui/macro";
import { WahooWorkoutTypeFamily } from "@WahooFitness/cloud-client-types";
import { ReactRouterLinkWrapper } from "../MenuList/ReactRouterLinkWrapper";

const useWorkoutProfiles = ({
  isNew = false,
  familyFilter,
  action,
}: {
  isNew?: boolean;
  familyFilter?: WahooWorkoutTypeFamily;
  action?: (profileId: number) => void;
}) => {
  const { setDialog } = useDialogContext();
  const { workoutProfiles, workoutProfilesLoading, deleteProfile, mutateWorkoutProfiles } =
    useWorkoutProfilesContext();
  const [editing, setEditing] = useState<boolean>(false);

  const getIcon = useCallback(
    (permanent: boolean) => {
      if (isNew) {
        return <ChevronRightIcon />;
      }
      if (!permanent) {
        return <DeleteIcon color="error" />;
      }
      return null;
    },
    [isNew]
  );

  const deleteProfileWithWarning = useCallback(
    async (profileId: number) => {
      setDialog({
        open: true,
        title: t`Delete workout profile?`,
        body: t`This action cannot be undone.`,
        actions: [
          { text: t`Cancel` },
          { text: t`Delete`, color: "error", action: () => deleteProfile(profileId) },
        ],
      });
    },
    [deleteProfile, setDialog]
  );

  const toggleEditing = useCallback(() => {
    setEditing((currentValue) => !currentValue);
  }, []);

  const workoutProfilesList: MenuListItemType[] = useMemo((): MenuListItemType[] => {
    const variant =
      editing || isNew ? MenuListItemVariant.Action : MenuListItemVariant.InternalLink;
    return (
      workoutProfiles
        ?.filter((profile) => {
          return familyFilter !== undefined
            ? profile.workout_type_family_id === familyFilter
            : true;
        })
        .map((workoutProfile): MenuListItemType => {
          const WorkoutTypeIcon = getWorkoutTypeIcon(workoutProfile.workout_type_id);
          return {
            id: String(workoutProfile.id),
            icon: <WorkoutTypeIcon sx={{ width: "24px", height: "24px" }} />,
            content: workoutProfile.name,
            variant,
            linkComponent: ReactRouterLinkWrapper,
            linkLocation: `/workout-profiles/${workoutProfile.id}`,
            action: action
              ? () => action(Number(workoutProfile.id))
              : () => !workoutProfile.permanent && deleteProfileWithWarning(workoutProfile.id),
            actionComponent: getIcon(workoutProfile.permanent),
          };
        }) || []
    );
  }, [action, deleteProfileWithWarning, editing, familyFilter, getIcon, isNew, workoutProfiles]);

  return {
    editing,
    toggleEditing,
    deleteProfile,
    workoutProfilesList,
    workoutProfilesLoading,
    mutateWorkoutProfiles,
  };
};

export default useWorkoutProfiles;
