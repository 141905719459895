import { useCallback, useMemo } from "react";
import { useAnalyticsContext, useUserContext } from "@WahooFitness/wahoo-offline-mfe";
import { Profile } from "@WahooFitness/cloud-client-types";
import { hasMinProfile } from "@/services/profileService";
import { FlaggedFeature } from "./types/FlaggedFeature";

enum RemoteConfigKey {
  ElemntConfigMinProfile = "ELEMNT_CONFIG_MIN_PROFILE",
  ElemntPlannedWorkoutsMinProfile = "ELEMNT_PLANNED_WORKOUTS_MIN_PROFILE",
  ElemntMultisportPageMinProfile = "ELEMNT_MULTISPORT_PAGE_MIN_PROFILE",
  ElemntPedalPageMinProfile = "ELEMNT_PEDAL_PAGE_MIN_PROFILE",
  PaceZonesMinProfile = "PACEZONES_MIN_PROFILE",
  SummaryAutoShareMinProfile = "SUMMARY_AUTO_SHARE_MIN_PROFILE",
  LocationSharingMinProfile = "LOCATION_SHARING_MIN_PROFILE",
  StravaLiveSegmentsMinProfile = "STRAVA_LIVE_SEGMENTS_MIN_PROFILE",
  SuggestedWorkoutsMinProfile = "SUGGESTED_WORKOUTS_MIN_PROFILE",
  WorkoutAlertsMinProfile = "WORKOUT_ALERTS_MIN_PROFILE",
  CuratedWorkoutsList = "CURATED_WORKOUTS_LIST",
  ElemntAlertCountWarningEnabled = "ELEMNT_ALERT_COUNT_WARNING_ENABLED",
  RouteToPlayrMinProfile = "ROUTE_TO_PLAYR",
  CuratedRoutesMinProfile = "CURATED_ROUTES_MIN_PROFILE",
}

const useFlaggedFeatures = () => {
  const { currentUser, userIsLoading } = useUserContext();
  const {
    getRemoteConfigString,
    getRemoteConfigObject,
    getRemoteConfigBoolean,
    remoteConfigIsLoading,
  } = useAnalyticsContext();

  const checkMinProfileFeature = useCallback(
    (remoteConfigKey: RemoteConfigKey) =>
      hasMinProfile(getRemoteConfigString(remoteConfigKey) as Profile, currentUser?.app?.profile),
    [currentUser?.app?.profile, getRemoteConfigString]
  );

  const checkBooleanFeature = useCallback(
    (remoteConfigKey: RemoteConfigKey) => !!getRemoteConfigBoolean(remoteConfigKey),
    [getRemoteConfigBoolean]
  );

  const featureCheckers = useMemo<Record<FlaggedFeature, () => boolean>>(() => {
    return {
      [FlaggedFeature.ElemntConfig]: () =>
        checkMinProfileFeature(RemoteConfigKey.ElemntConfigMinProfile),
      [FlaggedFeature.AutoShareSummary]: () =>
        checkMinProfileFeature(RemoteConfigKey.SummaryAutoShareMinProfile),
      [FlaggedFeature.PaceZones]: () => checkMinProfileFeature(RemoteConfigKey.PaceZonesMinProfile),
      [FlaggedFeature.ElemntMultisportPage]: () =>
        checkMinProfileFeature(RemoteConfigKey.ElemntMultisportPageMinProfile),
      [FlaggedFeature.ElemntPedalsPage]: () =>
        checkMinProfileFeature(RemoteConfigKey.ElemntPedalPageMinProfile),
      [FlaggedFeature.ElemntPlannedWorkouts]: () =>
        checkMinProfileFeature(RemoteConfigKey.ElemntPlannedWorkoutsMinProfile),
      [FlaggedFeature.LocationSharing]: () =>
        checkMinProfileFeature(RemoteConfigKey.LocationSharingMinProfile),
      [FlaggedFeature.StravaLiveSegments]: () =>
        checkMinProfileFeature(RemoteConfigKey.StravaLiveSegmentsMinProfile),
      [FlaggedFeature.ElemntAlertCountWarning]: () =>
        checkBooleanFeature(RemoteConfigKey.ElemntAlertCountWarningEnabled),
      [FlaggedFeature.RouteToPlayr]: () =>
        checkMinProfileFeature(RemoteConfigKey.RouteToPlayrMinProfile),
      [FlaggedFeature.CuratedRoutes]: () =>
        checkMinProfileFeature(RemoteConfigKey.CuratedRoutesMinProfile),
    };
  }, [checkBooleanFeature, checkMinProfileFeature]);

  const curatedWorkoutsList = useMemo(
    () => getRemoteConfigObject(RemoteConfigKey.CuratedWorkoutsList),
    [getRemoteConfigObject]
  );

  const checkIsFeatureEnabled = useCallback(
    (feature: FlaggedFeature) => featureCheckers[feature](),
    [featureCheckers]
  );

  const featuresAreLoading = useMemo(
    () => remoteConfigIsLoading || userIsLoading,
    [remoteConfigIsLoading, userIsLoading]
  );

  return {
    curatedWorkoutsList: curatedWorkoutsList || [],
    checkIsFeatureEnabled,
    featuresAreLoading,
  };
};
export default useFlaggedFeatures;
