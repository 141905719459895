import { ImperialMetricDisplayPreference } from "@WahooFitness/cloud-client-types";
import { Stack } from "@mui/system";
import UnitPreferenceToggle from "../../User/AboutYou/UnitPreferenceToggle";
import { t } from "@lingui/macro";
import { TextField } from "@mui/material";
import { useHeight } from "@/hooks/useHeight";

type Props = {
  userHeight: number | null;
  setUserHeight: (height: string) => void;
  userHeightUnit: ImperialMetricDisplayPreference | null | undefined;
  setUserHeightUnit: (unit: ImperialMetricDisplayPreference) => void;
};

export const OnboardingHeightInput = ({
  setUserHeight,
  userHeight,
  userHeightUnit,
  setUserHeightUnit,
}: Props) => {
  const {
    feet,
    inches,
    centimeters,
    handleFeetBlur,
    handleInchesBlur,
    handleCentimetersBlur,
    handleFeetChange,
    handleInchesChange,
    handleCentimetersChange,
    handleUnitChange,
    feetValid,
    inchesValid,
    centimetersValid,
  } = useHeight({
    setUserHeight,
    userHeight,
    userHeightUnit,
    setUserHeightUnit,
  });

  return (
    <Stack spacing={2.5} width="100%">
      <UnitPreferenceToggle
        value={userHeightUnit === ImperialMetricDisplayPreference.imperial ? "imperial" : "metric"}
        onChange={handleUnitChange}
        hideCustom
        hideUomText
      />
      {userHeightUnit === ImperialMetricDisplayPreference.metric ? (
        <TextField
          label={t`Centimeters`}
          value={centimeters}
          variant="outlined"
          type="number"
          inputProps={{ inputMode: "decimal", "data-testid": "metricHeightInput" }}
          onBlur={handleCentimetersBlur}
          onChange={handleCentimetersChange}
          error={!centimetersValid}
          helperText={centimetersValid ? undefined : t`Enter a value between 60 and 242`}
          autoFocus
        />
      ) : (
        <Stack direction="row" gap={1.5}>
          <TextField
            label={t`Feet`}
            value={feet}
            variant="outlined"
            type="number"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              "data-testid": "imperialFeetInput",
            }}
            onBlur={handleFeetBlur}
            onChange={handleFeetChange}
            error={!feetValid}
            helperText={feetValid ? undefined : t`Enter a value between 2 and 7`}
            fullWidth
            autoFocus
          />

          <TextField
            label={t`Inches`}
            value={inches}
            variant="outlined"
            type="number"
            inputProps={{ inputMode: "decimal", "data-testid": "imperialInchesInput" }}
            onBlur={handleInchesBlur}
            onChange={handleInchesChange}
            error={!inchesValid}
            helperText={inchesValid ? undefined : t`Enter a value less than 12`}
            fullWidth
          />
        </Stack>
      )}
    </Stack>
  );
};
