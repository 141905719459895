import { useState, useMemo, useCallback } from "react";
import { ImperialMetricDisplayPreference, UserType } from "@WahooFitness/cloud-client-types";
import { t } from "@lingui/macro";
import { StepContent } from "./AthleteOnboarding";
import { OnboardingHeightInput } from "./StepComponents/OnboardingHeightInput";
import Sex from "@/services/types/Sex";
import SexSelect from "./StepComponents/SexSelect";
import useAboutYou from "../User/AboutYou/useAboutYou";
import DateOfBirthInput from "../User/AboutYou/DateOfBirthInput";
import { formatISO } from "date-fns";

export const useAthleteOnboarding = (user: UserType) => {
  const [activeStep, setActiveStep] = useState(0);
  const { updateUserField } = useAboutYou(user);

  const [userSex, setUserSex] = useState(user.gender as Sex);
  const [userDateOfBirth, setUserDateOfBirth] = useState(user.birth ? new Date(user.birth) : null);
  const [cloudUserHeight, setCloudUserHeight] = useState<string | null>(user.height);
  const [userHeightUnit, setUserHeightUnit] = useState(
    user.display_preference?.speed_distance ?? ImperialMetricDisplayPreference.metric
  );

  const displayUserHeight = useMemo(() => {
    // default value to null so user sees empty inputs until they enter a value
    if (cloudUserHeight === null || cloudUserHeight === "0") {
      return null;
    }

    // display height converts to cm if user has metric preference
    // otherwise, it converts to inches
    const calculatedHeight =
      userHeightUnit === ImperialMetricDisplayPreference.metric
        ? Math.round(+(cloudUserHeight || 0) * 10000) / 100
        : Math.round(+(cloudUserHeight || 0) * 3937) / 100;
    return Number(calculatedHeight);
  }, [cloudUserHeight, userHeightUnit]);

  const steps: StepContent[] = useMemo(
    () => [
      {
        stepId: 1,
        stepName: "birthday",
        headline: t`When is your birthday?`,
        subheader: t`This data helps build your athlete profile, ensuring you get precise and personalized performance metrics.`,
        component: (
          <DateOfBirthInput
            value={userDateOfBirth}
            onChange={setUserDateOfBirth}
            textFieldOnlyPicker
          />
        ),
        skippable: false,
        onContinue: () => {
          if (userDateOfBirth) {
            updateUserField(formatISO(userDateOfBirth, { representation: "date" }), "birth");
          }
        },
        disabled: userDateOfBirth === null,
      },
      {
        stepId: 2,
        stepName: "sex",
        headline: t`What is your sex?`,
        subheader: t`Your biological sex at birth can be a good indicator of your power output physical abilities.`,
        component: <SexSelect userSex={userSex} setUserSex={setUserSex} />,
        skippable: false,
        onContinue: () => {
          updateUserField(userSex, "gender");
        },
        disabled: userSex === null,
      },
      {
        stepId: 3,
        stepName: "height",
        headline: t`What is your height?`,
        subheader: t`This data helps build your athlete profile, ensuring you get precise and personalized performance metrics.`,
        component: (
          <OnboardingHeightInput
            setUserHeight={setCloudUserHeight}
            userHeight={displayUserHeight}
            userHeightUnit={userHeightUnit}
            setUserHeightUnit={setUserHeightUnit}
          />
        ),
        skippable: true,
        onContinue: () => {
          updateUserField(cloudUserHeight, "height");
          updateUserField(userHeightUnit, "metric_speed_distance");
        },
        disabled: displayUserHeight === null,
      },
      {
        stepId: 4,
        stepName: "weight",
        headline: t`What is your weight?`,
        subheader: t`This data helps build your athlete profile, ensuring you get precise and personalized performance metrics.`,
        component: <div>placeholder weight</div>,
        skippable: true,
      },
      {
        stepId: 5,
        stepName: "sportPreference",
        headline: t`What sports are you interested in?`,
        subheader: t`Select all that applies.`,
        component: <div>placeholder sports</div>,
        skippable: false,
      },
    ],
    [userDateOfBirth, userSex, displayUserHeight, userHeightUnit, updateUserField, cloudUserHeight]
  );

  const handleNext = useCallback(() => {
    if (steps[activeStep].onContinue !== undefined) {
      steps[activeStep].onContinue!();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [activeStep, steps]);

  const handleSkip = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  return { activeStep, steps, handleNext, handleBack, handleSkip };
};

export default useAthleteOnboarding;
