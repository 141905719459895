import { Box } from "@mui/system";
import { useCallback } from "react";
import { useUserContext } from "@WahooFitness/wahoo-offline-mfe";
import ErrorBoundary from "../ErrorBoundary";
import AthleteOnboarding from "./AthleteOnboarding";

const AthleteOnboardingContainer = () => {
  const { currentUser, userIsLoading, userError, mutateUser } = useUserContext();
  const handleReload = useCallback(() => {
    mutateUser();
  }, [mutateUser]);

  return (
    <ErrorBoundary error={userError} isLoading={userIsLoading} onReload={handleReload}>
      <Box display="flex" flexDirection="column" width="100%" height="100%">
        {currentUser && <AthleteOnboarding user={currentUser} />}
      </Box>
    </ErrorBoundary>
  );
};

export default AthleteOnboardingContainer;
