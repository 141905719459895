import { StepIconProps, Stepper, Step, StepLabel } from "@mui/material";
import { Box } from "@mui/system";
import { StepContent } from "./AthleteOnboarding";
import { useState, useEffect, useMemo } from "react";

/**
 * The component calculates the width of each step based on the screen width and the number of steps plus accounting for padding.
 * It listens for window resize events to update the step widths dynamically.
 * Each step is rendered with a custom StepIconComponent that adjusts its color based on whether the step is active.
 */

const CustomStepper = ({ steps, activeStep }: { steps: StepContent[]; activeStep: number }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const calculateStepWidth = useMemo(
    () => (screenWidth - 8 * (steps.length - 1) - 32) / steps.length,
    [screenWidth, steps.length]
  );

  return (
    <Stepper activeStep={activeStep} connector={null}>
      {steps.map((step) => (
        <Step key={step.headline} sx={{ padding: 0 }}>
          <StepLabel
            StepIconComponent={({ active }: StepIconProps) => (
              <Box
                height={4}
                width={calculateStepWidth}
                borderRadius={2}
                bgcolor={active ? "text.primary" : "secondary.main"}
              />
            )}
          />
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
